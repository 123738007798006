module.exports = {
  "member": {
    "aggregation_status": 2,
    "connection_status": 3,
    "guid": "MBR-241266ed-803a-4841-8a8a-0f37551e8f56",
    "institution_guid": "INS-241266ed-803a-4841-8a8a-0f37551e8f56",
    "institution_name": null,
    "institution_url": "https://gringotts.sand.internal.mx",
    "instructional_data": {
      "title": "Test Instructions",
      "description": "<a href=\"https://google.com\" id=\"instructional_text\">A Test Link</a>",
      "steps": [
        "Go to <a href=\"https://google.com\" id=\"instructional_text_steps\">Google</a>"
      ]
    },
    "is_being_aggregated": true,
    "is_manual": false,
    "is_managed_by_user": true,
    "is_oauth": false,
    "last_job_guid": "JOB-139ef9f3-7b78-4ccd-b669-f5e4666b2625",
    "last_job_status": null,
    "last_update_time": null,
    "metadata": null,
    "mfa": {
      "credentials": [
        {
          "nullify": null,
          "guid": "CRD-241266ed-803a-4841-8a8a-0f37551e8f56",
          "institution_guid": "INS-241266ed-803a-4841-8a8a-0f37551e8f56",
          "label": "Pick one of the following",
          "field_name": null,
          "opt": null,
          "type": 2,
          "display_order": null,
          "external_id": "UNIQUE_ID_FOR_THIS_CHALLENGE-603e30716e",
          "data_source_guid": null,
          "field_type": 2,
          "optional": null,
          "editable": null,
          "mfa": true,
          "optional_mfa": null,
          "aggregator_guid": "AGG-241266ed-803a-4841-8a8a-0f37551e8f56",
          "question_field_type": null,
          "answer_field_type": null,
          "escaped": null,
          "value_identifier": null,
          "value_mask": null,
          "size": null,
          "max_length": null,
          "created_at": "2023-03-30T22:23:05.000+00:00",
          "updated_at": "2023-03-30T22:23:05.000+00:00",
          "legacy_question_field_type": null,
          "response_field_type": null,
          "sequence": null,
          "meta_data": null,
          "display_name": null,
          "status_code": 200,
          "job_guid": "JOB-241266ed-803a-4841-8a8a-0f37551e8f56",
          "options": [
            {
              "nullify": null,
              "guid": "CRO-241266ed-803a-4841-8a8a-0f37551e8f56",
              "label": "image",
              "external_id": null,
              "value": "image",
              "credential_guid": "CRD-241266ed-803a-4841-8a8a-0f37551e8f56",
              "created_at": "2023-03-30T22:23:05.000+00:00",
              "updated_at": "2023-03-30T22:23:05.000+00:00",
              "external_guid": null,
              "data_uri": null,
              "status_code": 200
            },
            {
              "nullify": null,
              "guid": "CRO-241266ed-803a-4841-8a8a-0f37551e8f56",
              "label": "options",
              "external_id": null,
              "value": "options",
              "credential_guid": "CRD-241266ed-803a-4841-8a8a-0f37551e8f56",
              "created_at": "2023-03-30T22:23:05.000+00:00",
              "updated_at": "2023-03-30T22:23:05.000+00:00",
              "external_guid": null,
              "data_uri": null,
              "status_code": 200
            },
            {
              "nullify": null,
              "guid": "CRO-241266ed-803a-4841-8a8a-0f37551e8f56",
              "label": "challenge",
              "external_id": null,
              "value": "challenge",
              "credential_guid": "CRD-241266ed-803a-4841-8a8a-0f37551e8f56",
              "created_at": "2023-03-30T22:23:05.000+00:00",
              "updated_at": "2023-03-30T22:23:05.000+00:00",
              "external_guid": null,
              "data_uri": null,
              "status_code": 200
            },
            {
              "nullify": null,
              "guid": "CRO-241266ed-803a-4841-8a8a-0f37551e8f56",
              "label": "wrong answer",
              "external_id": null,
              "value": "wrong answer",
              "credential_guid": "CRD-241266ed-803a-4841-8a8a-0f37551e8f56",
              "created_at": "2023-03-30T22:23:05.000+00:00",
              "updated_at": "2023-03-30T22:23:05.000+00:00",
              "external_guid": null,
              "data_uri": null,
              "status_code": 200
            },
            {
              "nullify": null,
              "guid": "CRO-241266ed-803a-4841-8a8a-0f37551e8f56",
              "label": "correct",
              "external_id": null,
              "value": "correct",
              "credential_guid": "CRD-241266ed-803a-4841-8a8a-0f37551e8f56",
              "created_at": "2023-03-30T22:23:05.000+00:00",
              "updated_at": "2023-03-30T22:23:05.000+00:00",
              "external_guid": null,
              "data_uri": null,
              "status_code": 200
            },
            {
              "nullify": null,
              "guid": "CRO-241266ed-803a-4841-8a8a-0f37551e8f56",
              "label": "image options",
              "external_id": null,
              "value": "image options",
              "credential_guid": "CRD-241266ed-803a-4841-8a8a-0f37551e8f56",
              "created_at": "2023-03-30T22:23:05.000+00:00",
              "updated_at": "2023-03-30T22:23:05.000+00:00",
              "external_guid": null,
              "data_uri": null,
              "status_code": 200
            },
            {
              "nullify": null,
              "guid": "CRO-241266ed-803a-4841-8a8a-0f37551e8f56",
              "label": "challenges",
              "external_id": null,
              "value": "challenges",
              "credential_guid": "CRD-241266ed-803a-4841-8a8a-0f37551e8f56",
              "created_at": "2023-03-30T22:23:05.000+00:00",
              "updated_at": "2023-03-30T22:23:05.000+00:00",
              "external_guid": null,
              "data_uri": null,
              "status_code": 200
            }
          ]
        }
      ]
    },
    "most_recent_job_guid": "JOB-241266ed-803a-4841-8a8a-0f37551e8f56",
    "needs_updated_credentials": false,
    "name": "Gringotts",
    "process_status": {
      "mfa": {
        "credentials": [
          {
            "nullify": null,
            "guid": "CRD-241266ed-803a-4841-8a8a-0f37551e8f56",
            "institution_guid": "INS-241266ed-803a-4841-8a8a-0f37551e8f56",
            "label": "Pick one of the following",
            "field_name": null,
            "opt": null,
            "type": 2,
            "display_order": null,
            "external_id": "UNIQUE_ID_FOR_THIS_CHALLENGE-603e30716e",
            "data_source_guid": null,
            "field_type": 2,
            "optional": null,
            "editable": null,
            "mfa": true,
            "optional_mfa": null,
            "aggregator_guid": "AGG-241266ed-803a-4841-8a8a-0f37551e8f56",
            "question_field_type": null,
            "answer_field_type": null,
            "escaped": null,
            "value_identifier": null,
            "value_mask": null,
            "size": null,
            "max_length": null,
            "created_at": "2023-03-30T22:23:05.000+00:00",
            "updated_at": "2023-03-30T22:23:05.000+00:00",
            "legacy_question_field_type": null,
            "response_field_type": null,
            "sequence": null,
            "meta_data": null,
            "display_name": null,
            "status_code": 200,
            "job_guid": "JOB-241266ed-803a-4841-8a8a-0f37551e8f56",
            "options": [
              {
                "nullify": null,
                "guid": "CRO-241266ed-803a-4841-8a8a-0f37551e8f56",
                "label": "image",
                "external_id": null,
                "value": "image",
                "credential_guid": "CRD-241266ed-803a-4841-8a8a-0f37551e8f56",
                "created_at": "2023-03-30T22:23:05.000+00:00",
                "updated_at": "2023-03-30T22:23:05.000+00:00",
                "external_guid": null,
                "data_uri": null,
                "status_code": 200
              },
              {
                "nullify": null,
                "guid": "CRO-241266ed-803a-4841-8a8a-0f37551e8f56",
                "label": "options",
                "external_id": null,
                "value": "options",
                "credential_guid": "CRD-241266ed-803a-4841-8a8a-0f37551e8f56",
                "created_at": "2023-03-30T22:23:05.000+00:00",
                "updated_at": "2023-03-30T22:23:05.000+00:00",
                "external_guid": null,
                "data_uri": null,
                "status_code": 200
              },
              {
                "nullify": null,
                "guid": "CRO-241266ed-803a-4841-8a8a-0f37551e8f56",
                "label": "challenge",
                "external_id": null,
                "value": "challenge",
                "credential_guid": "CRD-241266ed-803a-4841-8a8a-0f37551e8f56",
                "created_at": "2023-03-30T22:23:05.000+00:00",
                "updated_at": "2023-03-30T22:23:05.000+00:00",
                "external_guid": null,
                "data_uri": null,
                "status_code": 200
              },
              {
                "nullify": null,
                "guid": "CRO-241266ed-803a-4841-8a8a-0f37551e8f56",
                "label": "wrong answer",
                "external_id": null,
                "value": "wrong answer",
                "credential_guid": "CRD-241266ed-803a-4841-8a8a-0f37551e8f56",
                "created_at": "2023-03-30T22:23:05.000+00:00",
                "updated_at": "2023-03-30T22:23:05.000+00:00",
                "external_guid": null,
                "data_uri": null,
                "status_code": 200
              },
              {
                "nullify": null,
                "guid": "CRO-241266ed-803a-4841-8a8a-0f37551e8f56",
                "label": "correct",
                "external_id": null,
                "value": "correct",
                "credential_guid": "CRD-241266ed-803a-4841-8a8a-0f37551e8f56",
                "created_at": "2023-03-30T22:23:05.000+00:00",
                "updated_at": "2023-03-30T22:23:05.000+00:00",
                "external_guid": null,
                "data_uri": null,
                "status_code": 200
              },
              {
                "nullify": null,
                "guid": "CRO-241266ed-803a-4841-8a8a-0f37551e8f56",
                "label": "image options",
                "external_id": null,
                "value": "image options",
                "credential_guid": "CRD-241266ed-803a-4841-8a8a-0f37551e8f56",
                "created_at": "2023-03-30T22:23:05.000+00:00",
                "updated_at": "2023-03-30T22:23:05.000+00:00",
                "external_guid": null,
                "data_uri": null,
                "status_code": 200
              },
              {
                "nullify": null,
                "guid": "CRO-241266ed-803a-4841-8a8a-0f37551e8f56",
                "label": "challenges",
                "external_id": null,
                "value": "challenges",
                "credential_guid": "CRD-241266ed-803a-4841-8a8a-0f37551e8f56",
                "created_at": "2023-03-30T22:23:05.000+00:00",
                "updated_at": "2023-03-30T22:23:05.000+00:00",
                "external_guid": null,
                "data_uri": null,
                "status_code": 200
              }
            ]
          }
        ]
      }
    },
    "revision": 3,
    "user_guid": "USR-241266ed-803a-4841-8a8a-0f37551e8f56",
    "oauth_window_uri": null,
    "verification_is_enabled": true,
    "tax_statement_is_enabled": false,
    "successfully_aggregated_at": null
  }
}
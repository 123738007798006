module.exports = {
  "method": "POST",
  "headers": {
      "host": "webhook.sophtron-prod.com",
      "x-request-id": "612ccc78005e1ca96f0b208d8cedf176",
      "x-real-ip": "34.215.116.35",
      "x-forwarded-for": "34.215.116.35",
      "x-forwarded-host": "webhook.sophtron-prod.com",
      "x-forwarded-port": "80",
      "x-forwarded-proto": "http",
      "x-scheme": "http",
      "content-length": "113",
      "accept": "application/json, text/plain, */*",
      "content-type": "application/json",
      "x-finicity-signature": "586fe903c8063aa796bd6e1ab0910699f0f5b920c7d4185d8029c4d65ed00c47",
      "user-agent": "axios/0.25.0",
      "x-b3-traceid": "513f66f40b0bcc46babfd1c18a08c277",
      "x-b3-spanid": "f3a307e76b652d50",
      "x-b3-sampled": "1",
      "traceparent": "00-513f66f40b0bcc46babfd1c18a08c277-f3a307e76b652d50-01"
  },
  "path": "/webhook/finicity_sandbox/f0105b8f-a7a4-4665-98e2-09e116804951",
  "body": {
      "customerId": "6030781868",
      "eventType": "started",
      "eventId": "1688829063731-09493ef4b934040cc17b18c4",
      "payload": {}
  }
}
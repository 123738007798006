/* tslint:disable */
/* eslint-disable */
/**
 * MX Platform API
 * The MX Platform API is a powerful, fully-featured API designed to make aggregating and enhancing financial data easy and reliable. It can seamlessly connect your app or website to tens of thousands of financial institutions.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AccountNumberResponse
 */
export interface AccountNumberResponse {
    /**
     * 
     * @type {string}
     * @memberof AccountNumberResponse
     */
    'account_guid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountNumberResponse
     */
    'account_number'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountNumberResponse
     */
    'guid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountNumberResponse
     */
    'institution_number'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountNumberResponse
     */
    'member_guid'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AccountNumberResponse
     */
    'passed_validation'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof AccountNumberResponse
     */
    'routing_number'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountNumberResponse
     */
    'transit_number'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountNumberResponse
     */
    'user_guid'?: string | null;
}
/**
 * 
 * @export
 * @interface AccountNumbersResponseBody
 */
export interface AccountNumbersResponseBody {
    /**
     * 
     * @type {Array<AccountNumberResponse>}
     * @memberof AccountNumbersResponseBody
     */
    'account_numbers'?: Array<AccountNumberResponse>;
    /**
     * 
     * @type {PaginationResponse}
     * @memberof AccountNumbersResponseBody
     */
    'pagination'?: PaginationResponse;
}
/**
 * 
 * @export
 * @interface AccountOwnerResponse
 */
export interface AccountOwnerResponse {
    /**
     * 
     * @type {string}
     * @memberof AccountOwnerResponse
     */
    'account_guid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountOwnerResponse
     */
    'address'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountOwnerResponse
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountOwnerResponse
     */
    'country'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountOwnerResponse
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountOwnerResponse
     */
    'first_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountOwnerResponse
     */
    'guid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountOwnerResponse
     */
    'last_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountOwnerResponse
     */
    'member_guid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountOwnerResponse
     */
    'owner_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountOwnerResponse
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountOwnerResponse
     */
    'postal_code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountOwnerResponse
     */
    'state'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountOwnerResponse
     */
    'user_guid'?: string | null;
}
/**
 * 
 * @export
 * @interface AccountOwnersResponseBody
 */
export interface AccountOwnersResponseBody {
    /**
     * 
     * @type {Array<AccountOwnerResponse>}
     * @memberof AccountOwnersResponseBody
     */
    'account_owners'?: Array<AccountOwnerResponse>;
    /**
     * 
     * @type {PaginationResponse}
     * @memberof AccountOwnersResponseBody
     */
    'pagination'?: PaginationResponse;
}
/**
 * 
 * @export
 * @interface AccountResponse
 */
export interface AccountResponse {
    /**
     * 
     * @type {string}
     * @memberof AccountResponse
     */
    'account_number'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AccountResponse
     */
    'apr'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AccountResponse
     */
    'apy'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AccountResponse
     */
    'available_balance'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AccountResponse
     */
    'available_credit'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AccountResponse
     */
    'balance'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AccountResponse
     */
    'cash_balance'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AccountResponse
     */
    'cash_surrender_value'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AccountResponse
     */
    'created_at'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AccountResponse
     */
    'credit_limit'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AccountResponse
     */
    'currency_code'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AccountResponse
     */
    'day_payment_is_due'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AccountResponse
     */
    'death_benefit'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AccountResponse
     */
    'guid'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AccountResponse
     */
    'holdings_value'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AccountResponse
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountResponse
     */
    'imported_at'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountResponse
     */
    'institution_code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountResponse
     */
    'insured_name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AccountResponse
     */
    'interest_rate'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof AccountResponse
     */
    'is_closed'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof AccountResponse
     */
    'is_hidden'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof AccountResponse
     */
    'last_payment'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AccountResponse
     */
    'last_payment_at'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AccountResponse
     */
    'loan_amount'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AccountResponse
     */
    'matures_on'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountResponse
     */
    'member_guid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountResponse
     */
    'member_id'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AccountResponse
     */
    'member_is_managed_by_user'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof AccountResponse
     */
    'metadata'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AccountResponse
     */
    'minimum_balance'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AccountResponse
     */
    'minimum_payment'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AccountResponse
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountResponse
     */
    'nickname'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AccountResponse
     */
    'original_balance'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AccountResponse
     */
    'pay_out_amount'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AccountResponse
     */
    'payment_due_at'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AccountResponse
     */
    'payoff_balance'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AccountResponse
     */
    'premium_amount'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AccountResponse
     */
    'routing_number'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountResponse
     */
    'started_on'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountResponse
     */
    'subtype'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AccountResponse
     */
    'total_account_value'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AccountResponse
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountResponse
     */
    'updated_at'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountResponse
     */
    'user_guid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountResponse
     */
    'user_id'?: string | null;
}
/**
 * 
 * @export
 * @interface AccountResponseBody
 */
export interface AccountResponseBody {
    /**
     * 
     * @type {AccountResponse}
     * @memberof AccountResponseBody
     */
    'account'?: AccountResponse;
}
/**
 * 
 * @export
 * @interface AccountUpdateRequest
 */
export interface AccountUpdateRequest {
    /**
     * 
     * @type {boolean}
     * @memberof AccountUpdateRequest
     */
    'is_hidden'?: boolean;
}
/**
 * 
 * @export
 * @interface AccountUpdateRequestBody
 */
export interface AccountUpdateRequestBody {
    /**
     * 
     * @type {AccountUpdateRequest}
     * @memberof AccountUpdateRequestBody
     */
    'account'?: AccountUpdateRequest;
}
/**
 * 
 * @export
 * @interface AccountsResponseBody
 */
export interface AccountsResponseBody {
    /**
     * 
     * @type {Array<AccountResponse>}
     * @memberof AccountsResponseBody
     */
    'accounts'?: Array<AccountResponse>;
    /**
     * 
     * @type {PaginationResponse}
     * @memberof AccountsResponseBody
     */
    'pagination'?: PaginationResponse;
}
/**
 * 
 * @export
 * @interface AuthorizationCodeRequest
 */
export interface AuthorizationCodeRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthorizationCodeRequest
     */
    'scope'?: string | null;
}
/**
 * 
 * @export
 * @interface AuthorizationCodeRequestBody
 */
export interface AuthorizationCodeRequestBody {
    /**
     * 
     * @type {AuthorizationCodeRequest}
     * @memberof AuthorizationCodeRequestBody
     */
    'authorization_code'?: AuthorizationCodeRequest;
}
/**
 * 
 * @export
 * @interface AuthorizationCodeResponse
 */
export interface AuthorizationCodeResponse {
    /**
     * 
     * @type {string}
     * @memberof AuthorizationCodeResponse
     */
    'code'?: string | null;
}
/**
 * 
 * @export
 * @interface AuthorizationCodeResponseBody
 */
export interface AuthorizationCodeResponseBody {
    /**
     * 
     * @type {Array<AuthorizationCodeResponse>}
     * @memberof AuthorizationCodeResponseBody
     */
    'authorization_code'?: Array<AuthorizationCodeResponse>;
}
/**
 * 
 * @export
 * @interface CategoriesResponseBody
 */
export interface CategoriesResponseBody {
    /**
     * 
     * @type {Array<CategoryResponse>}
     * @memberof CategoriesResponseBody
     */
    'categories'?: Array<CategoryResponse>;
    /**
     * 
     * @type {PaginationResponse}
     * @memberof CategoriesResponseBody
     */
    'pagination'?: PaginationResponse;
}
/**
 * 
 * @export
 * @interface CategoryCreateRequest
 */
export interface CategoryCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof CategoryCreateRequest
     */
    'metadata'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryCreateRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CategoryCreateRequest
     */
    'parent_guid'?: string;
}
/**
 * 
 * @export
 * @interface CategoryCreateRequestBody
 */
export interface CategoryCreateRequestBody {
    /**
     * 
     * @type {CategoryCreateRequest}
     * @memberof CategoryCreateRequestBody
     */
    'category'?: CategoryCreateRequest;
}
/**
 * 
 * @export
 * @interface CategoryResponse
 */
export interface CategoryResponse {
    /**
     * 
     * @type {string}
     * @memberof CategoryResponse
     */
    'created_at'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CategoryResponse
     */
    'guid'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CategoryResponse
     */
    'is_default'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof CategoryResponse
     */
    'is_income'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof CategoryResponse
     */
    'metadata'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CategoryResponse
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CategoryResponse
     */
    'parent_guid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CategoryResponse
     */
    'updated_at'?: string | null;
}
/**
 * 
 * @export
 * @interface CategoryResponseBody
 */
export interface CategoryResponseBody {
    /**
     * 
     * @type {CategoryResponse}
     * @memberof CategoryResponseBody
     */
    'category'?: CategoryResponse;
}
/**
 * 
 * @export
 * @interface CategoryUpdateRequest
 */
export interface CategoryUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof CategoryUpdateRequest
     */
    'metadata'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryUpdateRequest
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface CategoryUpdateRequestBody
 */
export interface CategoryUpdateRequestBody {
    /**
     * 
     * @type {CategoryUpdateRequest}
     * @memberof CategoryUpdateRequestBody
     */
    'category'?: CategoryUpdateRequest;
}
/**
 * 
 * @export
 * @interface ChallengeResponse
 */
export interface ChallengeResponse {
    /**
     * 
     * @type {string}
     * @memberof ChallengeResponse
     */
    'field_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChallengeResponse
     */
    'guid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChallengeResponse
     */
    'image_data'?: string | null;
    /**
     * 
     * @type {Array<ImageOptionResponse>}
     * @memberof ChallengeResponse
     */
    'image_options'?: Array<ImageOptionResponse>;
    /**
     * 
     * @type {string}
     * @memberof ChallengeResponse
     */
    'label'?: string | null;
    /**
     * 
     * @type {Array<OptionResponse>}
     * @memberof ChallengeResponse
     */
    'options'?: Array<OptionResponse>;
    /**
     * 
     * @type {string}
     * @memberof ChallengeResponse
     */
    'type'?: string | null;
}
/**
 * 
 * @export
 * @interface ChallengesResponseBody
 */
export interface ChallengesResponseBody {
    /**
     * 
     * @type {Array<ChallengeResponse>}
     * @memberof ChallengesResponseBody
     */
    'challenges'?: Array<ChallengeResponse>;
    /**
     * 
     * @type {PaginationResponse}
     * @memberof ChallengesResponseBody
     */
    'pagination'?: PaginationResponse;
}
/**
 * 
 * @export
 * @interface ConnectWidgetRequest
 */
export interface ConnectWidgetRequest {
    /**
     * 
     * @type {string}
     * @memberof ConnectWidgetRequest
     */
    'client_redirect_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConnectWidgetRequest
     */
    'color_scheme'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConnectWidgetRequest
     */
    'current_institution_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConnectWidgetRequest
     */
    'current_member_guid'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ConnectWidgetRequest
     */
    'disable_background_agg'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConnectWidgetRequest
     */
    'disable_institution_search'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConnectWidgetRequest
     */
    'include_identity'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConnectWidgetRequest
     */
    'include_transactions'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConnectWidgetRequest
     */
    'is_mobile_webview'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ConnectWidgetRequest
     */
    'mode'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConnectWidgetRequest
     */
    'oauth_referral_source'?: string;
    /**
     * 
     * @type {number}
     * @memberof ConnectWidgetRequest
     */
    'ui_message_version'?: number;
    /**
     * 
     * @type {string}
     * @memberof ConnectWidgetRequest
     */
    'ui_message_webview_url_scheme'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ConnectWidgetRequest
     */
    'update_credentials'?: boolean;
}
/**
 * 
 * @export
 * @interface ConnectWidgetRequestBody
 */
export interface ConnectWidgetRequestBody {
    /**
     * 
     * @type {ConnectWidgetRequest}
     * @memberof ConnectWidgetRequestBody
     */
    'config'?: ConnectWidgetRequest;
}
/**
 * 
 * @export
 * @interface ConnectWidgetResponse
 */
export interface ConnectWidgetResponse {
    /**
     * 
     * @type {string}
     * @memberof ConnectWidgetResponse
     */
    'connect_widget_url'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConnectWidgetResponse
     */
    'guid'?: string | null;
}
/**
 * 
 * @export
 * @interface ConnectWidgetResponseBody
 */
export interface ConnectWidgetResponseBody {
    /**
     * 
     * @type {ConnectWidgetResponse}
     * @memberof ConnectWidgetResponseBody
     */
    'user'?: ConnectWidgetResponse;
}
/**
 * 
 * @export
 * @interface CredentialRequest
 */
export interface CredentialRequest {
    /**
     * 
     * @type {string}
     * @memberof CredentialRequest
     */
    'guid'?: string;
    /**
     * 
     * @type {string}
     * @memberof CredentialRequest
     */
    'value'?: string;
}
/**
 * 
 * @export
 * @interface CredentialResponse
 */
export interface CredentialResponse {
    /**
     * 
     * @type {number}
     * @memberof CredentialResponse
     */
    'display_order'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CredentialResponse
     */
    'field_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CredentialResponse
     */
    'field_type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CredentialResponse
     */
    'guid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CredentialResponse
     */
    'label'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CredentialResponse
     */
    'type'?: string | null;
}
/**
 * 
 * @export
 * @interface CredentialsResponseBody
 */
export interface CredentialsResponseBody {
    /**
     * 
     * @type {Array<CredentialResponse>}
     * @memberof CredentialsResponseBody
     */
    'credentials'?: Array<CredentialResponse>;
    /**
     * 
     * @type {PaginationResponse}
     * @memberof CredentialsResponseBody
     */
    'pagination'?: PaginationResponse;
}
/**
 * 
 * @export
 * @interface EnhanceTransactionResponse
 */
export interface EnhanceTransactionResponse {
    /**
     * 
     * @type {number}
     * @memberof EnhanceTransactionResponse
     */
    'amount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof EnhanceTransactionResponse
     */
    'categorized_by'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof EnhanceTransactionResponse
     */
    'category'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnhanceTransactionResponse
     */
    'category_guid'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EnhanceTransactionResponse
     */
    'described_by'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof EnhanceTransactionResponse
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnhanceTransactionResponse
     */
    'extended_transaction_type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnhanceTransactionResponse
     */
    'id'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof EnhanceTransactionResponse
     */
    'is_bill_pay'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof EnhanceTransactionResponse
     */
    'is_direct_deposit'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof EnhanceTransactionResponse
     */
    'is_expense'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof EnhanceTransactionResponse
     */
    'is_fee'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof EnhanceTransactionResponse
     */
    'is_income'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof EnhanceTransactionResponse
     */
    'is_international'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof EnhanceTransactionResponse
     */
    'is_overdraft_fee'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof EnhanceTransactionResponse
     */
    'is_payroll_advance'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof EnhanceTransactionResponse
     */
    'is_subscription'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof EnhanceTransactionResponse
     */
    'memo'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EnhanceTransactionResponse
     */
    'merchant_category_code'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof EnhanceTransactionResponse
     */
    'merchant_guid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnhanceTransactionResponse
     */
    'merchant_location_guid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnhanceTransactionResponse
     */
    'original_description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnhanceTransactionResponse
     */
    'type'?: string | null;
}
/**
 * 
 * @export
 * @interface EnhanceTransactionsRequest
 */
export interface EnhanceTransactionsRequest {
    /**
     * 
     * @type {number}
     * @memberof EnhanceTransactionsRequest
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof EnhanceTransactionsRequest
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof EnhanceTransactionsRequest
     */
    'extended_transaction_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof EnhanceTransactionsRequest
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof EnhanceTransactionsRequest
     */
    'memo'?: string;
    /**
     * 
     * @type {number}
     * @memberof EnhanceTransactionsRequest
     */
    'merchant_category_code'?: number;
    /**
     * 
     * @type {string}
     * @memberof EnhanceTransactionsRequest
     */
    'type'?: string;
}
/**
 * 
 * @export
 * @interface EnhanceTransactionsRequestBody
 */
export interface EnhanceTransactionsRequestBody {
    /**
     * 
     * @type {Array<EnhanceTransactionsRequest>}
     * @memberof EnhanceTransactionsRequestBody
     */
    'transactions'?: Array<EnhanceTransactionsRequest>;
}
/**
 * 
 * @export
 * @interface EnhanceTransactionsResponseBody
 */
export interface EnhanceTransactionsResponseBody {
    /**
     * 
     * @type {Array<EnhanceTransactionResponse>}
     * @memberof EnhanceTransactionsResponseBody
     */
    'transactions'?: Array<EnhanceTransactionResponse>;
}
/**
 * 
 * @export
 * @interface HoldingResponse
 */
export interface HoldingResponse {
    /**
     * 
     * @type {string}
     * @memberof HoldingResponse
     */
    'account_guid'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof HoldingResponse
     */
    'cost_basis'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof HoldingResponse
     */
    'created_at'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HoldingResponse
     */
    'currency_code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HoldingResponse
     */
    'cusip'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof HoldingResponse
     */
    'daily_change'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof HoldingResponse
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HoldingResponse
     */
    'guid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HoldingResponse
     */
    'holding_type'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof HoldingResponse
     */
    'holding_type_id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof HoldingResponse
     */
    'id'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof HoldingResponse
     */
    'market_value'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof HoldingResponse
     */
    'member_guid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HoldingResponse
     */
    'metadata'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof HoldingResponse
     */
    'purchase_price'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof HoldingResponse
     */
    'shares'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof HoldingResponse
     */
    'symbol'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HoldingResponse
     */
    'updated_at'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HoldingResponse
     */
    'user_guid'?: string | null;
}
/**
 * 
 * @export
 * @interface HoldingResponseBody
 */
export interface HoldingResponseBody {
    /**
     * 
     * @type {HoldingResponse}
     * @memberof HoldingResponseBody
     */
    'holding'?: HoldingResponse;
}
/**
 * 
 * @export
 * @interface HoldingsResponseBody
 */
export interface HoldingsResponseBody {
    /**
     * 
     * @type {Array<HoldingResponse>}
     * @memberof HoldingsResponseBody
     */
    'holdings'?: Array<HoldingResponse>;
    /**
     * 
     * @type {PaginationResponse}
     * @memberof HoldingsResponseBody
     */
    'pagination'?: PaginationResponse;
}
/**
 * 
 * @export
 * @interface ImageOptionResponse
 */
export interface ImageOptionResponse {
    /**
     * 
     * @type {string}
     * @memberof ImageOptionResponse
     */
    'data_uri'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImageOptionResponse
     */
    'label'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImageOptionResponse
     */
    'value'?: string | null;
}
/**
 * 
 * @export
 * @interface InstitutionResponse
 */
export interface InstitutionResponse {
    /**
     * 
     * @type {string}
     * @memberof InstitutionResponse
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InstitutionResponse
     */
    'instructional_text'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InstitutionResponse
     */
    'medium_logo_url'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InstitutionResponse
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InstitutionResponse
     */
    'small_logo_url'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof InstitutionResponse
     */
    'supports_account_identification'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof InstitutionResponse
     */
    'supports_account_statement'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof InstitutionResponse
     */
    'supports_account_verification'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof InstitutionResponse
     */
    'supports_oauth'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof InstitutionResponse
     */
    'supports_transaction_history'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof InstitutionResponse
     */
    'url'?: string | null;
}
/**
 * 
 * @export
 * @interface InstitutionResponseBody
 */
export interface InstitutionResponseBody {
    /**
     * 
     * @type {InstitutionResponse}
     * @memberof InstitutionResponseBody
     */
    'institution'?: InstitutionResponse;
}
/**
 * 
 * @export
 * @interface InstitutionsResponseBody
 */
export interface InstitutionsResponseBody {
    /**
     * 
     * @type {Array<InstitutionResponse>}
     * @memberof InstitutionsResponseBody
     */
    'institutions'?: Array<InstitutionResponse>;
    /**
     * 
     * @type {PaginationResponse}
     * @memberof InstitutionsResponseBody
     */
    'pagination'?: PaginationResponse;
}
/**
 * 
 * @export
 * @interface ManagedAccountCreateRequest
 */
export interface ManagedAccountCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof ManagedAccountCreateRequest
     */
    'account_number'?: string;
    /**
     * 
     * @type {number}
     * @memberof ManagedAccountCreateRequest
     */
    'apr'?: number;
    /**
     * 
     * @type {number}
     * @memberof ManagedAccountCreateRequest
     */
    'apy'?: number;
    /**
     * 
     * @type {number}
     * @memberof ManagedAccountCreateRequest
     */
    'available_balance'?: number;
    /**
     * 
     * @type {number}
     * @memberof ManagedAccountCreateRequest
     */
    'available_credit'?: number;
    /**
     * 
     * @type {number}
     * @memberof ManagedAccountCreateRequest
     */
    'balance': number;
    /**
     * 
     * @type {number}
     * @memberof ManagedAccountCreateRequest
     */
    'cash_surrender_value'?: number;
    /**
     * 
     * @type {number}
     * @memberof ManagedAccountCreateRequest
     */
    'credit_limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof ManagedAccountCreateRequest
     */
    'currency_code'?: string;
    /**
     * 
     * @type {number}
     * @memberof ManagedAccountCreateRequest
     */
    'day_payment_is_due'?: number;
    /**
     * 
     * @type {number}
     * @memberof ManagedAccountCreateRequest
     */
    'death_benefit'?: number;
    /**
     * 
     * @type {string}
     * @memberof ManagedAccountCreateRequest
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ManagedAccountCreateRequest
     */
    'interest_rate'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ManagedAccountCreateRequest
     */
    'is_closed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ManagedAccountCreateRequest
     */
    'is_hidden'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ManagedAccountCreateRequest
     */
    'last_payment'?: number;
    /**
     * 
     * @type {string}
     * @memberof ManagedAccountCreateRequest
     */
    'last_payment_at'?: string;
    /**
     * 
     * @type {number}
     * @memberof ManagedAccountCreateRequest
     */
    'loan_amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ManagedAccountCreateRequest
     */
    'matures_on'?: string;
    /**
     * 
     * @type {string}
     * @memberof ManagedAccountCreateRequest
     */
    'metadata'?: string;
    /**
     * 
     * @type {number}
     * @memberof ManagedAccountCreateRequest
     */
    'minimum_balance'?: number;
    /**
     * 
     * @type {number}
     * @memberof ManagedAccountCreateRequest
     */
    'minimum_payment'?: number;
    /**
     * 
     * @type {string}
     * @memberof ManagedAccountCreateRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ManagedAccountCreateRequest
     */
    'nickname'?: string;
    /**
     * 
     * @type {number}
     * @memberof ManagedAccountCreateRequest
     */
    'original_balance'?: number;
    /**
     * 
     * @type {string}
     * @memberof ManagedAccountCreateRequest
     */
    'payment_due_at'?: string;
    /**
     * 
     * @type {number}
     * @memberof ManagedAccountCreateRequest
     */
    'payoff_balance'?: number;
    /**
     * 
     * @type {string}
     * @memberof ManagedAccountCreateRequest
     */
    'routing_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof ManagedAccountCreateRequest
     */
    'started_on'?: string;
    /**
     * 
     * @type {string}
     * @memberof ManagedAccountCreateRequest
     */
    'subtype'?: string;
    /**
     * 
     * @type {string}
     * @memberof ManagedAccountCreateRequest
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface ManagedAccountCreateRequestBody
 */
export interface ManagedAccountCreateRequestBody {
    /**
     * 
     * @type {ManagedAccountCreateRequest}
     * @memberof ManagedAccountCreateRequestBody
     */
    'account'?: ManagedAccountCreateRequest;
}
/**
 * 
 * @export
 * @interface ManagedAccountUpdateRequest
 */
export interface ManagedAccountUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof ManagedAccountUpdateRequest
     */
    'account_number'?: string;
    /**
     * 
     * @type {number}
     * @memberof ManagedAccountUpdateRequest
     */
    'apr'?: number;
    /**
     * 
     * @type {number}
     * @memberof ManagedAccountUpdateRequest
     */
    'apy'?: number;
    /**
     * 
     * @type {number}
     * @memberof ManagedAccountUpdateRequest
     */
    'available_balance'?: number;
    /**
     * 
     * @type {number}
     * @memberof ManagedAccountUpdateRequest
     */
    'available_credit'?: number;
    /**
     * 
     * @type {number}
     * @memberof ManagedAccountUpdateRequest
     */
    'balance'?: number;
    /**
     * 
     * @type {number}
     * @memberof ManagedAccountUpdateRequest
     */
    'cash_surrender_value'?: number;
    /**
     * 
     * @type {number}
     * @memberof ManagedAccountUpdateRequest
     */
    'credit_limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof ManagedAccountUpdateRequest
     */
    'currency_code'?: string;
    /**
     * 
     * @type {number}
     * @memberof ManagedAccountUpdateRequest
     */
    'day_payment_is_due'?: number;
    /**
     * 
     * @type {number}
     * @memberof ManagedAccountUpdateRequest
     */
    'death_benefit'?: number;
    /**
     * 
     * @type {string}
     * @memberof ManagedAccountUpdateRequest
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ManagedAccountUpdateRequest
     */
    'interest_rate'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ManagedAccountUpdateRequest
     */
    'is_closed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ManagedAccountUpdateRequest
     */
    'is_hidden'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ManagedAccountUpdateRequest
     */
    'last_payment'?: number;
    /**
     * 
     * @type {string}
     * @memberof ManagedAccountUpdateRequest
     */
    'last_payment_at'?: string;
    /**
     * 
     * @type {number}
     * @memberof ManagedAccountUpdateRequest
     */
    'loan_amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ManagedAccountUpdateRequest
     */
    'matures_on'?: string;
    /**
     * 
     * @type {string}
     * @memberof ManagedAccountUpdateRequest
     */
    'metadata'?: string;
    /**
     * 
     * @type {number}
     * @memberof ManagedAccountUpdateRequest
     */
    'minimum_balance'?: number;
    /**
     * 
     * @type {number}
     * @memberof ManagedAccountUpdateRequest
     */
    'minimum_payment'?: number;
    /**
     * 
     * @type {string}
     * @memberof ManagedAccountUpdateRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ManagedAccountUpdateRequest
     */
    'nickname'?: string;
    /**
     * 
     * @type {number}
     * @memberof ManagedAccountUpdateRequest
     */
    'original_balance'?: number;
    /**
     * 
     * @type {string}
     * @memberof ManagedAccountUpdateRequest
     */
    'payment_due_at'?: string;
    /**
     * 
     * @type {number}
     * @memberof ManagedAccountUpdateRequest
     */
    'payoff_balance'?: number;
    /**
     * 
     * @type {string}
     * @memberof ManagedAccountUpdateRequest
     */
    'routing_number'?: string;
    /**
     * 
     * @type {string}
     * @memberof ManagedAccountUpdateRequest
     */
    'started_on'?: string;
    /**
     * 
     * @type {string}
     * @memberof ManagedAccountUpdateRequest
     */
    'subtype'?: string;
    /**
     * 
     * @type {string}
     * @memberof ManagedAccountUpdateRequest
     */
    'type'?: string;
}
/**
 * 
 * @export
 * @interface ManagedAccountUpdateRequestBody
 */
export interface ManagedAccountUpdateRequestBody {
    /**
     * 
     * @type {ManagedAccountUpdateRequest}
     * @memberof ManagedAccountUpdateRequestBody
     */
    'account'?: ManagedAccountUpdateRequest;
}
/**
 * 
 * @export
 * @interface ManagedMemberCreateRequest
 */
export interface ManagedMemberCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof ManagedMemberCreateRequest
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ManagedMemberCreateRequest
     */
    'institution_code': string;
    /**
     * 
     * @type {string}
     * @memberof ManagedMemberCreateRequest
     */
    'metadata'?: string;
    /**
     * 
     * @type {string}
     * @memberof ManagedMemberCreateRequest
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface ManagedMemberCreateRequestBody
 */
export interface ManagedMemberCreateRequestBody {
    /**
     * 
     * @type {ManagedMemberCreateRequest}
     * @memberof ManagedMemberCreateRequestBody
     */
    'member'?: ManagedMemberCreateRequest;
}
/**
 * 
 * @export
 * @interface ManagedMemberUpdateRequest
 */
export interface ManagedMemberUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof ManagedMemberUpdateRequest
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ManagedMemberUpdateRequest
     */
    'metadata'?: string;
    /**
     * 
     * @type {string}
     * @memberof ManagedMemberUpdateRequest
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface ManagedMemberUpdateRequestBody
 */
export interface ManagedMemberUpdateRequestBody {
    /**
     * 
     * @type {ManagedMemberUpdateRequest}
     * @memberof ManagedMemberUpdateRequestBody
     */
    'member'?: ManagedMemberUpdateRequest;
}
/**
 * 
 * @export
 * @interface ManagedTransactionCreateRequest
 */
export interface ManagedTransactionCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof ManagedTransactionCreateRequest
     */
    'amount': string;
    /**
     * 
     * @type {string}
     * @memberof ManagedTransactionCreateRequest
     */
    'category'?: string;
    /**
     * 
     * @type {string}
     * @memberof ManagedTransactionCreateRequest
     */
    'check_number_string'?: string;
    /**
     * 
     * @type {string}
     * @memberof ManagedTransactionCreateRequest
     */
    'currency_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ManagedTransactionCreateRequest
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof ManagedTransactionCreateRequest
     */
    'id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ManagedTransactionCreateRequest
     */
    'is_international'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ManagedTransactionCreateRequest
     */
    'latitude'?: number;
    /**
     * 
     * @type {string}
     * @memberof ManagedTransactionCreateRequest
     */
    'localized_description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ManagedTransactionCreateRequest
     */
    'localized_memo'?: string;
    /**
     * 
     * @type {number}
     * @memberof ManagedTransactionCreateRequest
     */
    'longitude'?: number;
    /**
     * 
     * @type {string}
     * @memberof ManagedTransactionCreateRequest
     */
    'memo'?: string;
    /**
     * 
     * @type {number}
     * @memberof ManagedTransactionCreateRequest
     */
    'merchant_category_code'?: number;
    /**
     * 
     * @type {string}
     * @memberof ManagedTransactionCreateRequest
     */
    'merchant_guid'?: string;
    /**
     * 
     * @type {string}
     * @memberof ManagedTransactionCreateRequest
     */
    'merchant_location_guid'?: string;
    /**
     * 
     * @type {string}
     * @memberof ManagedTransactionCreateRequest
     */
    'metadata'?: string;
    /**
     * 
     * @type {string}
     * @memberof ManagedTransactionCreateRequest
     */
    'posted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ManagedTransactionCreateRequest
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof ManagedTransactionCreateRequest
     */
    'transacted_at': string;
    /**
     * 
     * @type {string}
     * @memberof ManagedTransactionCreateRequest
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface ManagedTransactionCreateRequestBody
 */
export interface ManagedTransactionCreateRequestBody {
    /**
     * 
     * @type {ManagedTransactionCreateRequest}
     * @memberof ManagedTransactionCreateRequestBody
     */
    'transaction'?: ManagedTransactionCreateRequest;
}
/**
 * 
 * @export
 * @interface ManagedTransactionUpdateRequest
 */
export interface ManagedTransactionUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof ManagedTransactionUpdateRequest
     */
    'amount'?: string;
    /**
     * 
     * @type {string}
     * @memberof ManagedTransactionUpdateRequest
     */
    'category'?: string;
    /**
     * 
     * @type {string}
     * @memberof ManagedTransactionUpdateRequest
     */
    'check_number_string'?: string;
    /**
     * 
     * @type {string}
     * @memberof ManagedTransactionUpdateRequest
     */
    'currency_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ManagedTransactionUpdateRequest
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ManagedTransactionUpdateRequest
     */
    'id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ManagedTransactionUpdateRequest
     */
    'is_international'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ManagedTransactionUpdateRequest
     */
    'latitude'?: number;
    /**
     * 
     * @type {string}
     * @memberof ManagedTransactionUpdateRequest
     */
    'localized_description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ManagedTransactionUpdateRequest
     */
    'localized_memo'?: string;
    /**
     * 
     * @type {number}
     * @memberof ManagedTransactionUpdateRequest
     */
    'longitude'?: number;
    /**
     * 
     * @type {string}
     * @memberof ManagedTransactionUpdateRequest
     */
    'memo'?: string;
    /**
     * 
     * @type {number}
     * @memberof ManagedTransactionUpdateRequest
     */
    'merchant_category_code'?: number;
    /**
     * 
     * @type {string}
     * @memberof ManagedTransactionUpdateRequest
     */
    'merchant_guid'?: string;
    /**
     * 
     * @type {string}
     * @memberof ManagedTransactionUpdateRequest
     */
    'merchant_location_guid'?: string;
    /**
     * 
     * @type {string}
     * @memberof ManagedTransactionUpdateRequest
     */
    'metadata'?: string;
    /**
     * 
     * @type {string}
     * @memberof ManagedTransactionUpdateRequest
     */
    'posted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ManagedTransactionUpdateRequest
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof ManagedTransactionUpdateRequest
     */
    'transacted_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ManagedTransactionUpdateRequest
     */
    'type'?: string;
}
/**
 * 
 * @export
 * @interface ManagedTransactionUpdateRequestBody
 */
export interface ManagedTransactionUpdateRequestBody {
    /**
     * 
     * @type {ManagedTransactionUpdateRequest}
     * @memberof ManagedTransactionUpdateRequestBody
     */
    'transaction'?: ManagedTransactionUpdateRequest;
}
/**
 * 
 * @export
 * @interface MemberCreateRequest
 */
export interface MemberCreateRequest {
    /**
     * 
     * @type {boolean}
     * @memberof MemberCreateRequest
     */
    'background_aggregation_is_disabled'?: boolean;
    /**
     * 
     * @type {Array<CredentialRequest>}
     * @memberof MemberCreateRequest
     */
    'credentials': Array<CredentialRequest>;
    /**
     * 
     * @type {string}
     * @memberof MemberCreateRequest
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberCreateRequest
     */
    'institution_code': string;
    /**
     * 
     * @type {boolean}
     * @memberof MemberCreateRequest
     */
    'is_oauth'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MemberCreateRequest
     */
    'metadata'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MemberCreateRequest
     */
    'skip_aggregation'?: boolean;
}
/**
 * 
 * @export
 * @interface MemberCreateRequestBody
 */
export interface MemberCreateRequestBody {
    /**
     * 
     * @type {string}
     * @memberof MemberCreateRequestBody
     */
    'client_redirect_url'?: string;
    /**
     * 
     * @type {MemberCreateRequest}
     * @memberof MemberCreateRequestBody
     */
    'member'?: MemberCreateRequest;
    /**
     * 
     * @type {string}
     * @memberof MemberCreateRequestBody
     */
    'referral_source'?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberCreateRequestBody
     */
    'ui_message_webview_url_scheme'?: string;
}
/**
 * 
 * @export
 * @interface MemberResponse
 */
export interface MemberResponse {
    /**
     * 
     * @type {string}
     * @memberof MemberResponse
     */
    'aggregated_at'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MemberResponse
     */
    'background_aggregation_is_disabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MemberResponse
     */
    'connection_status'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MemberResponse
     */
    'guid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MemberResponse
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MemberResponse
     */
    'institution_code'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MemberResponse
     */
    'is_being_aggregated'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MemberResponse
     */
    'is_managed_by_user'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MemberResponse
     */
    'is_oauth'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof MemberResponse
     */
    'metadata'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MemberResponse
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MemberResponse
     */
    'oauth_window_uri'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MemberResponse
     */
    'successfully_aggregated_at'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MemberResponse
     */
    'user_guid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MemberResponse
     */
    'user_id'?: string | null;
}
/**
 * 
 * @export
 * @interface MemberResponseBody
 */
export interface MemberResponseBody {
    /**
     * 
     * @type {MemberResponse}
     * @memberof MemberResponseBody
     */
    'member'?: MemberResponse;
    'error'?: {message?: string}
}
/**
 * 
 * @export
 * @interface MemberResumeRequest
 */
export interface MemberResumeRequest {
    /**
     * 
     * @type {Array<CredentialRequest>}
     * @memberof MemberResumeRequest
     */
    'challenges'?: Array<CredentialRequest>;
}
/**
 * 
 * @export
 * @interface MemberResumeRequestBody
 */
export interface MemberResumeRequestBody {
    /**
     * 
     * @type {MemberResumeRequest}
     * @memberof MemberResumeRequestBody
     */
    'member'?: MemberResumeRequest;
}
/**
 * 
 * @export
 * @interface MemberStatusResponse
 */
export interface MemberStatusResponse {
    /**
     * 
     * @type {string}
     * @memberof MemberStatusResponse
     */
    'aggregated_at'?: string | null;
    /**
     * 
     * @type {Array<ChallengeResponse>}
     * @memberof MemberStatusResponse
     */
    'challenges'?: Array<ChallengeResponse>;
    /**
     * 
     * @type {string}
     * @memberof MemberStatusResponse
     */
    'connection_status'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MemberStatusResponse
     */
    'guid'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MemberStatusResponse
     */
    'has_processed_accounts'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MemberStatusResponse
     */
    'has_processed_transactions'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MemberStatusResponse
     */
    'is_authenticated'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MemberStatusResponse
     */
    'is_being_aggregated'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof MemberStatusResponse
     */
    'successfully_aggregated_at'?: string | null;
}
/**
 * 
 * @export
 * @interface MemberStatusResponseBody
 */
export interface MemberStatusResponseBody {
    /**
     * 
     * @type {MemberStatusResponse}
     * @memberof MemberStatusResponseBody
     */
    'member'?: MemberStatusResponse;
}
/**
 * 
 * @export
 * @interface MemberUpdateRequest
 */
export interface MemberUpdateRequest {
    /**
     * 
     * @type {boolean}
     * @memberof MemberUpdateRequest
     */
    'background_aggregation_is_disabled'?: boolean;
    /**
     * 
     * @type {Array<CredentialRequest>}
     * @memberof MemberUpdateRequest
     */
    'credentials'?: Array<CredentialRequest>;
    /**
     * 
     * @type {string}
     * @memberof MemberUpdateRequest
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberUpdateRequest
     */
    'metadata'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MemberUpdateRequest
     */
    'skip_aggregation'?: boolean;
}
/**
 * 
 * @export
 * @interface MemberUpdateRequestBody
 */
export interface MemberUpdateRequestBody {
    /**
     * 
     * @type {MemberUpdateRequest}
     * @memberof MemberUpdateRequestBody
     */
    'member'?: MemberUpdateRequest;
}
/**
 * 
 * @export
 * @interface MembersResponseBody
 */
export interface MembersResponseBody {
    /**
     * 
     * @type {Array<MemberResponse>}
     * @memberof MembersResponseBody
     */
    'members'?: Array<MemberResponse>;
    /**
     * 
     * @type {PaginationResponse}
     * @memberof MembersResponseBody
     */
    'pagination'?: PaginationResponse;
}
/**
 * 
 * @export
 * @interface MerchantLocationResponse
 */
export interface MerchantLocationResponse {
    /**
     * 
     * @type {string}
     * @memberof MerchantLocationResponse
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MerchantLocationResponse
     */
    'country'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MerchantLocationResponse
     */
    'created_at'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MerchantLocationResponse
     */
    'guid'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MerchantLocationResponse
     */
    'latitude'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MerchantLocationResponse
     */
    'longitude'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MerchantLocationResponse
     */
    'merchant_guid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MerchantLocationResponse
     */
    'phone_number'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MerchantLocationResponse
     */
    'postal_code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MerchantLocationResponse
     */
    'state'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MerchantLocationResponse
     */
    'street_address'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MerchantLocationResponse
     */
    'updated_at'?: string | null;
}
/**
 * 
 * @export
 * @interface MerchantLocationResponseBody
 */
export interface MerchantLocationResponseBody {
    /**
     * 
     * @type {MerchantLocationResponse}
     * @memberof MerchantLocationResponseBody
     */
    'merchant_location'?: MerchantLocationResponse;
}
/**
 * 
 * @export
 * @interface MerchantResponse
 */
export interface MerchantResponse {
    /**
     * 
     * @type {string}
     * @memberof MerchantResponse
     */
    'created_at'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MerchantResponse
     */
    'guid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MerchantResponse
     */
    'logo_url'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MerchantResponse
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MerchantResponse
     */
    'updated_at'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MerchantResponse
     */
    'website_url'?: string | null;
}
/**
 * 
 * @export
 * @interface MerchantResponseBody
 */
export interface MerchantResponseBody {
    /**
     * 
     * @type {MerchantResponse}
     * @memberof MerchantResponseBody
     */
    'merchant'?: MerchantResponse;
}
/**
 * 
 * @export
 * @interface MerchantsResponseBody
 */
export interface MerchantsResponseBody {
    /**
     * 
     * @type {Array<MerchantResponse>}
     * @memberof MerchantsResponseBody
     */
    'merchants'?: Array<MerchantResponse>;
    /**
     * 
     * @type {PaginationResponse}
     * @memberof MerchantsResponseBody
     */
    'pagination'?: PaginationResponse;
}
/**
 * 
 * @export
 * @interface OAuthWindowResponse
 */
export interface OAuthWindowResponse {
    /**
     * 
     * @type {string}
     * @memberof OAuthWindowResponse
     */
    'guid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OAuthWindowResponse
     */
    'oauth_window_uri'?: string | null;
}
/**
 * 
 * @export
 * @interface OAuthWindowResponseBody
 */
export interface OAuthWindowResponseBody {
    /**
     * 
     * @type {OAuthWindowResponse}
     * @memberof OAuthWindowResponseBody
     */
    'member'?: OAuthWindowResponse;
}
/**
 * 
 * @export
 * @interface OptionResponse
 */
export interface OptionResponse {
    /**
     * 
     * @type {string}
     * @memberof OptionResponse
     */
    'label'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OptionResponse
     */
    'value'?: string | null;
}
/**
 * 
 * @export
 * @interface PaginationResponse
 */
export interface PaginationResponse {
    /**
     * 
     * @type {number}
     * @memberof PaginationResponse
     */
    'current_page'?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginationResponse
     */
    'per_page'?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginationResponse
     */
    'total_entries'?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginationResponse
     */
    'total_pages'?: number;
}
/**
 * 
 * @export
 * @interface PaymentProcessorAuthorizationCodeRequest
 */
export interface PaymentProcessorAuthorizationCodeRequest {
    /**
     * 
     * @type {string}
     * @memberof PaymentProcessorAuthorizationCodeRequest
     */
    'account_guid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaymentProcessorAuthorizationCodeRequest
     */
    'member_guid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaymentProcessorAuthorizationCodeRequest
     */
    'user_guid'?: string | null;
}
/**
 * 
 * @export
 * @interface PaymentProcessorAuthorizationCodeRequestBody
 */
export interface PaymentProcessorAuthorizationCodeRequestBody {
    /**
     * 
     * @type {PaymentProcessorAuthorizationCodeRequest}
     * @memberof PaymentProcessorAuthorizationCodeRequestBody
     */
    'payment_processor_authorization_code'?: PaymentProcessorAuthorizationCodeRequest;
}
/**
 * 
 * @export
 * @interface PaymentProcessorAuthorizationCodeResponse
 */
export interface PaymentProcessorAuthorizationCodeResponse {
    /**
     * 
     * @type {string}
     * @memberof PaymentProcessorAuthorizationCodeResponse
     */
    'authorization_code'?: string | null;
}
/**
 * 
 * @export
 * @interface PaymentProcessorAuthorizationCodeResponseBody
 */
export interface PaymentProcessorAuthorizationCodeResponseBody {
    /**
     * 
     * @type {PaymentProcessorAuthorizationCodeResponse}
     * @memberof PaymentProcessorAuthorizationCodeResponseBody
     */
    'payment_processor_authorization_code'?: PaymentProcessorAuthorizationCodeResponse;
}
/**
 * 
 * @export
 * @interface StatementResponse
 */
export interface StatementResponse {
    /**
     * 
     * @type {string}
     * @memberof StatementResponse
     */
    'account_guid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StatementResponse
     */
    'content_hash'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StatementResponse
     */
    'created_at'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StatementResponse
     */
    'guid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StatementResponse
     */
    'member_guid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StatementResponse
     */
    'updated_at'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StatementResponse
     */
    'uri'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StatementResponse
     */
    'user_guid'?: string | null;
}
/**
 * 
 * @export
 * @interface StatementResponseBody
 */
export interface StatementResponseBody {
    /**
     * 
     * @type {StatementResponse}
     * @memberof StatementResponseBody
     */
    'statement'?: StatementResponse;
}
/**
 * 
 * @export
 * @interface StatementsResponseBody
 */
export interface StatementsResponseBody {
    /**
     * 
     * @type {PaginationResponse}
     * @memberof StatementsResponseBody
     */
    'pagination'?: PaginationResponse;
    /**
     * 
     * @type {Array<StatementResponse>}
     * @memberof StatementsResponseBody
     */
    'statements'?: Array<StatementResponse>;
}
/**
 * 
 * @export
 * @interface TagCreateRequest
 */
export interface TagCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof TagCreateRequest
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface TagCreateRequestBody
 */
export interface TagCreateRequestBody {
    /**
     * 
     * @type {TagCreateRequest}
     * @memberof TagCreateRequestBody
     */
    'tag'?: TagCreateRequest;
}
/**
 * 
 * @export
 * @interface TagResponse
 */
export interface TagResponse {
    /**
     * 
     * @type {string}
     * @memberof TagResponse
     */
    'guid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TagResponse
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TagResponse
     */
    'user_guid'?: string | null;
}
/**
 * 
 * @export
 * @interface TagResponseBody
 */
export interface TagResponseBody {
    /**
     * 
     * @type {TagResponse}
     * @memberof TagResponseBody
     */
    'tag'?: TagResponse;
}
/**
 * 
 * @export
 * @interface TagUpdateRequest
 */
export interface TagUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof TagUpdateRequest
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface TagUpdateRequestBody
 */
export interface TagUpdateRequestBody {
    /**
     * 
     * @type {TagUpdateRequest}
     * @memberof TagUpdateRequestBody
     */
    'tag'?: TagUpdateRequest;
}
/**
 * 
 * @export
 * @interface TaggingCreateRequest
 */
export interface TaggingCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof TaggingCreateRequest
     */
    'tag_guid': string;
    /**
     * 
     * @type {string}
     * @memberof TaggingCreateRequest
     */
    'transaction_guid': string;
}
/**
 * 
 * @export
 * @interface TaggingCreateRequestBody
 */
export interface TaggingCreateRequestBody {
    /**
     * 
     * @type {TaggingCreateRequest}
     * @memberof TaggingCreateRequestBody
     */
    'tagging'?: TaggingCreateRequest;
}
/**
 * 
 * @export
 * @interface TaggingResponse
 */
export interface TaggingResponse {
    /**
     * 
     * @type {string}
     * @memberof TaggingResponse
     */
    'guid'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TaggingResponse
     */
    'member_is_managed_by_user'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TaggingResponse
     */
    'tag_guid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TaggingResponse
     */
    'transaction_guid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TaggingResponse
     */
    'user_guid'?: string | null;
}
/**
 * 
 * @export
 * @interface TaggingResponseBody
 */
export interface TaggingResponseBody {
    /**
     * 
     * @type {TaggingResponse}
     * @memberof TaggingResponseBody
     */
    'tagging'?: TaggingResponse;
}
/**
 * 
 * @export
 * @interface TaggingUpdateRequest
 */
export interface TaggingUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof TaggingUpdateRequest
     */
    'tag_guid': string;
}
/**
 * 
 * @export
 * @interface TaggingUpdateRequestBody
 */
export interface TaggingUpdateRequestBody {
    /**
     * 
     * @type {TaggingUpdateRequest}
     * @memberof TaggingUpdateRequestBody
     */
    'tagging'?: TaggingUpdateRequest;
}
/**
 * 
 * @export
 * @interface TaggingsResponseBody
 */
export interface TaggingsResponseBody {
    /**
     * 
     * @type {PaginationResponse}
     * @memberof TaggingsResponseBody
     */
    'pagination'?: PaginationResponse;
    /**
     * 
     * @type {Array<TaggingResponse>}
     * @memberof TaggingsResponseBody
     */
    'taggings'?: Array<TaggingResponse>;
}
/**
 * 
 * @export
 * @interface TagsResponseBody
 */
export interface TagsResponseBody {
    /**
     * 
     * @type {PaginationResponse}
     * @memberof TagsResponseBody
     */
    'pagination'?: PaginationResponse;
    /**
     * 
     * @type {Array<TagResponse>}
     * @memberof TagsResponseBody
     */
    'tags'?: Array<TagResponse>;
}
/**
 * 
 * @export
 * @interface TaxDocumentResponse
 */
export interface TaxDocumentResponse {
    /**
     * 
     * @type {string}
     * @memberof TaxDocumentResponse
     */
    'content_hash'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TaxDocumentResponse
     */
    'created_at'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TaxDocumentResponse
     */
    'document_type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TaxDocumentResponse
     */
    'guid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TaxDocumentResponse
     */
    'issued_on'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TaxDocumentResponse
     */
    'member_guid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TaxDocumentResponse
     */
    'tax_year'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TaxDocumentResponse
     */
    'updated_at'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TaxDocumentResponse
     */
    'uri'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TaxDocumentResponse
     */
    'user_guid'?: string | null;
}
/**
 * 
 * @export
 * @interface TaxDocumentResponseBody
 */
export interface TaxDocumentResponseBody {
    /**
     * 
     * @type {TaxDocumentResponse}
     * @memberof TaxDocumentResponseBody
     */
    'tax_document'?: TaxDocumentResponse;
}
/**
 * 
 * @export
 * @interface TaxDocumentsResponseBody
 */
export interface TaxDocumentsResponseBody {
    /**
     * 
     * @type {PaginationResponse}
     * @memberof TaxDocumentsResponseBody
     */
    'pagination'?: PaginationResponse;
    /**
     * 
     * @type {Array<TaxDocumentResponse>}
     * @memberof TaxDocumentsResponseBody
     */
    'tax_documents'?: Array<TaxDocumentResponse>;
}
/**
 * 
 * @export
 * @interface TransactionResponse
 */
export interface TransactionResponse {
    /**
     * 
     * @type {string}
     * @memberof TransactionResponse
     */
    'account_guid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionResponse
     */
    'account_id'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionResponse
     */
    'amount'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionResponse
     */
    'category'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionResponse
     */
    'category_guid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionResponse
     */
    'check_number_string'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionResponse
     */
    'created_at'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionResponse
     */
    'currency_code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionResponse
     */
    'date'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionResponse
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionResponse
     */
    'extended_transaction_type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionResponse
     */
    'guid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionResponse
     */
    'id'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionResponse
     */
    'is_bill_pay'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionResponse
     */
    'is_direct_deposit'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionResponse
     */
    'is_expense'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionResponse
     */
    'is_fee'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionResponse
     */
    'is_income'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionResponse
     */
    'is_international'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionResponse
     */
    'is_overdraft_fee'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionResponse
     */
    'is_payroll_advance'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionResponse
     */
    'is_recurring'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionResponse
     */
    'is_subscription'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionResponse
     */
    'latitude'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionResponse
     */
    'localized_description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionResponse
     */
    'localized_memo'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionResponse
     */
    'longitude'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionResponse
     */
    'member_guid'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionResponse
     */
    'member_is_managed_by_user'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionResponse
     */
    'memo'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionResponse
     */
    'merchant_category_code'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionResponse
     */
    'merchant_guid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionResponse
     */
    'merchant_location_guid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionResponse
     */
    'metadata'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionResponse
     */
    'original_description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionResponse
     */
    'posted_at'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionResponse
     */
    'status'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionResponse
     */
    'top_level_category'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionResponse
     */
    'transacted_at'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionResponse
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionResponse
     */
    'updated_at'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionResponse
     */
    'user_guid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionResponse
     */
    'user_id'?: string | null;
}
/**
 * 
 * @export
 * @interface TransactionResponseBody
 */
export interface TransactionResponseBody {
    /**
     * 
     * @type {TransactionResponse}
     * @memberof TransactionResponseBody
     */
    'transaction'?: TransactionResponse;
}
/**
 * 
 * @export
 * @interface TransactionRuleCreateRequest
 */
export interface TransactionRuleCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof TransactionRuleCreateRequest
     */
    'category_guid': string;
    /**
     * 
     * @type {string}
     * @memberof TransactionRuleCreateRequest
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionRuleCreateRequest
     */
    'match_description': string;
}
/**
 * 
 * @export
 * @interface TransactionRuleCreateRequestBody
 */
export interface TransactionRuleCreateRequestBody {
    /**
     * 
     * @type {TransactionRuleCreateRequest}
     * @memberof TransactionRuleCreateRequestBody
     */
    'transaction_rule'?: TransactionRuleCreateRequest;
}
/**
 * 
 * @export
 * @interface TransactionRuleResponse
 */
export interface TransactionRuleResponse {
    /**
     * 
     * @type {string}
     * @memberof TransactionRuleResponse
     */
    'category_guid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionRuleResponse
     */
    'created_at'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionRuleResponse
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionRuleResponse
     */
    'guid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionRuleResponse
     */
    'match_description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionRuleResponse
     */
    'updated_at'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionRuleResponse
     */
    'user_guid'?: string | null;
}
/**
 * 
 * @export
 * @interface TransactionRuleResponseBody
 */
export interface TransactionRuleResponseBody {
    /**
     * 
     * @type {TransactionRuleResponse}
     * @memberof TransactionRuleResponseBody
     */
    'transaction_rule'?: TransactionRuleResponse;
}
/**
 * 
 * @export
 * @interface TransactionRuleUpdateRequest
 */
export interface TransactionRuleUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof TransactionRuleUpdateRequest
     */
    'category_guid'?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionRuleUpdateRequest
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionRuleUpdateRequest
     */
    'match_description'?: string;
}
/**
 * 
 * @export
 * @interface TransactionRuleUpdateRequestBody
 */
export interface TransactionRuleUpdateRequestBody {
    /**
     * 
     * @type {TransactionRuleUpdateRequest}
     * @memberof TransactionRuleUpdateRequestBody
     */
    'transaction_rule'?: TransactionRuleUpdateRequest;
}
/**
 * 
 * @export
 * @interface TransactionRulesResponseBody
 */
export interface TransactionRulesResponseBody {
    /**
     * 
     * @type {PaginationResponse}
     * @memberof TransactionRulesResponseBody
     */
    'pagination'?: PaginationResponse;
    /**
     * 
     * @type {Array<TransactionRuleResponse>}
     * @memberof TransactionRulesResponseBody
     */
    'transaction_rules'?: Array<TransactionRuleResponse>;
}
/**
 * 
 * @export
 * @interface TransactionUpdateRequest
 */
export interface TransactionUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof TransactionUpdateRequest
     */
    'description': string;
}
/**
 * 
 * @export
 * @interface TransactionUpdateRequestBody
 */
export interface TransactionUpdateRequestBody {
    /**
     * 
     * @type {TransactionUpdateRequest}
     * @memberof TransactionUpdateRequestBody
     */
    'transaction'?: TransactionUpdateRequest;
}
/**
 * 
 * @export
 * @interface TransactionsResponseBody
 */
export interface TransactionsResponseBody {
    /**
     * 
     * @type {PaginationResponse}
     * @memberof TransactionsResponseBody
     */
    'pagination'?: PaginationResponse;
    /**
     * 
     * @type {Array<TransactionResponse>}
     * @memberof TransactionsResponseBody
     */
    'transactions'?: Array<TransactionResponse>;
}
/**
 * 
 * @export
 * @interface UserCreateRequest
 */
export interface UserCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof UserCreateRequest
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreateRequest
     */
    'id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserCreateRequest
     */
    'is_disabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserCreateRequest
     */
    'metadata'?: string;
}
/**
 * 
 * @export
 * @interface UserCreateRequestBody
 */
export interface UserCreateRequestBody {
    /**
     * 
     * @type {UserCreateRequest}
     * @memberof UserCreateRequestBody
     */
    'user'?: UserCreateRequest;
}
/**
 * 
 * @export
 * @interface UserResponse
 */
export interface UserResponse {
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'guid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'id'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserResponse
     */
    'is_disabled'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'metadata'?: string | null;
}
/**
 * 
 * @export
 * @interface UserResponseBody
 */
export interface UserResponseBody {
    /**
     * 
     * @type {UserResponse}
     * @memberof UserResponseBody
     */
    'user'?: UserResponse;
}
/**
 * 
 * @export
 * @interface UserUpdateRequest
 */
export interface UserUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof UserUpdateRequest
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateRequest
     */
    'id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserUpdateRequest
     */
    'is_disabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateRequest
     */
    'metadata'?: string;
}
/**
 * 
 * @export
 * @interface UserUpdateRequestBody
 */
export interface UserUpdateRequestBody {
    /**
     * 
     * @type {UserUpdateRequest}
     * @memberof UserUpdateRequestBody
     */
    'user'?: UserUpdateRequest;
}
/**
 * 
 * @export
 * @interface UsersResponseBody
 */
export interface UsersResponseBody {
    /**
     * 
     * @type {PaginationResponse}
     * @memberof UsersResponseBody
     */
    'pagination'?: PaginationResponse;
    /**
     * 
     * @type {Array<UserResponse>}
     * @memberof UsersResponseBody
     */
    'users'?: Array<UserResponse>;
}
/**
 * 
 * @export
 * @interface WidgetRequest
 */
export interface WidgetRequest {
    /**
     * 
     * @type {string}
     * @memberof WidgetRequest
     */
    'client_redirect_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof WidgetRequest
     */
    'color_scheme'?: string;
    /**
     * 
     * @type {string}
     * @memberof WidgetRequest
     */
    'current_institution_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof WidgetRequest
     */
    'current_institution_guid'?: string;
    /**
     * 
     * @type {string}
     * @memberof WidgetRequest
     */
    'current_member_guid'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof WidgetRequest
     */
    'disable_background_agg'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WidgetRequest
     */
    'disable_institution_search'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WidgetRequest
     */
    'include_identity'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WidgetRequest
     */
    'include_transactions'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WidgetRequest
     */
    'is_mobile_webview'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof WidgetRequest
     */
    'mode'?: string;
    /**
     * 
     * @type {string}
     * @memberof WidgetRequest
     */
    'oauth_referral_source'?: string;
    /**
     * 
     * @type {number}
     * @memberof WidgetRequest
     */
    'ui_message_version'?: number;
    /**
     * 
     * @type {string}
     * @memberof WidgetRequest
     */
    'ui_message_webview_url_scheme'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof WidgetRequest
     */
    'update_credentials'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof WidgetRequest
     */
    'widget_type': string;
}
/**
 * 
 * @export
 * @interface WidgetRequestBody
 */
export interface WidgetRequestBody {
    /**
     * 
     * @type {WidgetRequest}
     * @memberof WidgetRequestBody
     */
    'widget_url'?: WidgetRequest;
}
/**
 * 
 * @export
 * @interface WidgetResponse
 */
export interface WidgetResponse {
    /**
     * 
     * @type {string}
     * @memberof WidgetResponse
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WidgetResponse
     */
    'url'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WidgetResponse
     */
    'user_id'?: string | null;
}
/**
 * 
 * @export
 * @interface WidgetResponseBody
 */
export interface WidgetResponseBody {
    /**
     * 
     * @type {WidgetResponse}
     * @memberof WidgetResponseBody
     */
    'widget_url'?: WidgetResponse;
}

/**
 * MxPlatformApi - axios parameter creator
 * @export
 */
export const MxPlatformApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Calling this endpoint initiates an aggregation event for the member. This brings in the latest account and transaction data from the connected institution. If this data has recently been updated, MX may not initiate an aggregation event.
         * @summary Aggregate member
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateMember: async (memberGuid: string, userGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberGuid' is not null or undefined
            assertParamExists('aggregateMember', 'memberGuid', memberGuid)
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('aggregateMember', 'userGuid', userGuid)
            const localVarPath = `/users/{user_guid}/members/{member_guid}/aggregate`
                .replace(`{${"member_guid"}}`, encodeURIComponent(String(memberGuid)))
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint operates much like the aggregate member endpoint except that it gathers only account balance information; it does not gather any transaction data.
         * @summary Check balances
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkBalances: async (memberGuid: string, userGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberGuid' is not null or undefined
            assertParamExists('checkBalances', 'memberGuid', memberGuid)
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('checkBalances', 'userGuid', userGuid)
            const localVarPath = `/users/{user_guid}/members/{member_guid}/check_balance`
                .replace(`{${"member_guid"}}`, encodeURIComponent(String(memberGuid)))
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to create a new custom category for a specific `user`.
         * @summary Create category
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {CategoryCreateRequestBody} categoryCreateRequestBody Custom category object to be created
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCategory: async (userGuid: string, categoryCreateRequestBody: CategoryCreateRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('createCategory', 'userGuid', userGuid)
            // verify required parameter 'categoryCreateRequestBody' is not null or undefined
            assertParamExists('createCategory', 'categoryCreateRequestBody', categoryCreateRequestBody)
            const localVarPath = `/users/{user_guid}/categories`
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(categoryCreateRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to create a partner-managed account.
         * @summary Create managed account
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {ManagedAccountCreateRequestBody} managedAccountCreateRequestBody Managed account to be created.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createManagedAccount: async (memberGuid: string, userGuid: string, managedAccountCreateRequestBody: ManagedAccountCreateRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberGuid' is not null or undefined
            assertParamExists('createManagedAccount', 'memberGuid', memberGuid)
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('createManagedAccount', 'userGuid', userGuid)
            // verify required parameter 'managedAccountCreateRequestBody' is not null or undefined
            assertParamExists('createManagedAccount', 'managedAccountCreateRequestBody', managedAccountCreateRequestBody)
            const localVarPath = `/users/{user_guid}/managed_members/{member_guid}/accounts`
                .replace(`{${"member_guid"}}`, encodeURIComponent(String(memberGuid)))
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(managedAccountCreateRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to create a new partner-managed `member`.
         * @summary Create managed member
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {ManagedMemberCreateRequestBody} managedMemberCreateRequestBody Managed member to be created.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createManagedMember: async (userGuid: string, managedMemberCreateRequestBody: ManagedMemberCreateRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('createManagedMember', 'userGuid', userGuid)
            // verify required parameter 'managedMemberCreateRequestBody' is not null or undefined
            assertParamExists('createManagedMember', 'managedMemberCreateRequestBody', managedMemberCreateRequestBody)
            const localVarPath = `/users/{user_guid}/managed_members`
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(managedMemberCreateRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to create a new partner-managed `transaction`.
         * @summary Create managed transaction
         * @param {string} accountGuid The unique id for an &#x60;account&#x60;.
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {ManagedTransactionCreateRequestBody} managedTransactionCreateRequestBody Managed transaction to be created.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createManagedTransaction: async (accountGuid: string, memberGuid: string, userGuid: string, managedTransactionCreateRequestBody: ManagedTransactionCreateRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountGuid' is not null or undefined
            assertParamExists('createManagedTransaction', 'accountGuid', accountGuid)
            // verify required parameter 'memberGuid' is not null or undefined
            assertParamExists('createManagedTransaction', 'memberGuid', memberGuid)
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('createManagedTransaction', 'userGuid', userGuid)
            // verify required parameter 'managedTransactionCreateRequestBody' is not null or undefined
            assertParamExists('createManagedTransaction', 'managedTransactionCreateRequestBody', managedTransactionCreateRequestBody)
            const localVarPath = `/users/{user_guid}/managed_members/{member_guid}/accounts/{account_guid}/transactions`
                .replace(`{${"account_guid"}}`, encodeURIComponent(String(accountGuid)))
                .replace(`{${"member_guid"}}`, encodeURIComponent(String(memberGuid)))
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(managedTransactionCreateRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint allows you to create a new member. Members are created with the required parameters credentials and institution_code, and the optional parameters id and metadata. When creating a member, youll need to include the correct type of credential required by the financial institution and provided by the user. You can find out which credential type is required with the `/institutions/{institution_code}/credentials` endpoint. If successful, the MX Platform API will respond with the newly-created member object. Once you successfully create a member, MX will immediately validate the provided credentials and attempt to aggregate data for accounts and transactions.
         * @summary Create member
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {MemberCreateRequestBody} memberCreateRequestBody Member object to be created with optional parameters (id and metadata) and required parameters (credentials and institution_code)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMember: async (userGuid: string, memberCreateRequestBody: MemberCreateRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('createMember', 'userGuid', userGuid)
            // verify required parameter 'memberCreateRequestBody' is not null or undefined
            assertParamExists('createMember', 'memberCreateRequestBody', memberCreateRequestBody)
            const localVarPath = `/users/{user_guid}/members`
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(memberCreateRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to create a new custom tag.
         * @summary Create tag
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {TagCreateRequestBody} tagCreateRequestBody Tag object to be created with required parameters (tag_guid)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTag: async (userGuid: string, tagCreateRequestBody: TagCreateRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('createTag', 'userGuid', userGuid)
            // verify required parameter 'tagCreateRequestBody' is not null or undefined
            assertParamExists('createTag', 'tagCreateRequestBody', tagCreateRequestBody)
            const localVarPath = `/users/{user_guid}/tags`
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tagCreateRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to create a new association between a tag and a particular transaction, according to their unique GUIDs.
         * @summary Create tagging
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {TaggingCreateRequestBody} taggingCreateRequestBody Tagging object to be created with required parameters (tag_guid and transaction_guid)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTagging: async (userGuid: string, taggingCreateRequestBody: TaggingCreateRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('createTagging', 'userGuid', userGuid)
            // verify required parameter 'taggingCreateRequestBody' is not null or undefined
            assertParamExists('createTagging', 'taggingCreateRequestBody', taggingCreateRequestBody)
            const localVarPath = `/users/{user_guid}/taggings`
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(taggingCreateRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to create a new transaction rule. The newly-created `transaction_rule` object will be returned if successful.
         * @summary Create transaction rule
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {TransactionRuleCreateRequestBody} transactionRuleCreateRequestBody TransactionRule object to be created with optional parameters (description) and required parameters (category_guid and match_description)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTransactionRule: async (userGuid: string, transactionRuleCreateRequestBody: TransactionRuleCreateRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('createTransactionRule', 'userGuid', userGuid)
            // verify required parameter 'transactionRuleCreateRequestBody' is not null or undefined
            assertParamExists('createTransactionRule', 'transactionRuleCreateRequestBody', transactionRuleCreateRequestBody)
            const localVarPath = `/users/{user_guid}/transaction_rules`
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(transactionRuleCreateRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to create a new user. The API will respond with the newly-created user object if successful. Disabling a user means that accounts and transactions associated with it will not be updated in the background by MX. It will also restrict access to that user’s data until they are no longer disabled.
         * @summary Create user
         * @param {UserCreateRequestBody} userCreateRequestBody User object to be created. (None of these parameters are required, but the user object cannot be empty)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: async (userCreateRequestBody: UserCreateRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userCreateRequestBody' is not null or undefined
            assertParamExists('createUser', 'userCreateRequestBody', userCreateRequestBody)
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userCreateRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to delete a specific custom category according to its unique GUID. The API will respond with an empty object and a status of `204 No Content`.
         * @summary Delete category
         * @param {string} categoryGuid The unique id for a &#x60;category&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCategory: async (categoryGuid: string, userGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryGuid' is not null or undefined
            assertParamExists('deleteCategory', 'categoryGuid', categoryGuid)
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('deleteCategory', 'userGuid', userGuid)
            const localVarPath = `/users/{user_guid}/categories/{category_guid}`
                .replace(`{${"category_guid"}}`, encodeURIComponent(String(categoryGuid)))
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to delete a partner-managed account according to its unique GUID. If successful, the API will respond with a status of `204 No Content`.
         * @summary Delete managed account
         * @param {string} accountGuid The unique id for an &#x60;account&#x60;.
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManagedAccount: async (accountGuid: string, memberGuid: string, userGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountGuid' is not null or undefined
            assertParamExists('deleteManagedAccount', 'accountGuid', accountGuid)
            // verify required parameter 'memberGuid' is not null or undefined
            assertParamExists('deleteManagedAccount', 'memberGuid', memberGuid)
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('deleteManagedAccount', 'userGuid', userGuid)
            const localVarPath = `/users/{user_guid}/managed_members/{member_guid}/accounts/{account_guid}`
                .replace(`{${"account_guid"}}`, encodeURIComponent(String(accountGuid)))
                .replace(`{${"member_guid"}}`, encodeURIComponent(String(memberGuid)))
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to delete the specified partner-managed `member`. The endpoint will respond with a status of `204 No Content` without a resource.
         * @summary Delete managed member
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManagedMember: async (memberGuid: string, userGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberGuid' is not null or undefined
            assertParamExists('deleteManagedMember', 'memberGuid', memberGuid)
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('deleteManagedMember', 'userGuid', userGuid)
            const localVarPath = `/users/{user_guid}/managed_members/{member_guid}`
                .replace(`{${"member_guid"}}`, encodeURIComponent(String(memberGuid)))
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to delete the specified partner-managed `transaction`. The endpoint will respond with a status of `204 No Content` without a resource.
         * @summary Delete managed transaction
         * @param {string} accountGuid The unique id for an &#x60;account&#x60;.
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} transactionGuid The unique id for a &#x60;transaction&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManagedTransaction: async (accountGuid: string, memberGuid: string, transactionGuid: string, userGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountGuid' is not null or undefined
            assertParamExists('deleteManagedTransaction', 'accountGuid', accountGuid)
            // verify required parameter 'memberGuid' is not null or undefined
            assertParamExists('deleteManagedTransaction', 'memberGuid', memberGuid)
            // verify required parameter 'transactionGuid' is not null or undefined
            assertParamExists('deleteManagedTransaction', 'transactionGuid', transactionGuid)
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('deleteManagedTransaction', 'userGuid', userGuid)
            const localVarPath = `/users/{user_guid}/managed_members/{member_guid}/accounts/{account_guid}/transactions/{transaction_guid}`
                .replace(`{${"account_guid"}}`, encodeURIComponent(String(accountGuid)))
                .replace(`{${"member_guid"}}`, encodeURIComponent(String(memberGuid)))
                .replace(`{${"transaction_guid"}}`, encodeURIComponent(String(transactionGuid)))
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Accessing this endpoint will permanently delete a member.
         * @summary Delete member
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMember: async (memberGuid: string, userGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberGuid' is not null or undefined
            assertParamExists('deleteMember', 'memberGuid', memberGuid)
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('deleteMember', 'userGuid', userGuid)
            const localVarPath = `/users/{user_guid}/members/{member_guid}`
                .replace(`{${"member_guid"}}`, encodeURIComponent(String(memberGuid)))
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to permanently delete a specific tag based on its unique GUID. If successful, the API will respond with status of `204 No Content`.
         * @summary Delete tag
         * @param {string} tagGuid The unique id for a &#x60;tag&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTag: async (tagGuid: string, userGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tagGuid' is not null or undefined
            assertParamExists('deleteTag', 'tagGuid', tagGuid)
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('deleteTag', 'userGuid', userGuid)
            const localVarPath = `/users/{user_guid}/tags/{tag_guid}`
                .replace(`{${"tag_guid"}}`, encodeURIComponent(String(tagGuid)))
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to delete a tagging according to its unique GUID. If successful, the API will respond with an empty body and a status of 204 NO Content.
         * @summary Delete tagging
         * @param {string} taggingGuid The unique id for a &#x60;tagging&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTagging: async (taggingGuid: string, userGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taggingGuid' is not null or undefined
            assertParamExists('deleteTagging', 'taggingGuid', taggingGuid)
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('deleteTagging', 'userGuid', userGuid)
            const localVarPath = `/users/{user_guid}/taggings/{tagging_guid}`
                .replace(`{${"tagging_guid"}}`, encodeURIComponent(String(taggingGuid)))
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to permanently delete a transaction rule based on its unique GUID.
         * @summary Delete transaction rule
         * @param {string} transactionRuleGuid The unique id for a &#x60;transaction_rule&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTransactionRule: async (transactionRuleGuid: string, userGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'transactionRuleGuid' is not null or undefined
            assertParamExists('deleteTransactionRule', 'transactionRuleGuid', transactionRuleGuid)
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('deleteTransactionRule', 'userGuid', userGuid)
            const localVarPath = `/users/{user_guid}/transaction_rules/{transaction_rule_guid}`
                .replace(`{${"transaction_rule_guid"}}`, encodeURIComponent(String(transactionRuleGuid)))
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to delete the specified `user`. The response will have a status of `204 No Content` without an object.
         * @summary Delete user
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: async (userGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('deleteUser', 'userGuid', userGuid)
            const localVarPath = `/users/{user_guid}`
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * (This endpoint is deprecated. Clients should use `/authorization_code`.) Clients use this endpoint to request an authorization_code according to a user, member, and account specified in the request body. Clients then pass this code to processors. Processor access is scoped only to the user/member/account specified in this request. Before requesting an authorization_code, clients must have verified the specified member.
         * @summary (Deprecated) Request an authorization code.
         * @param {PaymentProcessorAuthorizationCodeRequestBody} paymentProcessorAuthorizationCodeRequestBody The scope for the authorization code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deprecatedRequestPaymentProcessorAuthorizationCode: async (paymentProcessorAuthorizationCodeRequestBody: PaymentProcessorAuthorizationCodeRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'paymentProcessorAuthorizationCodeRequestBody' is not null or undefined
            assertParamExists('deprecatedRequestPaymentProcessorAuthorizationCode', 'paymentProcessorAuthorizationCodeRequestBody', paymentProcessorAuthorizationCodeRequestBody)
            const localVarPath = `/payment_processor_authorization_code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paymentProcessorAuthorizationCodeRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to download a specified statement PDF.
         * @summary Download statement pdf
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} statementGuid The unique id for a &#x60;statement&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadStatementPDF: async (memberGuid: string, statementGuid: string, userGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberGuid' is not null or undefined
            assertParamExists('downloadStatementPDF', 'memberGuid', memberGuid)
            // verify required parameter 'statementGuid' is not null or undefined
            assertParamExists('downloadStatementPDF', 'statementGuid', statementGuid)
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('downloadStatementPDF', 'userGuid', userGuid)
            const localVarPath = `/users/{user_guid}/members/{member_guid}/statements/{statement_guid}.pdf`
                .replace(`{${"member_guid"}}`, encodeURIComponent(String(memberGuid)))
                .replace(`{${"statement_guid"}}`, encodeURIComponent(String(statementGuid)))
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to download a PDF version of the specified tax document. The endpoint URL is the base URL appended with the uri of the tax_document.
         * @summary Download a Tax Document PDF
         * @param {string} taxDocumentGuid The unique id for a &#x60;tax_document&#x60;.
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadTaxDocument: async (taxDocumentGuid: string, memberGuid: string, userGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taxDocumentGuid' is not null or undefined
            assertParamExists('downloadTaxDocument', 'taxDocumentGuid', taxDocumentGuid)
            // verify required parameter 'memberGuid' is not null or undefined
            assertParamExists('downloadTaxDocument', 'memberGuid', memberGuid)
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('downloadTaxDocument', 'userGuid', userGuid)
            const localVarPath = `/users/{user_guid}/members/{member_guid}/tax_documents/{tax_document_guid}.pdf`
                .replace(`{${"tax_document_guid"}}`, encodeURIComponent(String(taxDocumentGuid)))
                .replace(`{${"member_guid"}}`, encodeURIComponent(String(memberGuid)))
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to categorize, cleanse, and classify transactions. These transactions are not persisted or stored on the MX platform.
         * @summary Enhance transactions
         * @param {EnhanceTransactionsRequestBody} enhanceTransactionsRequestBody Transaction object to be enhanced
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enhanceTransactions: async (enhanceTransactionsRequestBody: EnhanceTransactionsRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'enhanceTransactionsRequestBody' is not null or undefined
            assertParamExists('enhanceTransactions', 'enhanceTransactionsRequestBody', enhanceTransactionsRequestBody)
            const localVarPath = `/transactions/enhance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(enhanceTransactionsRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Some institutions allow developers to access an extended transaction history with up to 24 months of data associated with a particular member. The process for fetching and then reading this extended transaction history is much like standard aggregation, and it may trigger multi-factor authentication.
         * @summary Extend history
         * @param {string} memberGuid The unique identifier for a &#x60;member&#x60;.
         * @param {string} userGuid The unique identifier for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        extendHistory: async (memberGuid: string, userGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberGuid' is not null or undefined
            assertParamExists('extendHistory', 'memberGuid', memberGuid)
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('extendHistory', 'userGuid', userGuid)
            const localVarPath = `/users/{user_guid}/members/{member_guid}/extend_history`
                .replace(`{${"member_guid"}}`, encodeURIComponent(String(memberGuid)))
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to fetch the statements associated with a particular member.
         * @summary Fetch statements
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchStatements: async (memberGuid: string, userGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberGuid' is not null or undefined
            assertParamExists('fetchStatements', 'memberGuid', memberGuid)
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('fetchStatements', 'userGuid', userGuid)
            const localVarPath = `/users/{user_guid}/members/{member_guid}/fetch_statements`
                .replace(`{${"member_guid"}}`, encodeURIComponent(String(memberGuid)))
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to fetch (aggregate) the tax documents associated with the specified member. This request **does not** return the latest tax documents. It just starts the document aggregation process and returns the initial state of the process. You must interact with the newly aggregated data using the other document endpoints in this reference. This request may also trigger multi-factor authentication which requires end-user input and a specific process for answering authentication challenges.
         * @summary Fetch Tax Documents
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchTaxDocuments: async (memberGuid: string, userGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberGuid' is not null or undefined
            assertParamExists('fetchTaxDocuments', 'memberGuid', memberGuid)
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('fetchTaxDocuments', 'userGuid', userGuid)
            const localVarPath = `/users/{user_guid}/members/{member_guid}/fetch_tax_documents`
                .replace(`{${"member_guid"}}`, encodeURIComponent(String(memberGuid)))
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The identify endpoint begins an identification process for an already-existing member.
         * @summary Identify member
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identifyMember: async (memberGuid: string, userGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberGuid' is not null or undefined
            assertParamExists('identifyMember', 'memberGuid', memberGuid)
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('identifyMember', 'userGuid', userGuid)
            const localVarPath = `/users/{user_guid}/members/{member_guid}/identify`
                .replace(`{${"member_guid"}}`, encodeURIComponent(String(memberGuid)))
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint returns a list of account numbers associated with the specified `account`.
         * @summary List account numbers by account
         * @param {string} accountGuid The unique id for an &#x60;account&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAccountNumbersByAccount: async (accountGuid: string, userGuid: string, page?: number, recordsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountGuid' is not null or undefined
            assertParamExists('listAccountNumbersByAccount', 'accountGuid', accountGuid)
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('listAccountNumbersByAccount', 'userGuid', userGuid)
            const localVarPath = `/users/{user_guid}/accounts/{account_guid}/account_numbers`
                .replace(`{${"account_guid"}}`, encodeURIComponent(String(accountGuid)))
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (recordsPerPage !== undefined) {
                localVarQueryParameter['records_per_page'] = recordsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint returns a list of account numbers associated with the specified `member`.
         * @summary List account numbers by member
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAccountNumbersByMember: async (memberGuid: string, userGuid: string, page?: number, recordsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberGuid' is not null or undefined
            assertParamExists('listAccountNumbersByMember', 'memberGuid', memberGuid)
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('listAccountNumbersByMember', 'userGuid', userGuid)
            const localVarPath = `/users/{user_guid}/members/{member_guid}/account_numbers`
                .replace(`{${"member_guid"}}`, encodeURIComponent(String(memberGuid)))
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (recordsPerPage !== undefined) {
                localVarQueryParameter['records_per_page'] = recordsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint returns an array with information about every account associated with a particular member.
         * @summary List account owners by member
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAccountOwnersByMember: async (memberGuid: string, userGuid: string, page?: number, recordsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberGuid' is not null or undefined
            assertParamExists('listAccountOwnersByMember', 'memberGuid', memberGuid)
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('listAccountOwnersByMember', 'userGuid', userGuid)
            const localVarPath = `/users/{user_guid}/members/{member_guid}/account_owners`
                .replace(`{${"member_guid"}}`, encodeURIComponent(String(memberGuid)))
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (recordsPerPage !== undefined) {
                localVarQueryParameter['records_per_page'] = recordsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to list all categories associated with a `user`, including both default and custom categories.
         * @summary List categories
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCategories: async (userGuid: string, page?: number, recordsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('listCategories', 'userGuid', userGuid)
            const localVarPath = `/users/{user_guid}/categories`
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (recordsPerPage !== undefined) {
                localVarQueryParameter['records_per_page'] = recordsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to retrieve a list of all the default categories and subcategories offered within the MX Platform API. In other words, each item in the returned list will have its `is_default` field set to `true`. There are currently 119 default categories and subcategories. Both the _list default categories_ and _list default categories by user_ endpoints return the same results. The different routes are provided for convenience.
         * @summary List default categories
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDefaultCategories: async (page?: number, recordsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/categories/default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (recordsPerPage !== undefined) {
                localVarQueryParameter['records_per_page'] = recordsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to retrieve a list of all the default categories and subcategories, scoped by user, offered within the MX Platform API. In other words, each item in the returned list will have its `is_default` field set to `true`. There are currently 119 default categories and subcategories. Both the _list default categories_ and _list default categories by user_ endpoints return the same results. The different routes are provided for convenience.
         * @summary List default categories by user
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDefaultCategoriesByUser: async (userGuid: string, page?: number, recordsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('listDefaultCategoriesByUser', 'userGuid', userGuid)
            const localVarPath = `/users/{user_guid}/categories/default`
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (recordsPerPage !== undefined) {
                localVarQueryParameter['records_per_page'] = recordsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint returns a paginated list containing institutions that have been set as the partner’s favorites, sorted by popularity. Please contact MX to set a list of favorites.
         * @summary List favorite institutions
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFavoriteInstitutions: async (page?: number, recordsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/institutions/favorites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (recordsPerPage !== undefined) {
                localVarQueryParameter['records_per_page'] = recordsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint returns all holdings associated with the specified `user` across all accounts and members.
         * @summary List holdings
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {string} [fromDate] Filter holdings from this date.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {string} [toDate] Filter holdings to this date.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listHoldings: async (userGuid: string, fromDate?: string, page?: number, recordsPerPage?: number, toDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('listHoldings', 'userGuid', userGuid)
            const localVarPath = `/users/{user_guid}/holdings`
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = fromDate;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (recordsPerPage !== undefined) {
                localVarQueryParameter['records_per_page'] = recordsPerPage;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = toDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint returns all holdings associated with the specified `account`.
         * @summary List holdings by account
         * @param {string} accountGuid The unique id for the &#x60;account&#x60;.
         * @param {string} userGuid The unique id for the &#x60;user&#x60;.
         * @param {string} [fromDate] Filter holdings from this date.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {string} [toDate] Filter holdings to this date.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listHoldingsByAccount: async (accountGuid: string, userGuid: string, fromDate?: string, page?: number, recordsPerPage?: number, toDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountGuid' is not null or undefined
            assertParamExists('listHoldingsByAccount', 'accountGuid', accountGuid)
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('listHoldingsByAccount', 'userGuid', userGuid)
            const localVarPath = `/users/{user_guid}/accounts/{account_guid}/holdings`
                .replace(`{${"account_guid"}}`, encodeURIComponent(String(accountGuid)))
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = fromDate;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (recordsPerPage !== undefined) {
                localVarQueryParameter['records_per_page'] = recordsPerPage;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = toDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint returns all holdings associated with the specified `member` across all accounts.
         * @summary List holdings by member
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {string} [fromDate] Filter holdings from this date.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {string} [toDate] Filter holdings to this date.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listHoldingsByMember: async (memberGuid: string, userGuid: string, fromDate?: string, page?: number, recordsPerPage?: number, toDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberGuid' is not null or undefined
            assertParamExists('listHoldingsByMember', 'memberGuid', memberGuid)
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('listHoldingsByMember', 'userGuid', userGuid)
            const localVarPath = `/users/{user_guid}/members/{member_guid}/holdings`
                .replace(`{${"member_guid"}}`, encodeURIComponent(String(memberGuid)))
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = fromDate;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (recordsPerPage !== undefined) {
                localVarQueryParameter['records_per_page'] = recordsPerPage;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = toDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to see which credentials will be needed to create a member for a specific institution.
         * @summary List institution credentials
         * @param {string} institutionCode The institution_code of the institution.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInstitutionCredentials: async (institutionCode: string, page?: number, recordsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'institutionCode' is not null or undefined
            assertParamExists('listInstitutionCredentials', 'institutionCode', institutionCode)
            const localVarPath = `/institutions/{institution_code}/credentials`
                .replace(`{${"institution_code"}}`, encodeURIComponent(String(institutionCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (recordsPerPage !== undefined) {
                localVarQueryParameter['records_per_page'] = recordsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint returns a list of institutions based on the specified search term or parameter.
         * @summary List institutions
         * @param {string} [name] This will list only institutions in which the appended string appears.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {boolean} [supportsAccountIdentification] Filter only institutions which support account identification.
         * @param {boolean} [supportsAccountStatement] Filter only institutions which support account statements.
         * @param {boolean} [supportsAccountVerification] Filter only institutions which support account verification.
         * @param {boolean} [supportsTransactionHistory] Filter only institutions which support extended transaction history.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInstitutions: async (name?: string, page?: number, recordsPerPage?: number, supportsAccountIdentification?: boolean, supportsAccountStatement?: boolean, supportsAccountVerification?: boolean, supportsTransactionHistory?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/institutions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (recordsPerPage !== undefined) {
                localVarQueryParameter['records_per_page'] = recordsPerPage;
            }

            if (supportsAccountIdentification !== undefined) {
                localVarQueryParameter['supports_account_identification'] = supportsAccountIdentification;
            }

            if (supportsAccountStatement !== undefined) {
                localVarQueryParameter['supports_account_statement'] = supportsAccountStatement;
            }

            if (supportsAccountVerification !== undefined) {
                localVarQueryParameter['supports_account_verification'] = supportsAccountVerification;
            }

            if (supportsTransactionHistory !== undefined) {
                localVarQueryParameter['supports_transaction_history'] = supportsTransactionHistory;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to retrieve a list of all the partner-managed accounts associated with the given partner-manage member.
         * @summary List managed accounts
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listManagedAccounts: async (memberGuid: string, userGuid: string, page?: number, recordsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberGuid' is not null or undefined
            assertParamExists('listManagedAccounts', 'memberGuid', memberGuid)
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('listManagedAccounts', 'userGuid', userGuid)
            const localVarPath = `/users/{user_guid}/managed_members/{member_guid}/accounts`
                .replace(`{${"member_guid"}}`, encodeURIComponent(String(memberGuid)))
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (recordsPerPage !== undefined) {
                localVarQueryParameter['records_per_page'] = recordsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint returns a list of institutions which can be used to create partner-managed members.
         * @summary List managed institutions
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listManagedInstitutions: async (page?: number, recordsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/managed_institutions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (recordsPerPage !== undefined) {
                localVarQueryParameter['records_per_page'] = recordsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint returns a list of all the partner-managed members associated with the specified `user`.
         * @summary List managed members
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listManagedMembers: async (userGuid: string, page?: number, recordsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('listManagedMembers', 'userGuid', userGuid)
            const localVarPath = `/users/{user_guid}/managed_members`
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (recordsPerPage !== undefined) {
                localVarQueryParameter['records_per_page'] = recordsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint returns a list of all the partner-managed transactions associated with the specified `account`, scoped through a `user` and a `member`.
         * @summary List managed transactions
         * @param {string} accountGuid The unique id for an &#x60;account&#x60;.
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listManagedTransactions: async (accountGuid: string, memberGuid: string, userGuid: string, page?: number, recordsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountGuid' is not null or undefined
            assertParamExists('listManagedTransactions', 'accountGuid', accountGuid)
            // verify required parameter 'memberGuid' is not null or undefined
            assertParamExists('listManagedTransactions', 'memberGuid', memberGuid)
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('listManagedTransactions', 'userGuid', userGuid)
            const localVarPath = `/users/{user_guid}/managed_members/{member_guid}/accounts/{account_guid}/transactions`
                .replace(`{${"account_guid"}}`, encodeURIComponent(String(accountGuid)))
                .replace(`{${"member_guid"}}`, encodeURIComponent(String(memberGuid)))
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (recordsPerPage !== undefined) {
                localVarQueryParameter['records_per_page'] = recordsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint returns a list of all the accounts associated with the specified `member`.
         * @summary List accounts by member
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {boolean} [memberIsManagedByUser] List only accounts whose member is managed by the user.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMemberAccounts: async (userGuid: string, memberGuid: string, memberIsManagedByUser?: boolean, page?: number, recordsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('listMemberAccounts', 'userGuid', userGuid)
            // verify required parameter 'memberGuid' is not null or undefined
            assertParamExists('listMemberAccounts', 'memberGuid', memberGuid)
            const localVarPath = `/users/{user_guid}/members/{member_guid}/accounts`
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)))
                .replace(`{${"member_guid"}}`, encodeURIComponent(String(memberGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (memberIsManagedByUser !== undefined) {
                localVarQueryParameter['member_is_managed_by_user'] = memberIsManagedByUser;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (recordsPerPage !== undefined) {
                localVarQueryParameter['records_per_page'] = recordsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint for information on what multi-factor authentication challenges need to be answered in order to aggregate a member. If the aggregation is not challenged, i.e., the member does not have a connection status of `CHALLENGED`, then code `204 No Content` will be returned. If the aggregation has been challenged, i.e., the member does have a connection status of `CHALLENGED`, then code `200 OK` will be returned - along with the corresponding credentials.
         * @summary List member challenges
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMemberChallenges: async (memberGuid: string, userGuid: string, page?: number, recordsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberGuid' is not null or undefined
            assertParamExists('listMemberChallenges', 'memberGuid', memberGuid)
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('listMemberChallenges', 'userGuid', userGuid)
            const localVarPath = `/users/{user_guid}/members/{member_guid}/challenges`
                .replace(`{${"member_guid"}}`, encodeURIComponent(String(memberGuid)))
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (recordsPerPage !== undefined) {
                localVarQueryParameter['records_per_page'] = recordsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint returns an array which contains information on every non-MFA credential associated with a specific member.
         * @summary List member credentials
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMemberCredentials: async (memberGuid: string, userGuid: string, page?: number, recordsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberGuid' is not null or undefined
            assertParamExists('listMemberCredentials', 'memberGuid', memberGuid)
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('listMemberCredentials', 'userGuid', userGuid)
            const localVarPath = `/users/{user_guid}/members/{member_guid}/credentials`
                .replace(`{${"member_guid"}}`, encodeURIComponent(String(memberGuid)))
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (recordsPerPage !== undefined) {
                localVarQueryParameter['records_per_page'] = recordsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint returns an array which contains information on every member associated with a specific user.
         * @summary List members
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMembers: async (userGuid: string, page?: number, recordsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('listMembers', 'userGuid', userGuid)
            const localVarPath = `/users/{user_guid}/members`
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (recordsPerPage !== undefined) {
                localVarQueryParameter['records_per_page'] = recordsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint returns a paginated list of all the merchants in the MX system.
         * @summary List merchants
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMerchants: async (page?: number, recordsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/merchants`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (recordsPerPage !== undefined) {
                localVarQueryParameter['records_per_page'] = recordsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to get an array of available statements.
         * @summary List statements by member
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStatementsByMember: async (memberGuid: string, userGuid: string, page?: number, recordsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberGuid' is not null or undefined
            assertParamExists('listStatementsByMember', 'memberGuid', memberGuid)
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('listStatementsByMember', 'userGuid', userGuid)
            const localVarPath = `/users/{user_guid}/members/{member_guid}/statements`
                .replace(`{${"member_guid"}}`, encodeURIComponent(String(memberGuid)))
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (recordsPerPage !== undefined) {
                localVarQueryParameter['records_per_page'] = recordsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to retrieve a list of all the taggings associated with a specific user.
         * @summary List taggings
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTaggings: async (userGuid: string, page?: number, recordsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('listTaggings', 'userGuid', userGuid)
            const localVarPath = `/users/{user_guid}/taggings`
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (recordsPerPage !== undefined) {
                localVarQueryParameter['records_per_page'] = recordsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to list all tags associated with the specified `user`. Each user includes the `Business` tag by default.
         * @summary List tags
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTags: async (userGuid: string, page?: number, recordsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('listTags', 'userGuid', userGuid)
            const localVarPath = `/users/{user_guid}/tags`
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (recordsPerPage !== undefined) {
                localVarQueryParameter['records_per_page'] = recordsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to get a paginated list of tax documents.
         * @summary List Tax Documents
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTaxDocuments: async (memberGuid: string, userGuid: string, page?: number, recordsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberGuid' is not null or undefined
            assertParamExists('listTaxDocuments', 'memberGuid', memberGuid)
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('listTaxDocuments', 'userGuid', userGuid)
            const localVarPath = `/users/{user_guid}/members/{member_guid}/tax_documents`
                .replace(`{${"member_guid"}}`, encodeURIComponent(String(memberGuid)))
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (recordsPerPage !== undefined) {
                localVarQueryParameter['records_per_page'] = recordsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to read the attributes of all existing transaction rules belonging to the user.
         * @summary List transaction rules
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTransactionRules: async (userGuid: string, page?: number, recordsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('listTransactionRules', 'userGuid', userGuid)
            const localVarPath = `/users/{user_guid}/transaction_rules`
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (recordsPerPage !== undefined) {
                localVarQueryParameter['records_per_page'] = recordsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Requests to this endpoint return a list of transactions associated with the specified `user`, accross all members and accounts associated with that `user`.
         * @summary List transactions
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {string} [fromDate] Filter transactions from this date.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {string} [toDate] Filter transactions to this date.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTransactions: async (userGuid: string, fromDate?: string, page?: number, recordsPerPage?: number, toDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('listTransactions', 'userGuid', userGuid)
            const localVarPath = `/users/{user_guid}/transactions`
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = fromDate;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (recordsPerPage !== undefined) {
                localVarQueryParameter['records_per_page'] = recordsPerPage;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = toDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint returns a list of the last 90 days of transactions associated with the specified account.
         * @summary List transactions by account
         * @param {string} accountGuid The unique id for an &#x60;account&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {string} [fromDate] Filter transactions from this date.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {string} [toDate] Filter transactions to this date.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTransactionsByAccount: async (accountGuid: string, userGuid: string, fromDate?: string, page?: number, recordsPerPage?: number, toDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountGuid' is not null or undefined
            assertParamExists('listTransactionsByAccount', 'accountGuid', accountGuid)
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('listTransactionsByAccount', 'userGuid', userGuid)
            const localVarPath = `/users/{user_guid}/accounts/{account_guid}/transactions`
                .replace(`{${"account_guid"}}`, encodeURIComponent(String(accountGuid)))
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = fromDate;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (recordsPerPage !== undefined) {
                localVarQueryParameter['records_per_page'] = recordsPerPage;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = toDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Requests to this endpoint return a list of transactions associated with the specified `member`, accross all accounts associated with that `member`.
         * @summary List transactions by member
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {string} [fromDate] Filter transactions from this date.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {string} [toDate] Filter transactions to this date.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTransactionsByMember: async (memberGuid: string, userGuid: string, fromDate?: string, page?: number, recordsPerPage?: number, toDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberGuid' is not null or undefined
            assertParamExists('listTransactionsByMember', 'memberGuid', memberGuid)
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('listTransactionsByMember', 'userGuid', userGuid)
            const localVarPath = `/users/{user_guid}/members/{member_guid}/transactions`
                .replace(`{${"member_guid"}}`, encodeURIComponent(String(memberGuid)))
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = fromDate;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (recordsPerPage !== undefined) {
                localVarQueryParameter['records_per_page'] = recordsPerPage;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = toDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to get a list of all transactions associated with a particular tag according to the tag’s unique GUID. In other words, a list of all transactions that have been assigned to a particular tag using the create a tagging endpoint.
         * @summary List transactions by tag
         * @param {string} tagGuid The unique id for a &#x60;tag&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {string} [fromDate] Filter transactions from this date.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {string} [toDate] Filter transactions to this date.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTransactionsByTag: async (tagGuid: string, userGuid: string, fromDate?: string, page?: number, recordsPerPage?: number, toDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tagGuid' is not null or undefined
            assertParamExists('listTransactionsByTag', 'tagGuid', tagGuid)
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('listTransactionsByTag', 'userGuid', userGuid)
            const localVarPath = `/users/{user_guid}/tags/{tag_guid}/transactions`
                .replace(`{${"tag_guid"}}`, encodeURIComponent(String(tagGuid)))
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = fromDate;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (recordsPerPage !== undefined) {
                localVarQueryParameter['records_per_page'] = recordsPerPage;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = toDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint returns a list of all the accounts associated with the specified `user`.
         * @summary List accounts
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {boolean} [memberIsManagedByUser] List only accounts whose member is managed by the user.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserAccounts: async (userGuid: string, memberIsManagedByUser?: boolean, page?: number, recordsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('listUserAccounts', 'userGuid', userGuid)
            const localVarPath = `/users/{user_guid}/accounts`
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (memberIsManagedByUser !== undefined) {
                localVarQueryParameter['member_is_managed_by_user'] = memberIsManagedByUser;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (recordsPerPage !== undefined) {
                localVarQueryParameter['records_per_page'] = recordsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to list every user you\'ve created in the MX Platform API.
         * @summary List users
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {string} [id] The user &#x60;id&#x60; to search for.
         * @param {string} [email] The user &#x60;email&#x60; to search for.
         * @param {boolean} [isDisabled] Search for users that are diabled.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsers: async (page?: number, recordsPerPage?: number, id?: string, email?: string, isDisabled?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (recordsPerPage !== undefined) {
                localVarQueryParameter['records_per_page'] = recordsPerPage;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (isDisabled !== undefined) {
                localVarQueryParameter['is_disabled'] = isDisabled;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint returns the specified `account` resource.
         * @summary Read account
         * @param {string} accountGuid The unique id for an &#x60;account&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAccount: async (accountGuid: string, userGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountGuid' is not null or undefined
            assertParamExists('readAccount', 'accountGuid', accountGuid)
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('readAccount', 'userGuid', userGuid)
            const localVarPath = `/users/{user_guid}/accounts/{account_guid}`
                .replace(`{${"account_guid"}}`, encodeURIComponent(String(accountGuid)))
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint allows you to read the attributes of an `account` resource.
         * @summary Read account by member
         * @param {string} accountGuid The unique id for an &#x60;account&#x60;.
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAccountByMember: async (accountGuid: string, memberGuid: string, userGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountGuid' is not null or undefined
            assertParamExists('readAccountByMember', 'accountGuid', accountGuid)
            // verify required parameter 'memberGuid' is not null or undefined
            assertParamExists('readAccountByMember', 'memberGuid', memberGuid)
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('readAccountByMember', 'userGuid', userGuid)
            const localVarPath = `/users/{user_guid}/members/{member_guid}/accounts/{account_guid}`
                .replace(`{${"account_guid"}}`, encodeURIComponent(String(accountGuid)))
                .replace(`{${"member_guid"}}`, encodeURIComponent(String(memberGuid)))
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to read the attributes of either a default category or a custom category.
         * @summary Read a custom category
         * @param {string} categoryGuid The unique id for a &#x60;category&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCategory: async (categoryGuid: string, userGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryGuid' is not null or undefined
            assertParamExists('readCategory', 'categoryGuid', categoryGuid)
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('readCategory', 'userGuid', userGuid)
            const localVarPath = `/users/{user_guid}/categories/{category_guid}`
                .replace(`{${"category_guid"}}`, encodeURIComponent(String(categoryGuid)))
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to read the attributes of a default category.
         * @summary Read a default category
         * @param {string} categoryGuid The unique id for a &#x60;category&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDefaultCategory: async (categoryGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryGuid' is not null or undefined
            assertParamExists('readDefaultCategory', 'categoryGuid', categoryGuid)
            const localVarPath = `/categories/{category_guid}`
                .replace(`{${"category_guid"}}`, encodeURIComponent(String(categoryGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to read the attributes of a specific `holding`.
         * @summary Read holding
         * @param {string} holdingGuid The unique id for a &#x60;holding&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readHolding: async (holdingGuid: string, userGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'holdingGuid' is not null or undefined
            assertParamExists('readHolding', 'holdingGuid', holdingGuid)
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('readHolding', 'userGuid', userGuid)
            const localVarPath = `/users/{user_guid}/holdings/{holding_guid}`
                .replace(`{${"holding_guid"}}`, encodeURIComponent(String(holdingGuid)))
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint returns information about the institution specified by `institution_code`.
         * @summary Read institution
         * @param {string} institutionCode The institution_code of the institution.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readInstitution: async (institutionCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'institutionCode' is not null or undefined
            assertParamExists('readInstitution', 'institutionCode', institutionCode)
            const localVarPath = `/institutions/{institution_code}`
                .replace(`{${"institution_code"}}`, encodeURIComponent(String(institutionCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to read the attributes of a partner-managed account according to its unique guid.
         * @summary Read managed account
         * @param {string} accountGuid The unique id for an &#x60;account&#x60;.
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readManagedAccount: async (accountGuid: string, memberGuid: string, userGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountGuid' is not null or undefined
            assertParamExists('readManagedAccount', 'accountGuid', accountGuid)
            // verify required parameter 'memberGuid' is not null or undefined
            assertParamExists('readManagedAccount', 'memberGuid', memberGuid)
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('readManagedAccount', 'userGuid', userGuid)
            const localVarPath = `/users/{user_guid}/managed_members/{member_guid}/accounts/{account_guid}`
                .replace(`{${"account_guid"}}`, encodeURIComponent(String(accountGuid)))
                .replace(`{${"member_guid"}}`, encodeURIComponent(String(memberGuid)))
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint returns the attributes of the specified partner-managed `member`.
         * @summary Read managed member
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readManagedMember: async (memberGuid: string, userGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberGuid' is not null or undefined
            assertParamExists('readManagedMember', 'memberGuid', memberGuid)
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('readManagedMember', 'userGuid', userGuid)
            const localVarPath = `/users/{user_guid}/managed_members/{member_guid}`
                .replace(`{${"member_guid"}}`, encodeURIComponent(String(memberGuid)))
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Requests to this endpoint will return the attributes of the specified partner-managed `transaction`.
         * @summary Read managed transaction
         * @param {string} accountGuid The unique id for an &#x60;account&#x60;.
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} transactionGuid The unique id for a &#x60;transaction&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readManagedTransaction: async (accountGuid: string, memberGuid: string, transactionGuid: string, userGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountGuid' is not null or undefined
            assertParamExists('readManagedTransaction', 'accountGuid', accountGuid)
            // verify required parameter 'memberGuid' is not null or undefined
            assertParamExists('readManagedTransaction', 'memberGuid', memberGuid)
            // verify required parameter 'transactionGuid' is not null or undefined
            assertParamExists('readManagedTransaction', 'transactionGuid', transactionGuid)
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('readManagedTransaction', 'userGuid', userGuid)
            const localVarPath = `/users/{user_guid}/managed_members/{member_guid}/accounts/{account_guid}/transactions/{transaction_guid}`
                .replace(`{${"account_guid"}}`, encodeURIComponent(String(accountGuid)))
                .replace(`{${"member_guid"}}`, encodeURIComponent(String(memberGuid)))
                .replace(`{${"transaction_guid"}}`, encodeURIComponent(String(transactionGuid)))
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to read the attributes of a specific member.
         * @summary Read member
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readMember: async (memberGuid: string, userGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberGuid' is not null or undefined
            assertParamExists('readMember', 'memberGuid', memberGuid)
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('readMember', 'userGuid', userGuid)
            const localVarPath = `/users/{user_guid}/members/{member_guid}`
                .replace(`{${"member_guid"}}`, encodeURIComponent(String(memberGuid)))
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint provides the status of the members most recent aggregation event. This is an important step in the aggregation process, and the results returned by this endpoint should determine what you do next in order to successfully aggregate a member. MX has introduced new, more detailed information on the current status of a members connection to a financial institution and the state of its aggregation - the connection_status field. These are intended to replace and expand upon the information provided in the status field, which will soon be deprecated; support for the status field remains for the time being.
         * @summary Read member status
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readMemberStatus: async (memberGuid: string, userGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberGuid' is not null or undefined
            assertParamExists('readMemberStatus', 'memberGuid', memberGuid)
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('readMemberStatus', 'userGuid', userGuid)
            const localVarPath = `/users/{user_guid}/members/{member_guid}/status`
                .replace(`{${"member_guid"}}`, encodeURIComponent(String(memberGuid)))
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns information about a particular merchant, such as a logo, name, and website.
         * @summary Read merchant
         * @param {string} merchantGuid The unique id for a &#x60;merchant&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readMerchant: async (merchantGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'merchantGuid' is not null or undefined
            assertParamExists('readMerchant', 'merchantGuid', merchantGuid)
            const localVarPath = `/merchants/{merchant_guid}`
                .replace(`{${"merchant_guid"}}`, encodeURIComponent(String(merchantGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint returns the specified merchant_location resource.
         * @summary Read merchant location
         * @param {string} merchantLocationGuid The unique id for a &#x60;merchant_location&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readMerchantLocation: async (merchantLocationGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'merchantLocationGuid' is not null or undefined
            assertParamExists('readMerchantLocation', 'merchantLocationGuid', merchantLocationGuid)
            const localVarPath = `/merchant_locations/{merchant_location_guid}`
                .replace(`{${"merchant_location_guid"}}`, encodeURIComponent(String(merchantLocationGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to read a JSON representation of the statement.
         * @summary Read statement by member
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} statementGuid The unique id for a &#x60;statement&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readStatementByMember: async (memberGuid: string, statementGuid: string, userGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberGuid' is not null or undefined
            assertParamExists('readStatementByMember', 'memberGuid', memberGuid)
            // verify required parameter 'statementGuid' is not null or undefined
            assertParamExists('readStatementByMember', 'statementGuid', statementGuid)
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('readStatementByMember', 'userGuid', userGuid)
            const localVarPath = `/users/{user_guid}/members/{member_guid}/statements/{statement_guid}`
                .replace(`{${"member_guid"}}`, encodeURIComponent(String(memberGuid)))
                .replace(`{${"statement_guid"}}`, encodeURIComponent(String(statementGuid)))
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to read the attributes of a particular tag according to its unique GUID.
         * @summary Read tag
         * @param {string} tagGuid The unique id for a &#x60;tag&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readTag: async (tagGuid: string, userGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tagGuid' is not null or undefined
            assertParamExists('readTag', 'tagGuid', tagGuid)
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('readTag', 'userGuid', userGuid)
            const localVarPath = `/users/{user_guid}/tags/{tag_guid}`
                .replace(`{${"tag_guid"}}`, encodeURIComponent(String(tagGuid)))
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to read the attributes of a `tagging` according to its unique GUID.
         * @summary Read tagging
         * @param {string} taggingGuid The unique id for a &#x60;tagging&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readTagging: async (taggingGuid: string, userGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taggingGuid' is not null or undefined
            assertParamExists('readTagging', 'taggingGuid', taggingGuid)
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('readTagging', 'userGuid', userGuid)
            const localVarPath = `/users/{user_guid}/taggings/{tagging_guid}`
                .replace(`{${"tagging_guid"}}`, encodeURIComponent(String(taggingGuid)))
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to read the attributes of the specified tax document.
         * @summary Read a Tax Document
         * @param {string} taxDocumentGuid The unique id for a &#x60;tax_document&#x60;.
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readTaxDocument: async (taxDocumentGuid: string, memberGuid: string, userGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taxDocumentGuid' is not null or undefined
            assertParamExists('readTaxDocument', 'taxDocumentGuid', taxDocumentGuid)
            // verify required parameter 'memberGuid' is not null or undefined
            assertParamExists('readTaxDocument', 'memberGuid', memberGuid)
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('readTaxDocument', 'userGuid', userGuid)
            const localVarPath = `/users/{user_guid}/members/{member_guid}/tax_documents/{tax_document_guid}`
                .replace(`{${"tax_document_guid"}}`, encodeURIComponent(String(taxDocumentGuid)))
                .replace(`{${"member_guid"}}`, encodeURIComponent(String(memberGuid)))
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Requests to this endpoint will return the attributes of the specified `transaction`.
         * @summary Read transaction
         * @param {string} transactionGuid The unique id for a &#x60;transaction&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readTransaction: async (transactionGuid: string, userGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'transactionGuid' is not null or undefined
            assertParamExists('readTransaction', 'transactionGuid', transactionGuid)
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('readTransaction', 'userGuid', userGuid)
            const localVarPath = `/users/{user_guid}/transactions/{transaction_guid}`
                .replace(`{${"transaction_guid"}}`, encodeURIComponent(String(transactionGuid)))
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to read the attributes of an existing transaction rule based on the rule’s unique GUID.
         * @summary Read transaction rule
         * @param {string} transactionRuleGuid The unique id for a &#x60;transaction_rule&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readTransactionRule: async (transactionRuleGuid: string, userGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'transactionRuleGuid' is not null or undefined
            assertParamExists('readTransactionRule', 'transactionRuleGuid', transactionRuleGuid)
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('readTransactionRule', 'userGuid', userGuid)
            const localVarPath = `/users/{user_guid}/transaction_rules/{transaction_rule_guid}`
                .replace(`{${"transaction_rule_guid"}}`, encodeURIComponent(String(transactionRuleGuid)))
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to read the attributes of a specific user.
         * @summary Read user
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUser: async (userGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('readUser', 'userGuid', userGuid)
            const localVarPath = `/users/{user_guid}`
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Clients use this endpoint to request an authorization code according to the parameters specified in the scope. Clients then pass this code to processors. Processor access is scoped only to the GUIDs and features specified in this request. Before requesting an authorization code which includes a member in the scope, clients must have verified that member.
         * @summary Request an authorization code.
         * @param {AuthorizationCodeRequestBody} authorizationCodeRequestBody The scope for the authorization code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestAuthorizationCode: async (authorizationCodeRequestBody: AuthorizationCodeRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorizationCodeRequestBody' is not null or undefined
            assertParamExists('requestAuthorizationCode', 'authorizationCodeRequestBody', authorizationCodeRequestBody)
            const localVarPath = `/authorization_code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authorizationCodeRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint will return a URL for an embeddable version of MX Connect.
         * @summary Request connect widget url
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {ConnectWidgetRequestBody} connectWidgetRequestBody Optional config options for WebView (is_mobile_webview, current_institution_code, current_member_guid, update_credentials)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestConnectWidgetURL: async (userGuid: string, connectWidgetRequestBody: ConnectWidgetRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('requestConnectWidgetURL', 'userGuid', userGuid)
            // verify required parameter 'connectWidgetRequestBody' is not null or undefined
            assertParamExists('requestConnectWidgetURL', 'connectWidgetRequestBody', connectWidgetRequestBody)
            const localVarPath = `/users/{user_guid}/connect_widget_url`
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(connectWidgetRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint will generate an `oauth_window_uri` for the specified `member`.
         * @summary Request oauth window uri
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {string} [clientRedirectUrl] A URL that MX will redirect to at the end of OAuth with additional query parameters. Only available with &#x60;referral_source&#x3D;APP&#x60;.
         * @param {string} [referralSource] Must be either &#x60;BROWSER&#x60; or &#x60;APP&#x60; depending on the implementation. Defaults to &#x60;BROWSER&#x60;.
         * @param {boolean} [skipAggregation] Setting this parameter to &#x60;true&#x60; will prevent the member from automatically aggregating after being redirected from the authorization page.
         * @param {string} [uiMessageWebviewUrlScheme] A scheme for routing the user back to the application state they were previously in. Only available with &#x60;referral_source&#x3D;APP&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestOAuthWindowURI: async (memberGuid: string, userGuid: string, clientRedirectUrl?: string, referralSource?: string, skipAggregation?: boolean, uiMessageWebviewUrlScheme?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberGuid' is not null or undefined
            assertParamExists('requestOAuthWindowURI', 'memberGuid', memberGuid)
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('requestOAuthWindowURI', 'userGuid', userGuid)
            const localVarPath = `/users/{user_guid}/members/{member_guid}/oauth_window_uri`
                .replace(`{${"member_guid"}}`, encodeURIComponent(String(memberGuid)))
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (clientRedirectUrl !== undefined) {
                localVarQueryParameter['client_redirect_url'] = clientRedirectUrl;
            }

            if (referralSource !== undefined) {
                localVarQueryParameter['referral_source'] = referralSource;
            }

            if (skipAggregation !== undefined) {
                localVarQueryParameter['skip_aggregation'] = skipAggregation;
            }

            if (uiMessageWebviewUrlScheme !== undefined) {
                localVarQueryParameter['ui_message_webview_url_scheme'] = uiMessageWebviewUrlScheme;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint allows partners to get a URL by passing the `widget_type` in the request body, as well as configuring it in several different ways. In the case of Connect, that means setting the `widget_type` to `connect_widget`. Partners may also pass an optional `Accept-Language` header as well as a number of configuration options. Note that this is a `POST` request.
         * @summary Request widget url
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {WidgetRequestBody} widgetRequestBody The widget url configuration options.
         * @param {string} [acceptLanguage] The desired language of the widget.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestWidgetURL: async (userGuid: string, widgetRequestBody: WidgetRequestBody, acceptLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('requestWidgetURL', 'userGuid', userGuid)
            // verify required parameter 'widgetRequestBody' is not null or undefined
            assertParamExists('requestWidgetURL', 'widgetRequestBody', widgetRequestBody)
            const localVarPath = `/users/{user_guid}/widget_urls`
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(widgetRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint answers the challenges needed when a member has been challenged by multi-factor authentication.
         * @summary Resume aggregation
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {MemberResumeRequestBody} memberResumeRequestBody Member object with MFA challenge answers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resumeAggregation: async (memberGuid: string, userGuid: string, memberResumeRequestBody: MemberResumeRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberGuid' is not null or undefined
            assertParamExists('resumeAggregation', 'memberGuid', memberGuid)
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('resumeAggregation', 'userGuid', userGuid)
            // verify required parameter 'memberResumeRequestBody' is not null or undefined
            assertParamExists('resumeAggregation', 'memberResumeRequestBody', memberResumeRequestBody)
            const localVarPath = `/users/{user_guid}/members/{member_guid}/resume`
                .replace(`{${"member_guid"}}`, encodeURIComponent(String(memberGuid)))
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(memberResumeRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint allows you to update certain attributes of an `account` resource.
         * @summary Update account by member
         * @param {string} accountGuid The unique id for an &#x60;account&#x60;.
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {AccountUpdateRequestBody} accountUpdateRequestBody Account object to be created with optional parameters (is_hidden)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountByMember: async (accountGuid: string, memberGuid: string, userGuid: string, accountUpdateRequestBody: AccountUpdateRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountGuid' is not null or undefined
            assertParamExists('updateAccountByMember', 'accountGuid', accountGuid)
            // verify required parameter 'memberGuid' is not null or undefined
            assertParamExists('updateAccountByMember', 'memberGuid', memberGuid)
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('updateAccountByMember', 'userGuid', userGuid)
            // verify required parameter 'accountUpdateRequestBody' is not null or undefined
            assertParamExists('updateAccountByMember', 'accountUpdateRequestBody', accountUpdateRequestBody)
            const localVarPath = `/users/{user_guid}/members/{member_guid}/accounts/{account_guid}`
                .replace(`{${"account_guid"}}`, encodeURIComponent(String(accountGuid)))
                .replace(`{${"member_guid"}}`, encodeURIComponent(String(memberGuid)))
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accountUpdateRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to update the attributes of a custom category according to its unique GUID.
         * @summary Update category
         * @param {string} categoryGuid The unique id for a &#x60;category&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {CategoryUpdateRequestBody} categoryUpdateRequestBody Category object to be updated (While no single parameter is required, the &#x60;category&#x60; object cannot be empty)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCategory: async (categoryGuid: string, userGuid: string, categoryUpdateRequestBody: CategoryUpdateRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryGuid' is not null or undefined
            assertParamExists('updateCategory', 'categoryGuid', categoryGuid)
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('updateCategory', 'userGuid', userGuid)
            // verify required parameter 'categoryUpdateRequestBody' is not null or undefined
            assertParamExists('updateCategory', 'categoryUpdateRequestBody', categoryUpdateRequestBody)
            const localVarPath = `/users/{user_guid}/categories/{category_guid}`
                .replace(`{${"category_guid"}}`, encodeURIComponent(String(categoryGuid)))
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(categoryUpdateRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to update the attributes of a partner-managed account according to its unique GUID.
         * @summary Update managed account
         * @param {string} accountGuid The unique id for an &#x60;account&#x60;.
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {ManagedAccountUpdateRequestBody} managedAccountUpdateRequestBody Managed account object to be updated (While no single parameter is required, the request body can\&#39;t be empty)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateManagedAccount: async (accountGuid: string, memberGuid: string, userGuid: string, managedAccountUpdateRequestBody: ManagedAccountUpdateRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountGuid' is not null or undefined
            assertParamExists('updateManagedAccount', 'accountGuid', accountGuid)
            // verify required parameter 'memberGuid' is not null or undefined
            assertParamExists('updateManagedAccount', 'memberGuid', memberGuid)
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('updateManagedAccount', 'userGuid', userGuid)
            // verify required parameter 'managedAccountUpdateRequestBody' is not null or undefined
            assertParamExists('updateManagedAccount', 'managedAccountUpdateRequestBody', managedAccountUpdateRequestBody)
            const localVarPath = `/users/{user_guid}/managed_members/{member_guid}/accounts/{account_guid}`
                .replace(`{${"account_guid"}}`, encodeURIComponent(String(accountGuid)))
                .replace(`{${"member_guid"}}`, encodeURIComponent(String(memberGuid)))
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(managedAccountUpdateRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to update the attributes of the specified partner_managed `member`.
         * @summary Update managed member
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {ManagedMemberUpdateRequestBody} managedMemberUpdateRequestBody Managed member object to be updated (While no single parameter is required, the request body can\&#39;t be empty)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateManagedMember: async (memberGuid: string, userGuid: string, managedMemberUpdateRequestBody: ManagedMemberUpdateRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberGuid' is not null or undefined
            assertParamExists('updateManagedMember', 'memberGuid', memberGuid)
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('updateManagedMember', 'userGuid', userGuid)
            // verify required parameter 'managedMemberUpdateRequestBody' is not null or undefined
            assertParamExists('updateManagedMember', 'managedMemberUpdateRequestBody', managedMemberUpdateRequestBody)
            const localVarPath = `/users/{user_guid}/managed_members/{member_guid}`
                .replace(`{${"member_guid"}}`, encodeURIComponent(String(memberGuid)))
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(managedMemberUpdateRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to update the attributes of the specified partner_managed `transaction`.
         * @summary Update managed transaction
         * @param {string} accountGuid The unique id for an &#x60;account&#x60;.
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} transactionGuid The unique id for a &#x60;transaction&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {ManagedTransactionUpdateRequestBody} managedTransactionUpdateRequestBody Managed transaction object to be updated (While no single parameter is required, the request body can\&#39;t be empty)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateManagedTransaction: async (accountGuid: string, memberGuid: string, transactionGuid: string, userGuid: string, managedTransactionUpdateRequestBody: ManagedTransactionUpdateRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountGuid' is not null or undefined
            assertParamExists('updateManagedTransaction', 'accountGuid', accountGuid)
            // verify required parameter 'memberGuid' is not null or undefined
            assertParamExists('updateManagedTransaction', 'memberGuid', memberGuid)
            // verify required parameter 'transactionGuid' is not null or undefined
            assertParamExists('updateManagedTransaction', 'transactionGuid', transactionGuid)
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('updateManagedTransaction', 'userGuid', userGuid)
            // verify required parameter 'managedTransactionUpdateRequestBody' is not null or undefined
            assertParamExists('updateManagedTransaction', 'managedTransactionUpdateRequestBody', managedTransactionUpdateRequestBody)
            const localVarPath = `/users/{user_guid}/managed_members/{member_guid}/accounts/{account_guid}/transactions/{transaction_guid}`
                .replace(`{${"account_guid"}}`, encodeURIComponent(String(accountGuid)))
                .replace(`{${"member_guid"}}`, encodeURIComponent(String(memberGuid)))
                .replace(`{${"transaction_guid"}}`, encodeURIComponent(String(transactionGuid)))
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(managedTransactionUpdateRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to update a members attributes. Only the credentials, id, and metadata parameters can be updated. To get a list of the required credentials for the member, use the list member credentials endpoint.
         * @summary Update member
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {MemberUpdateRequestBody} memberUpdateRequestBody Member object to be updated (While no single parameter is required, the request body can\&#39;t be empty)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMember: async (memberGuid: string, userGuid: string, memberUpdateRequestBody: MemberUpdateRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberGuid' is not null or undefined
            assertParamExists('updateMember', 'memberGuid', memberGuid)
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('updateMember', 'userGuid', userGuid)
            // verify required parameter 'memberUpdateRequestBody' is not null or undefined
            assertParamExists('updateMember', 'memberUpdateRequestBody', memberUpdateRequestBody)
            const localVarPath = `/users/{user_guid}/members/{member_guid}`
                .replace(`{${"member_guid"}}`, encodeURIComponent(String(memberGuid)))
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(memberUpdateRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to update the name of a specific tag according to its unique GUID.
         * @summary Update tag
         * @param {string} tagGuid The unique id for a &#x60;tag&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {TagUpdateRequestBody} tagUpdateRequestBody Tag object to be updated with required parameter (tag_guid)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTag: async (tagGuid: string, userGuid: string, tagUpdateRequestBody: TagUpdateRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tagGuid' is not null or undefined
            assertParamExists('updateTag', 'tagGuid', tagGuid)
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('updateTag', 'userGuid', userGuid)
            // verify required parameter 'tagUpdateRequestBody' is not null or undefined
            assertParamExists('updateTag', 'tagUpdateRequestBody', tagUpdateRequestBody)
            const localVarPath = `/users/{user_guid}/tags/{tag_guid}`
                .replace(`{${"tag_guid"}}`, encodeURIComponent(String(tagGuid)))
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tagUpdateRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to update a tagging.
         * @summary Update tagging
         * @param {string} taggingGuid The unique id for a &#x60;tagging&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {TaggingUpdateRequestBody} taggingUpdateRequestBody Tagging object to be updated with required parameter (tag_guid)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTagging: async (taggingGuid: string, userGuid: string, taggingUpdateRequestBody: TaggingUpdateRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taggingGuid' is not null or undefined
            assertParamExists('updateTagging', 'taggingGuid', taggingGuid)
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('updateTagging', 'userGuid', userGuid)
            // verify required parameter 'taggingUpdateRequestBody' is not null or undefined
            assertParamExists('updateTagging', 'taggingUpdateRequestBody', taggingUpdateRequestBody)
            const localVarPath = `/users/{user_guid}/taggings/{tagging_guid}`
                .replace(`{${"tagging_guid"}}`, encodeURIComponent(String(taggingGuid)))
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(taggingUpdateRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to update the `description` of a specific transaction according to its unique GUID.
         * @summary Update transaction
         * @param {string} transactionGuid The unique id for a &#x60;transaction&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {TransactionUpdateRequestBody} transactionUpdateRequestBody Transaction object to be updated with a new description
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTransaction: async (transactionGuid: string, userGuid: string, transactionUpdateRequestBody: TransactionUpdateRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'transactionGuid' is not null or undefined
            assertParamExists('updateTransaction', 'transactionGuid', transactionGuid)
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('updateTransaction', 'userGuid', userGuid)
            // verify required parameter 'transactionUpdateRequestBody' is not null or undefined
            assertParamExists('updateTransaction', 'transactionUpdateRequestBody', transactionUpdateRequestBody)
            const localVarPath = `/users/{user_guid}/transactions/{transaction_guid}`
                .replace(`{${"transaction_guid"}}`, encodeURIComponent(String(transactionGuid)))
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(transactionUpdateRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to update the attributes of a specific transaction rule based on its unique GUID. The API will respond with the updated transaction_rule object. Any attributes not provided will be left unchanged.
         * @summary Update transaction_rule
         * @param {string} transactionRuleGuid The unique id for a &#x60;transaction_rule&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {TransactionRuleUpdateRequestBody} transactionRuleUpdateRequestBody TransactionRule object to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTransactionRule: async (transactionRuleGuid: string, userGuid: string, transactionRuleUpdateRequestBody: TransactionRuleUpdateRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'transactionRuleGuid' is not null or undefined
            assertParamExists('updateTransactionRule', 'transactionRuleGuid', transactionRuleGuid)
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('updateTransactionRule', 'userGuid', userGuid)
            // verify required parameter 'transactionRuleUpdateRequestBody' is not null or undefined
            assertParamExists('updateTransactionRule', 'transactionRuleUpdateRequestBody', transactionRuleUpdateRequestBody)
            const localVarPath = `/users/{user_guid}/transaction_rules/{transaction_rule_guid}`
                .replace(`{${"transaction_rule_guid"}}`, encodeURIComponent(String(transactionRuleGuid)))
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(transactionRuleUpdateRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to update the attributes of the specified user.
         * @summary Update user
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {UserUpdateRequestBody} userUpdateRequestBody User object to be updated (None of these parameters are required, but the user object cannot be empty.)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (userGuid: string, userUpdateRequestBody: UserUpdateRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('updateUser', 'userGuid', userGuid)
            // verify required parameter 'userUpdateRequestBody' is not null or undefined
            assertParamExists('updateUser', 'userUpdateRequestBody', userUpdateRequestBody)
            const localVarPath = `/users/{user_guid}`
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userUpdateRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The verify endpoint begins a verification process for a member.
         * @summary Verify member
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyMember: async (memberGuid: string, userGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberGuid' is not null or undefined
            assertParamExists('verifyMember', 'memberGuid', memberGuid)
            // verify required parameter 'userGuid' is not null or undefined
            assertParamExists('verifyMember', 'userGuid', userGuid)
            const localVarPath = `/users/{user_guid}/members/{member_guid}/verify`
                .replace(`{${"member_guid"}}`, encodeURIComponent(String(memberGuid)))
                .replace(`{${"user_guid"}}`, encodeURIComponent(String(userGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MxPlatformApi - functional programming interface
 * @export
 */
export const MxPlatformApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MxPlatformApiAxiosParamCreator(configuration)
    return {
        /**
         * Calling this endpoint initiates an aggregation event for the member. This brings in the latest account and transaction data from the connected institution. If this data has recently been updated, MX may not initiate an aggregation event.
         * @summary Aggregate member
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aggregateMember(memberGuid: string, userGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aggregateMember(memberGuid, userGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint operates much like the aggregate member endpoint except that it gathers only account balance information; it does not gather any transaction data.
         * @summary Check balances
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkBalances(memberGuid: string, userGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkBalances(memberGuid, userGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to create a new custom category for a specific `user`.
         * @summary Create category
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {CategoryCreateRequestBody} categoryCreateRequestBody Custom category object to be created
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCategory(userGuid: string, categoryCreateRequestBody: CategoryCreateRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoryResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCategory(userGuid, categoryCreateRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to create a partner-managed account.
         * @summary Create managed account
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {ManagedAccountCreateRequestBody} managedAccountCreateRequestBody Managed account to be created.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createManagedAccount(memberGuid: string, userGuid: string, managedAccountCreateRequestBody: ManagedAccountCreateRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createManagedAccount(memberGuid, userGuid, managedAccountCreateRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to create a new partner-managed `member`.
         * @summary Create managed member
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {ManagedMemberCreateRequestBody} managedMemberCreateRequestBody Managed member to be created.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createManagedMember(userGuid: string, managedMemberCreateRequestBody: ManagedMemberCreateRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createManagedMember(userGuid, managedMemberCreateRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to create a new partner-managed `transaction`.
         * @summary Create managed transaction
         * @param {string} accountGuid The unique id for an &#x60;account&#x60;.
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {ManagedTransactionCreateRequestBody} managedTransactionCreateRequestBody Managed transaction to be created.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createManagedTransaction(accountGuid: string, memberGuid: string, userGuid: string, managedTransactionCreateRequestBody: ManagedTransactionCreateRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createManagedTransaction(accountGuid, memberGuid, userGuid, managedTransactionCreateRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint allows you to create a new member. Members are created with the required parameters credentials and institution_code, and the optional parameters id and metadata. When creating a member, youll need to include the correct type of credential required by the financial institution and provided by the user. You can find out which credential type is required with the `/institutions/{institution_code}/credentials` endpoint. If successful, the MX Platform API will respond with the newly-created member object. Once you successfully create a member, MX will immediately validate the provided credentials and attempt to aggregate data for accounts and transactions.
         * @summary Create member
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {MemberCreateRequestBody} memberCreateRequestBody Member object to be created with optional parameters (id and metadata) and required parameters (credentials and institution_code)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMember(userGuid: string, memberCreateRequestBody: MemberCreateRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMember(userGuid, memberCreateRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to create a new custom tag.
         * @summary Create tag
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {TagCreateRequestBody} tagCreateRequestBody Tag object to be created with required parameters (tag_guid)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTag(userGuid: string, tagCreateRequestBody: TagCreateRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTag(userGuid, tagCreateRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to create a new association between a tag and a particular transaction, according to their unique GUIDs.
         * @summary Create tagging
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {TaggingCreateRequestBody} taggingCreateRequestBody Tagging object to be created with required parameters (tag_guid and transaction_guid)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTagging(userGuid: string, taggingCreateRequestBody: TaggingCreateRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaggingResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTagging(userGuid, taggingCreateRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to create a new transaction rule. The newly-created `transaction_rule` object will be returned if successful.
         * @summary Create transaction rule
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {TransactionRuleCreateRequestBody} transactionRuleCreateRequestBody TransactionRule object to be created with optional parameters (description) and required parameters (category_guid and match_description)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTransactionRule(userGuid: string, transactionRuleCreateRequestBody: TransactionRuleCreateRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionRuleResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTransactionRule(userGuid, transactionRuleCreateRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to create a new user. The API will respond with the newly-created user object if successful. Disabling a user means that accounts and transactions associated with it will not be updated in the background by MX. It will also restrict access to that user’s data until they are no longer disabled.
         * @summary Create user
         * @param {UserCreateRequestBody} userCreateRequestBody User object to be created. (None of these parameters are required, but the user object cannot be empty)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUser(userCreateRequestBody: UserCreateRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUser(userCreateRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to delete a specific custom category according to its unique GUID. The API will respond with an empty object and a status of `204 No Content`.
         * @summary Delete category
         * @param {string} categoryGuid The unique id for a &#x60;category&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCategory(categoryGuid: string, userGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCategory(categoryGuid, userGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to delete a partner-managed account according to its unique GUID. If successful, the API will respond with a status of `204 No Content`.
         * @summary Delete managed account
         * @param {string} accountGuid The unique id for an &#x60;account&#x60;.
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteManagedAccount(accountGuid: string, memberGuid: string, userGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteManagedAccount(accountGuid, memberGuid, userGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to delete the specified partner-managed `member`. The endpoint will respond with a status of `204 No Content` without a resource.
         * @summary Delete managed member
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteManagedMember(memberGuid: string, userGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteManagedMember(memberGuid, userGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to delete the specified partner-managed `transaction`. The endpoint will respond with a status of `204 No Content` without a resource.
         * @summary Delete managed transaction
         * @param {string} accountGuid The unique id for an &#x60;account&#x60;.
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} transactionGuid The unique id for a &#x60;transaction&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteManagedTransaction(accountGuid: string, memberGuid: string, transactionGuid: string, userGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteManagedTransaction(accountGuid, memberGuid, transactionGuid, userGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Accessing this endpoint will permanently delete a member.
         * @summary Delete member
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMember(memberGuid: string, userGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMember(memberGuid, userGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to permanently delete a specific tag based on its unique GUID. If successful, the API will respond with status of `204 No Content`.
         * @summary Delete tag
         * @param {string} tagGuid The unique id for a &#x60;tag&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTag(tagGuid: string, userGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTag(tagGuid, userGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to delete a tagging according to its unique GUID. If successful, the API will respond with an empty body and a status of 204 NO Content.
         * @summary Delete tagging
         * @param {string} taggingGuid The unique id for a &#x60;tagging&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTagging(taggingGuid: string, userGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTagging(taggingGuid, userGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to permanently delete a transaction rule based on its unique GUID.
         * @summary Delete transaction rule
         * @param {string} transactionRuleGuid The unique id for a &#x60;transaction_rule&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTransactionRule(transactionRuleGuid: string, userGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTransactionRule(transactionRuleGuid, userGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to delete the specified `user`. The response will have a status of `204 No Content` without an object.
         * @summary Delete user
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUser(userGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUser(userGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * (This endpoint is deprecated. Clients should use `/authorization_code`.) Clients use this endpoint to request an authorization_code according to a user, member, and account specified in the request body. Clients then pass this code to processors. Processor access is scoped only to the user/member/account specified in this request. Before requesting an authorization_code, clients must have verified the specified member.
         * @summary (Deprecated) Request an authorization code.
         * @param {PaymentProcessorAuthorizationCodeRequestBody} paymentProcessorAuthorizationCodeRequestBody The scope for the authorization code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deprecatedRequestPaymentProcessorAuthorizationCode(paymentProcessorAuthorizationCodeRequestBody: PaymentProcessorAuthorizationCodeRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentProcessorAuthorizationCodeResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deprecatedRequestPaymentProcessorAuthorizationCode(paymentProcessorAuthorizationCodeRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to download a specified statement PDF.
         * @summary Download statement pdf
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} statementGuid The unique id for a &#x60;statement&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadStatementPDF(memberGuid: string, statementGuid: string, userGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadStatementPDF(memberGuid, statementGuid, userGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to download a PDF version of the specified tax document. The endpoint URL is the base URL appended with the uri of the tax_document.
         * @summary Download a Tax Document PDF
         * @param {string} taxDocumentGuid The unique id for a &#x60;tax_document&#x60;.
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadTaxDocument(taxDocumentGuid: string, memberGuid: string, userGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadTaxDocument(taxDocumentGuid, memberGuid, userGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to categorize, cleanse, and classify transactions. These transactions are not persisted or stored on the MX platform.
         * @summary Enhance transactions
         * @param {EnhanceTransactionsRequestBody} enhanceTransactionsRequestBody Transaction object to be enhanced
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async enhanceTransactions(enhanceTransactionsRequestBody: EnhanceTransactionsRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnhanceTransactionsResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.enhanceTransactions(enhanceTransactionsRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Some institutions allow developers to access an extended transaction history with up to 24 months of data associated with a particular member. The process for fetching and then reading this extended transaction history is much like standard aggregation, and it may trigger multi-factor authentication.
         * @summary Extend history
         * @param {string} memberGuid The unique identifier for a &#x60;member&#x60;.
         * @param {string} userGuid The unique identifier for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async extendHistory(memberGuid: string, userGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.extendHistory(memberGuid, userGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to fetch the statements associated with a particular member.
         * @summary Fetch statements
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchStatements(memberGuid: string, userGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchStatements(memberGuid, userGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to fetch (aggregate) the tax documents associated with the specified member. This request **does not** return the latest tax documents. It just starts the document aggregation process and returns the initial state of the process. You must interact with the newly aggregated data using the other document endpoints in this reference. This request may also trigger multi-factor authentication which requires end-user input and a specific process for answering authentication challenges.
         * @summary Fetch Tax Documents
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchTaxDocuments(memberGuid: string, userGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchTaxDocuments(memberGuid, userGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * The identify endpoint begins an identification process for an already-existing member.
         * @summary Identify member
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identifyMember(memberGuid: string, userGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identifyMember(memberGuid, userGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint returns a list of account numbers associated with the specified `account`.
         * @summary List account numbers by account
         * @param {string} accountGuid The unique id for an &#x60;account&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAccountNumbersByAccount(accountGuid: string, userGuid: string, page?: number, recordsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountNumbersResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAccountNumbersByAccount(accountGuid, userGuid, page, recordsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint returns a list of account numbers associated with the specified `member`.
         * @summary List account numbers by member
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAccountNumbersByMember(memberGuid: string, userGuid: string, page?: number, recordsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountNumbersResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAccountNumbersByMember(memberGuid, userGuid, page, recordsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint returns an array with information about every account associated with a particular member.
         * @summary List account owners by member
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAccountOwnersByMember(memberGuid: string, userGuid: string, page?: number, recordsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountOwnersResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAccountOwnersByMember(memberGuid, userGuid, page, recordsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to list all categories associated with a `user`, including both default and custom categories.
         * @summary List categories
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCategories(userGuid: string, page?: number, recordsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoriesResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCategories(userGuid, page, recordsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to retrieve a list of all the default categories and subcategories offered within the MX Platform API. In other words, each item in the returned list will have its `is_default` field set to `true`. There are currently 119 default categories and subcategories. Both the _list default categories_ and _list default categories by user_ endpoints return the same results. The different routes are provided for convenience.
         * @summary List default categories
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDefaultCategories(page?: number, recordsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoriesResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDefaultCategories(page, recordsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to retrieve a list of all the default categories and subcategories, scoped by user, offered within the MX Platform API. In other words, each item in the returned list will have its `is_default` field set to `true`. There are currently 119 default categories and subcategories. Both the _list default categories_ and _list default categories by user_ endpoints return the same results. The different routes are provided for convenience.
         * @summary List default categories by user
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDefaultCategoriesByUser(userGuid: string, page?: number, recordsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoriesResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDefaultCategoriesByUser(userGuid, page, recordsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint returns a paginated list containing institutions that have been set as the partner’s favorites, sorted by popularity. Please contact MX to set a list of favorites.
         * @summary List favorite institutions
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listFavoriteInstitutions(page?: number, recordsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InstitutionsResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listFavoriteInstitutions(page, recordsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint returns all holdings associated with the specified `user` across all accounts and members.
         * @summary List holdings
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {string} [fromDate] Filter holdings from this date.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {string} [toDate] Filter holdings to this date.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listHoldings(userGuid: string, fromDate?: string, page?: number, recordsPerPage?: number, toDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HoldingsResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listHoldings(userGuid, fromDate, page, recordsPerPage, toDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint returns all holdings associated with the specified `account`.
         * @summary List holdings by account
         * @param {string} accountGuid The unique id for the &#x60;account&#x60;.
         * @param {string} userGuid The unique id for the &#x60;user&#x60;.
         * @param {string} [fromDate] Filter holdings from this date.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {string} [toDate] Filter holdings to this date.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listHoldingsByAccount(accountGuid: string, userGuid: string, fromDate?: string, page?: number, recordsPerPage?: number, toDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HoldingsResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listHoldingsByAccount(accountGuid, userGuid, fromDate, page, recordsPerPage, toDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint returns all holdings associated with the specified `member` across all accounts.
         * @summary List holdings by member
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {string} [fromDate] Filter holdings from this date.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {string} [toDate] Filter holdings to this date.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listHoldingsByMember(memberGuid: string, userGuid: string, fromDate?: string, page?: number, recordsPerPage?: number, toDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HoldingsResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listHoldingsByMember(memberGuid, userGuid, fromDate, page, recordsPerPage, toDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to see which credentials will be needed to create a member for a specific institution.
         * @summary List institution credentials
         * @param {string} institutionCode The institution_code of the institution.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listInstitutionCredentials(institutionCode: string, page?: number, recordsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CredentialsResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listInstitutionCredentials(institutionCode, page, recordsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint returns a list of institutions based on the specified search term or parameter.
         * @summary List institutions
         * @param {string} [name] This will list only institutions in which the appended string appears.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {boolean} [supportsAccountIdentification] Filter only institutions which support account identification.
         * @param {boolean} [supportsAccountStatement] Filter only institutions which support account statements.
         * @param {boolean} [supportsAccountVerification] Filter only institutions which support account verification.
         * @param {boolean} [supportsTransactionHistory] Filter only institutions which support extended transaction history.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listInstitutions(name?: string, page?: number, recordsPerPage?: number, supportsAccountIdentification?: boolean, supportsAccountStatement?: boolean, supportsAccountVerification?: boolean, supportsTransactionHistory?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InstitutionsResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listInstitutions(name, page, recordsPerPage, supportsAccountIdentification, supportsAccountStatement, supportsAccountVerification, supportsTransactionHistory, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to retrieve a list of all the partner-managed accounts associated with the given partner-manage member.
         * @summary List managed accounts
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listManagedAccounts(memberGuid: string, userGuid: string, page?: number, recordsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountsResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listManagedAccounts(memberGuid, userGuid, page, recordsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint returns a list of institutions which can be used to create partner-managed members.
         * @summary List managed institutions
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listManagedInstitutions(page?: number, recordsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InstitutionsResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listManagedInstitutions(page, recordsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint returns a list of all the partner-managed members associated with the specified `user`.
         * @summary List managed members
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listManagedMembers(userGuid: string, page?: number, recordsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MembersResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listManagedMembers(userGuid, page, recordsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint returns a list of all the partner-managed transactions associated with the specified `account`, scoped through a `user` and a `member`.
         * @summary List managed transactions
         * @param {string} accountGuid The unique id for an &#x60;account&#x60;.
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listManagedTransactions(accountGuid: string, memberGuid: string, userGuid: string, page?: number, recordsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionsResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listManagedTransactions(accountGuid, memberGuid, userGuid, page, recordsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint returns a list of all the accounts associated with the specified `member`.
         * @summary List accounts by member
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {boolean} [memberIsManagedByUser] List only accounts whose member is managed by the user.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMemberAccounts(userGuid: string, memberGuid: string, memberIsManagedByUser?: boolean, page?: number, recordsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountsResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMemberAccounts(userGuid, memberGuid, memberIsManagedByUser, page, recordsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint for information on what multi-factor authentication challenges need to be answered in order to aggregate a member. If the aggregation is not challenged, i.e., the member does not have a connection status of `CHALLENGED`, then code `204 No Content` will be returned. If the aggregation has been challenged, i.e., the member does have a connection status of `CHALLENGED`, then code `200 OK` will be returned - along with the corresponding credentials.
         * @summary List member challenges
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMemberChallenges(memberGuid: string, userGuid: string, page?: number, recordsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChallengesResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMemberChallenges(memberGuid, userGuid, page, recordsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint returns an array which contains information on every non-MFA credential associated with a specific member.
         * @summary List member credentials
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMemberCredentials(memberGuid: string, userGuid: string, page?: number, recordsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CredentialsResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMemberCredentials(memberGuid, userGuid, page, recordsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint returns an array which contains information on every member associated with a specific user.
         * @summary List members
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMembers(userGuid: string, page?: number, recordsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MembersResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMembers(userGuid, page, recordsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint returns a paginated list of all the merchants in the MX system.
         * @summary List merchants
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMerchants(page?: number, recordsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MerchantsResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMerchants(page, recordsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to get an array of available statements.
         * @summary List statements by member
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listStatementsByMember(memberGuid: string, userGuid: string, page?: number, recordsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatementsResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listStatementsByMember(memberGuid, userGuid, page, recordsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to retrieve a list of all the taggings associated with a specific user.
         * @summary List taggings
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listTaggings(userGuid: string, page?: number, recordsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaggingsResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listTaggings(userGuid, page, recordsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to list all tags associated with the specified `user`. Each user includes the `Business` tag by default.
         * @summary List tags
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listTags(userGuid: string, page?: number, recordsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagsResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listTags(userGuid, page, recordsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to get a paginated list of tax documents.
         * @summary List Tax Documents
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listTaxDocuments(memberGuid: string, userGuid: string, page?: number, recordsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaxDocumentsResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listTaxDocuments(memberGuid, userGuid, page, recordsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to read the attributes of all existing transaction rules belonging to the user.
         * @summary List transaction rules
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listTransactionRules(userGuid: string, page?: number, recordsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionRulesResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listTransactionRules(userGuid, page, recordsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Requests to this endpoint return a list of transactions associated with the specified `user`, accross all members and accounts associated with that `user`.
         * @summary List transactions
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {string} [fromDate] Filter transactions from this date.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {string} [toDate] Filter transactions to this date.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listTransactions(userGuid: string, fromDate?: string, page?: number, recordsPerPage?: number, toDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionsResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listTransactions(userGuid, fromDate, page, recordsPerPage, toDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint returns a list of the last 90 days of transactions associated with the specified account.
         * @summary List transactions by account
         * @param {string} accountGuid The unique id for an &#x60;account&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {string} [fromDate] Filter transactions from this date.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {string} [toDate] Filter transactions to this date.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listTransactionsByAccount(accountGuid: string, userGuid: string, fromDate?: string, page?: number, recordsPerPage?: number, toDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionsResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listTransactionsByAccount(accountGuid, userGuid, fromDate, page, recordsPerPage, toDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Requests to this endpoint return a list of transactions associated with the specified `member`, accross all accounts associated with that `member`.
         * @summary List transactions by member
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {string} [fromDate] Filter transactions from this date.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {string} [toDate] Filter transactions to this date.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listTransactionsByMember(memberGuid: string, userGuid: string, fromDate?: string, page?: number, recordsPerPage?: number, toDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionsResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listTransactionsByMember(memberGuid, userGuid, fromDate, page, recordsPerPage, toDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to get a list of all transactions associated with a particular tag according to the tag’s unique GUID. In other words, a list of all transactions that have been assigned to a particular tag using the create a tagging endpoint.
         * @summary List transactions by tag
         * @param {string} tagGuid The unique id for a &#x60;tag&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {string} [fromDate] Filter transactions from this date.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {string} [toDate] Filter transactions to this date.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listTransactionsByTag(tagGuid: string, userGuid: string, fromDate?: string, page?: number, recordsPerPage?: number, toDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionsResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listTransactionsByTag(tagGuid, userGuid, fromDate, page, recordsPerPage, toDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint returns a list of all the accounts associated with the specified `user`.
         * @summary List accounts
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {boolean} [memberIsManagedByUser] List only accounts whose member is managed by the user.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listUserAccounts(userGuid: string, memberIsManagedByUser?: boolean, page?: number, recordsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountsResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listUserAccounts(userGuid, memberIsManagedByUser, page, recordsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to list every user you\'ve created in the MX Platform API.
         * @summary List users
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {string} [id] The user &#x60;id&#x60; to search for.
         * @param {string} [email] The user &#x60;email&#x60; to search for.
         * @param {boolean} [isDisabled] Search for users that are diabled.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listUsers(page?: number, recordsPerPage?: number, id?: string, email?: string, isDisabled?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listUsers(page, recordsPerPage, id, email, isDisabled, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint returns the specified `account` resource.
         * @summary Read account
         * @param {string} accountGuid The unique id for an &#x60;account&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readAccount(accountGuid: string, userGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readAccount(accountGuid, userGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint allows you to read the attributes of an `account` resource.
         * @summary Read account by member
         * @param {string} accountGuid The unique id for an &#x60;account&#x60;.
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readAccountByMember(accountGuid: string, memberGuid: string, userGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readAccountByMember(accountGuid, memberGuid, userGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to read the attributes of either a default category or a custom category.
         * @summary Read a custom category
         * @param {string} categoryGuid The unique id for a &#x60;category&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readCategory(categoryGuid: string, userGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoryResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readCategory(categoryGuid, userGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to read the attributes of a default category.
         * @summary Read a default category
         * @param {string} categoryGuid The unique id for a &#x60;category&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readDefaultCategory(categoryGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoryResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readDefaultCategory(categoryGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to read the attributes of a specific `holding`.
         * @summary Read holding
         * @param {string} holdingGuid The unique id for a &#x60;holding&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readHolding(holdingGuid: string, userGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HoldingResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readHolding(holdingGuid, userGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint returns information about the institution specified by `institution_code`.
         * @summary Read institution
         * @param {string} institutionCode The institution_code of the institution.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readInstitution(institutionCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InstitutionResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readInstitution(institutionCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to read the attributes of a partner-managed account according to its unique guid.
         * @summary Read managed account
         * @param {string} accountGuid The unique id for an &#x60;account&#x60;.
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readManagedAccount(accountGuid: string, memberGuid: string, userGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readManagedAccount(accountGuid, memberGuid, userGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint returns the attributes of the specified partner-managed `member`.
         * @summary Read managed member
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readManagedMember(memberGuid: string, userGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readManagedMember(memberGuid, userGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Requests to this endpoint will return the attributes of the specified partner-managed `transaction`.
         * @summary Read managed transaction
         * @param {string} accountGuid The unique id for an &#x60;account&#x60;.
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} transactionGuid The unique id for a &#x60;transaction&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readManagedTransaction(accountGuid: string, memberGuid: string, transactionGuid: string, userGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readManagedTransaction(accountGuid, memberGuid, transactionGuid, userGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to read the attributes of a specific member.
         * @summary Read member
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readMember(memberGuid: string, userGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readMember(memberGuid, userGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint provides the status of the members most recent aggregation event. This is an important step in the aggregation process, and the results returned by this endpoint should determine what you do next in order to successfully aggregate a member. MX has introduced new, more detailed information on the current status of a members connection to a financial institution and the state of its aggregation - the connection_status field. These are intended to replace and expand upon the information provided in the status field, which will soon be deprecated; support for the status field remains for the time being.
         * @summary Read member status
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readMemberStatus(memberGuid: string, userGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberStatusResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readMemberStatus(memberGuid, userGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns information about a particular merchant, such as a logo, name, and website.
         * @summary Read merchant
         * @param {string} merchantGuid The unique id for a &#x60;merchant&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readMerchant(merchantGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MerchantResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readMerchant(merchantGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint returns the specified merchant_location resource.
         * @summary Read merchant location
         * @param {string} merchantLocationGuid The unique id for a &#x60;merchant_location&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readMerchantLocation(merchantLocationGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MerchantLocationResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readMerchantLocation(merchantLocationGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to read a JSON representation of the statement.
         * @summary Read statement by member
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} statementGuid The unique id for a &#x60;statement&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readStatementByMember(memberGuid: string, statementGuid: string, userGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatementResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readStatementByMember(memberGuid, statementGuid, userGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to read the attributes of a particular tag according to its unique GUID.
         * @summary Read tag
         * @param {string} tagGuid The unique id for a &#x60;tag&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readTag(tagGuid: string, userGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readTag(tagGuid, userGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to read the attributes of a `tagging` according to its unique GUID.
         * @summary Read tagging
         * @param {string} taggingGuid The unique id for a &#x60;tagging&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readTagging(taggingGuid: string, userGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaggingResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readTagging(taggingGuid, userGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to read the attributes of the specified tax document.
         * @summary Read a Tax Document
         * @param {string} taxDocumentGuid The unique id for a &#x60;tax_document&#x60;.
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readTaxDocument(taxDocumentGuid: string, memberGuid: string, userGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaxDocumentResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readTaxDocument(taxDocumentGuid, memberGuid, userGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Requests to this endpoint will return the attributes of the specified `transaction`.
         * @summary Read transaction
         * @param {string} transactionGuid The unique id for a &#x60;transaction&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readTransaction(transactionGuid: string, userGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readTransaction(transactionGuid, userGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to read the attributes of an existing transaction rule based on the rule’s unique GUID.
         * @summary Read transaction rule
         * @param {string} transactionRuleGuid The unique id for a &#x60;transaction_rule&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readTransactionRule(transactionRuleGuid: string, userGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionRuleResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readTransactionRule(transactionRuleGuid, userGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to read the attributes of a specific user.
         * @summary Read user
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readUser(userGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readUser(userGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Clients use this endpoint to request an authorization code according to the parameters specified in the scope. Clients then pass this code to processors. Processor access is scoped only to the GUIDs and features specified in this request. Before requesting an authorization code which includes a member in the scope, clients must have verified that member.
         * @summary Request an authorization code.
         * @param {AuthorizationCodeRequestBody} authorizationCodeRequestBody The scope for the authorization code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestAuthorizationCode(authorizationCodeRequestBody: AuthorizationCodeRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthorizationCodeResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestAuthorizationCode(authorizationCodeRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint will return a URL for an embeddable version of MX Connect.
         * @summary Request connect widget url
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {ConnectWidgetRequestBody} connectWidgetRequestBody Optional config options for WebView (is_mobile_webview, current_institution_code, current_member_guid, update_credentials)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestConnectWidgetURL(userGuid: string, connectWidgetRequestBody: ConnectWidgetRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConnectWidgetResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestConnectWidgetURL(userGuid, connectWidgetRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint will generate an `oauth_window_uri` for the specified `member`.
         * @summary Request oauth window uri
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {string} [clientRedirectUrl] A URL that MX will redirect to at the end of OAuth with additional query parameters. Only available with &#x60;referral_source&#x3D;APP&#x60;.
         * @param {string} [referralSource] Must be either &#x60;BROWSER&#x60; or &#x60;APP&#x60; depending on the implementation. Defaults to &#x60;BROWSER&#x60;.
         * @param {boolean} [skipAggregation] Setting this parameter to &#x60;true&#x60; will prevent the member from automatically aggregating after being redirected from the authorization page.
         * @param {string} [uiMessageWebviewUrlScheme] A scheme for routing the user back to the application state they were previously in. Only available with &#x60;referral_source&#x3D;APP&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestOAuthWindowURI(memberGuid: string, userGuid: string, clientRedirectUrl?: string, referralSource?: string, skipAggregation?: boolean, uiMessageWebviewUrlScheme?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OAuthWindowResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestOAuthWindowURI(memberGuid, userGuid, clientRedirectUrl, referralSource, skipAggregation, uiMessageWebviewUrlScheme, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint allows partners to get a URL by passing the `widget_type` in the request body, as well as configuring it in several different ways. In the case of Connect, that means setting the `widget_type` to `connect_widget`. Partners may also pass an optional `Accept-Language` header as well as a number of configuration options. Note that this is a `POST` request.
         * @summary Request widget url
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {WidgetRequestBody} widgetRequestBody The widget url configuration options.
         * @param {string} [acceptLanguage] The desired language of the widget.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestWidgetURL(userGuid: string, widgetRequestBody: WidgetRequestBody, acceptLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WidgetResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestWidgetURL(userGuid, widgetRequestBody, acceptLanguage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint answers the challenges needed when a member has been challenged by multi-factor authentication.
         * @summary Resume aggregation
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {MemberResumeRequestBody} memberResumeRequestBody Member object with MFA challenge answers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resumeAggregation(memberGuid: string, userGuid: string, memberResumeRequestBody: MemberResumeRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resumeAggregation(memberGuid, userGuid, memberResumeRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint allows you to update certain attributes of an `account` resource.
         * @summary Update account by member
         * @param {string} accountGuid The unique id for an &#x60;account&#x60;.
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {AccountUpdateRequestBody} accountUpdateRequestBody Account object to be created with optional parameters (is_hidden)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAccountByMember(accountGuid: string, memberGuid: string, userGuid: string, accountUpdateRequestBody: AccountUpdateRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAccountByMember(accountGuid, memberGuid, userGuid, accountUpdateRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to update the attributes of a custom category according to its unique GUID.
         * @summary Update category
         * @param {string} categoryGuid The unique id for a &#x60;category&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {CategoryUpdateRequestBody} categoryUpdateRequestBody Category object to be updated (While no single parameter is required, the &#x60;category&#x60; object cannot be empty)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCategory(categoryGuid: string, userGuid: string, categoryUpdateRequestBody: CategoryUpdateRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoryResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCategory(categoryGuid, userGuid, categoryUpdateRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to update the attributes of a partner-managed account according to its unique GUID.
         * @summary Update managed account
         * @param {string} accountGuid The unique id for an &#x60;account&#x60;.
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {ManagedAccountUpdateRequestBody} managedAccountUpdateRequestBody Managed account object to be updated (While no single parameter is required, the request body can\&#39;t be empty)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateManagedAccount(accountGuid: string, memberGuid: string, userGuid: string, managedAccountUpdateRequestBody: ManagedAccountUpdateRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateManagedAccount(accountGuid, memberGuid, userGuid, managedAccountUpdateRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to update the attributes of the specified partner_managed `member`.
         * @summary Update managed member
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {ManagedMemberUpdateRequestBody} managedMemberUpdateRequestBody Managed member object to be updated (While no single parameter is required, the request body can\&#39;t be empty)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateManagedMember(memberGuid: string, userGuid: string, managedMemberUpdateRequestBody: ManagedMemberUpdateRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateManagedMember(memberGuid, userGuid, managedMemberUpdateRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to update the attributes of the specified partner_managed `transaction`.
         * @summary Update managed transaction
         * @param {string} accountGuid The unique id for an &#x60;account&#x60;.
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} transactionGuid The unique id for a &#x60;transaction&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {ManagedTransactionUpdateRequestBody} managedTransactionUpdateRequestBody Managed transaction object to be updated (While no single parameter is required, the request body can\&#39;t be empty)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateManagedTransaction(accountGuid: string, memberGuid: string, transactionGuid: string, userGuid: string, managedTransactionUpdateRequestBody: ManagedTransactionUpdateRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateManagedTransaction(accountGuid, memberGuid, transactionGuid, userGuid, managedTransactionUpdateRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to update a members attributes. Only the credentials, id, and metadata parameters can be updated. To get a list of the required credentials for the member, use the list member credentials endpoint.
         * @summary Update member
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {MemberUpdateRequestBody} memberUpdateRequestBody Member object to be updated (While no single parameter is required, the request body can\&#39;t be empty)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMember(memberGuid: string, userGuid: string, memberUpdateRequestBody: MemberUpdateRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMember(memberGuid, userGuid, memberUpdateRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to update the name of a specific tag according to its unique GUID.
         * @summary Update tag
         * @param {string} tagGuid The unique id for a &#x60;tag&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {TagUpdateRequestBody} tagUpdateRequestBody Tag object to be updated with required parameter (tag_guid)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTag(tagGuid: string, userGuid: string, tagUpdateRequestBody: TagUpdateRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTag(tagGuid, userGuid, tagUpdateRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to update a tagging.
         * @summary Update tagging
         * @param {string} taggingGuid The unique id for a &#x60;tagging&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {TaggingUpdateRequestBody} taggingUpdateRequestBody Tagging object to be updated with required parameter (tag_guid)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTagging(taggingGuid: string, userGuid: string, taggingUpdateRequestBody: TaggingUpdateRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaggingResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTagging(taggingGuid, userGuid, taggingUpdateRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to update the `description` of a specific transaction according to its unique GUID.
         * @summary Update transaction
         * @param {string} transactionGuid The unique id for a &#x60;transaction&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {TransactionUpdateRequestBody} transactionUpdateRequestBody Transaction object to be updated with a new description
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTransaction(transactionGuid: string, userGuid: string, transactionUpdateRequestBody: TransactionUpdateRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTransaction(transactionGuid, userGuid, transactionUpdateRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to update the attributes of a specific transaction rule based on its unique GUID. The API will respond with the updated transaction_rule object. Any attributes not provided will be left unchanged.
         * @summary Update transaction_rule
         * @param {string} transactionRuleGuid The unique id for a &#x60;transaction_rule&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {TransactionRuleUpdateRequestBody} transactionRuleUpdateRequestBody TransactionRule object to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTransactionRule(transactionRuleGuid: string, userGuid: string, transactionRuleUpdateRequestBody: TransactionRuleUpdateRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionRuleResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTransactionRule(transactionRuleGuid, userGuid, transactionRuleUpdateRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to update the attributes of the specified user.
         * @summary Update user
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {UserUpdateRequestBody} userUpdateRequestBody User object to be updated (None of these parameters are required, but the user object cannot be empty.)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(userGuid: string, userUpdateRequestBody: UserUpdateRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(userGuid, userUpdateRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * The verify endpoint begins a verification process for a member.
         * @summary Verify member
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyMember(memberGuid: string, userGuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyMember(memberGuid, userGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MxPlatformApi - factory interface
 * @export
 */
export const MxPlatformApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MxPlatformApiFp(configuration)
    return {
        /**
         * Calling this endpoint initiates an aggregation event for the member. This brings in the latest account and transaction data from the connected institution. If this data has recently been updated, MX may not initiate an aggregation event.
         * @summary Aggregate member
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregateMember(memberGuid: string, userGuid: string, options?: any): AxiosPromise<MemberResponseBody> {
            return localVarFp.aggregateMember(memberGuid, userGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint operates much like the aggregate member endpoint except that it gathers only account balance information; it does not gather any transaction data.
         * @summary Check balances
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkBalances(memberGuid: string, userGuid: string, options?: any): AxiosPromise<MemberResponseBody> {
            return localVarFp.checkBalances(memberGuid, userGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to create a new custom category for a specific `user`.
         * @summary Create category
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {CategoryCreateRequestBody} categoryCreateRequestBody Custom category object to be created
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCategory(userGuid: string, categoryCreateRequestBody: CategoryCreateRequestBody, options?: any): AxiosPromise<CategoryResponseBody> {
            return localVarFp.createCategory(userGuid, categoryCreateRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to create a partner-managed account.
         * @summary Create managed account
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {ManagedAccountCreateRequestBody} managedAccountCreateRequestBody Managed account to be created.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createManagedAccount(memberGuid: string, userGuid: string, managedAccountCreateRequestBody: ManagedAccountCreateRequestBody, options?: any): AxiosPromise<AccountResponseBody> {
            return localVarFp.createManagedAccount(memberGuid, userGuid, managedAccountCreateRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to create a new partner-managed `member`.
         * @summary Create managed member
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {ManagedMemberCreateRequestBody} managedMemberCreateRequestBody Managed member to be created.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createManagedMember(userGuid: string, managedMemberCreateRequestBody: ManagedMemberCreateRequestBody, options?: any): AxiosPromise<MemberResponseBody> {
            return localVarFp.createManagedMember(userGuid, managedMemberCreateRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to create a new partner-managed `transaction`.
         * @summary Create managed transaction
         * @param {string} accountGuid The unique id for an &#x60;account&#x60;.
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {ManagedTransactionCreateRequestBody} managedTransactionCreateRequestBody Managed transaction to be created.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createManagedTransaction(accountGuid: string, memberGuid: string, userGuid: string, managedTransactionCreateRequestBody: ManagedTransactionCreateRequestBody, options?: any): AxiosPromise<TransactionResponseBody> {
            return localVarFp.createManagedTransaction(accountGuid, memberGuid, userGuid, managedTransactionCreateRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint allows you to create a new member. Members are created with the required parameters credentials and institution_code, and the optional parameters id and metadata. When creating a member, youll need to include the correct type of credential required by the financial institution and provided by the user. You can find out which credential type is required with the `/institutions/{institution_code}/credentials` endpoint. If successful, the MX Platform API will respond with the newly-created member object. Once you successfully create a member, MX will immediately validate the provided credentials and attempt to aggregate data for accounts and transactions.
         * @summary Create member
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {MemberCreateRequestBody} memberCreateRequestBody Member object to be created with optional parameters (id and metadata) and required parameters (credentials and institution_code)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMember(userGuid: string, memberCreateRequestBody: MemberCreateRequestBody, options?: any): AxiosPromise<MemberResponseBody> {
            return localVarFp.createMember(userGuid, memberCreateRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to create a new custom tag.
         * @summary Create tag
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {TagCreateRequestBody} tagCreateRequestBody Tag object to be created with required parameters (tag_guid)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTag(userGuid: string, tagCreateRequestBody: TagCreateRequestBody, options?: any): AxiosPromise<TagResponseBody> {
            return localVarFp.createTag(userGuid, tagCreateRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to create a new association between a tag and a particular transaction, according to their unique GUIDs.
         * @summary Create tagging
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {TaggingCreateRequestBody} taggingCreateRequestBody Tagging object to be created with required parameters (tag_guid and transaction_guid)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTagging(userGuid: string, taggingCreateRequestBody: TaggingCreateRequestBody, options?: any): AxiosPromise<TaggingResponseBody> {
            return localVarFp.createTagging(userGuid, taggingCreateRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to create a new transaction rule. The newly-created `transaction_rule` object will be returned if successful.
         * @summary Create transaction rule
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {TransactionRuleCreateRequestBody} transactionRuleCreateRequestBody TransactionRule object to be created with optional parameters (description) and required parameters (category_guid and match_description)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTransactionRule(userGuid: string, transactionRuleCreateRequestBody: TransactionRuleCreateRequestBody, options?: any): AxiosPromise<TransactionRuleResponseBody> {
            return localVarFp.createTransactionRule(userGuid, transactionRuleCreateRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to create a new user. The API will respond with the newly-created user object if successful. Disabling a user means that accounts and transactions associated with it will not be updated in the background by MX. It will also restrict access to that user’s data until they are no longer disabled.
         * @summary Create user
         * @param {UserCreateRequestBody} userCreateRequestBody User object to be created. (None of these parameters are required, but the user object cannot be empty)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(userCreateRequestBody: UserCreateRequestBody, options?: any): AxiosPromise<UserResponseBody> {
            return localVarFp.createUser(userCreateRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to delete a specific custom category according to its unique GUID. The API will respond with an empty object and a status of `204 No Content`.
         * @summary Delete category
         * @param {string} categoryGuid The unique id for a &#x60;category&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCategory(categoryGuid: string, userGuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCategory(categoryGuid, userGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to delete a partner-managed account according to its unique GUID. If successful, the API will respond with a status of `204 No Content`.
         * @summary Delete managed account
         * @param {string} accountGuid The unique id for an &#x60;account&#x60;.
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManagedAccount(accountGuid: string, memberGuid: string, userGuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteManagedAccount(accountGuid, memberGuid, userGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to delete the specified partner-managed `member`. The endpoint will respond with a status of `204 No Content` without a resource.
         * @summary Delete managed member
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManagedMember(memberGuid: string, userGuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteManagedMember(memberGuid, userGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to delete the specified partner-managed `transaction`. The endpoint will respond with a status of `204 No Content` without a resource.
         * @summary Delete managed transaction
         * @param {string} accountGuid The unique id for an &#x60;account&#x60;.
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} transactionGuid The unique id for a &#x60;transaction&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManagedTransaction(accountGuid: string, memberGuid: string, transactionGuid: string, userGuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteManagedTransaction(accountGuid, memberGuid, transactionGuid, userGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * Accessing this endpoint will permanently delete a member.
         * @summary Delete member
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMember(memberGuid: string, userGuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteMember(memberGuid, userGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to permanently delete a specific tag based on its unique GUID. If successful, the API will respond with status of `204 No Content`.
         * @summary Delete tag
         * @param {string} tagGuid The unique id for a &#x60;tag&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTag(tagGuid: string, userGuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTag(tagGuid, userGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to delete a tagging according to its unique GUID. If successful, the API will respond with an empty body and a status of 204 NO Content.
         * @summary Delete tagging
         * @param {string} taggingGuid The unique id for a &#x60;tagging&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTagging(taggingGuid: string, userGuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTagging(taggingGuid, userGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to permanently delete a transaction rule based on its unique GUID.
         * @summary Delete transaction rule
         * @param {string} transactionRuleGuid The unique id for a &#x60;transaction_rule&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTransactionRule(transactionRuleGuid: string, userGuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTransactionRule(transactionRuleGuid, userGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to delete the specified `user`. The response will have a status of `204 No Content` without an object.
         * @summary Delete user
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(userGuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUser(userGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * (This endpoint is deprecated. Clients should use `/authorization_code`.) Clients use this endpoint to request an authorization_code according to a user, member, and account specified in the request body. Clients then pass this code to processors. Processor access is scoped only to the user/member/account specified in this request. Before requesting an authorization_code, clients must have verified the specified member.
         * @summary (Deprecated) Request an authorization code.
         * @param {PaymentProcessorAuthorizationCodeRequestBody} paymentProcessorAuthorizationCodeRequestBody The scope for the authorization code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deprecatedRequestPaymentProcessorAuthorizationCode(paymentProcessorAuthorizationCodeRequestBody: PaymentProcessorAuthorizationCodeRequestBody, options?: any): AxiosPromise<PaymentProcessorAuthorizationCodeResponseBody> {
            return localVarFp.deprecatedRequestPaymentProcessorAuthorizationCode(paymentProcessorAuthorizationCodeRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to download a specified statement PDF.
         * @summary Download statement pdf
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} statementGuid The unique id for a &#x60;statement&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadStatementPDF(memberGuid: string, statementGuid: string, userGuid: string, options?: any): AxiosPromise<any> {
            return localVarFp.downloadStatementPDF(memberGuid, statementGuid, userGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to download a PDF version of the specified tax document. The endpoint URL is the base URL appended with the uri of the tax_document.
         * @summary Download a Tax Document PDF
         * @param {string} taxDocumentGuid The unique id for a &#x60;tax_document&#x60;.
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadTaxDocument(taxDocumentGuid: string, memberGuid: string, userGuid: string, options?: any): AxiosPromise<any> {
            return localVarFp.downloadTaxDocument(taxDocumentGuid, memberGuid, userGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to categorize, cleanse, and classify transactions. These transactions are not persisted or stored on the MX platform.
         * @summary Enhance transactions
         * @param {EnhanceTransactionsRequestBody} enhanceTransactionsRequestBody Transaction object to be enhanced
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enhanceTransactions(enhanceTransactionsRequestBody: EnhanceTransactionsRequestBody, options?: any): AxiosPromise<EnhanceTransactionsResponseBody> {
            return localVarFp.enhanceTransactions(enhanceTransactionsRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Some institutions allow developers to access an extended transaction history with up to 24 months of data associated with a particular member. The process for fetching and then reading this extended transaction history is much like standard aggregation, and it may trigger multi-factor authentication.
         * @summary Extend history
         * @param {string} memberGuid The unique identifier for a &#x60;member&#x60;.
         * @param {string} userGuid The unique identifier for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        extendHistory(memberGuid: string, userGuid: string, options?: any): AxiosPromise<MemberResponseBody> {
            return localVarFp.extendHistory(memberGuid, userGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to fetch the statements associated with a particular member.
         * @summary Fetch statements
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchStatements(memberGuid: string, userGuid: string, options?: any): AxiosPromise<MemberResponseBody> {
            return localVarFp.fetchStatements(memberGuid, userGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to fetch (aggregate) the tax documents associated with the specified member. This request **does not** return the latest tax documents. It just starts the document aggregation process and returns the initial state of the process. You must interact with the newly aggregated data using the other document endpoints in this reference. This request may also trigger multi-factor authentication which requires end-user input and a specific process for answering authentication challenges.
         * @summary Fetch Tax Documents
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchTaxDocuments(memberGuid: string, userGuid: string, options?: any): AxiosPromise<MemberResponseBody> {
            return localVarFp.fetchTaxDocuments(memberGuid, userGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * The identify endpoint begins an identification process for an already-existing member.
         * @summary Identify member
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identifyMember(memberGuid: string, userGuid: string, options?: any): AxiosPromise<MemberResponseBody> {
            return localVarFp.identifyMember(memberGuid, userGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint returns a list of account numbers associated with the specified `account`.
         * @summary List account numbers by account
         * @param {string} accountGuid The unique id for an &#x60;account&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAccountNumbersByAccount(accountGuid: string, userGuid: string, page?: number, recordsPerPage?: number, options?: any): AxiosPromise<AccountNumbersResponseBody> {
            return localVarFp.listAccountNumbersByAccount(accountGuid, userGuid, page, recordsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint returns a list of account numbers associated with the specified `member`.
         * @summary List account numbers by member
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAccountNumbersByMember(memberGuid: string, userGuid: string, page?: number, recordsPerPage?: number, options?: any): AxiosPromise<AccountNumbersResponseBody> {
            return localVarFp.listAccountNumbersByMember(memberGuid, userGuid, page, recordsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint returns an array with information about every account associated with a particular member.
         * @summary List account owners by member
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAccountOwnersByMember(memberGuid: string, userGuid: string, page?: number, recordsPerPage?: number, options?: any): AxiosPromise<AccountOwnersResponseBody> {
            return localVarFp.listAccountOwnersByMember(memberGuid, userGuid, page, recordsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to list all categories associated with a `user`, including both default and custom categories.
         * @summary List categories
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCategories(userGuid: string, page?: number, recordsPerPage?: number, options?: any): AxiosPromise<CategoriesResponseBody> {
            return localVarFp.listCategories(userGuid, page, recordsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to retrieve a list of all the default categories and subcategories offered within the MX Platform API. In other words, each item in the returned list will have its `is_default` field set to `true`. There are currently 119 default categories and subcategories. Both the _list default categories_ and _list default categories by user_ endpoints return the same results. The different routes are provided for convenience.
         * @summary List default categories
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDefaultCategories(page?: number, recordsPerPage?: number, options?: any): AxiosPromise<CategoriesResponseBody> {
            return localVarFp.listDefaultCategories(page, recordsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to retrieve a list of all the default categories and subcategories, scoped by user, offered within the MX Platform API. In other words, each item in the returned list will have its `is_default` field set to `true`. There are currently 119 default categories and subcategories. Both the _list default categories_ and _list default categories by user_ endpoints return the same results. The different routes are provided for convenience.
         * @summary List default categories by user
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDefaultCategoriesByUser(userGuid: string, page?: number, recordsPerPage?: number, options?: any): AxiosPromise<CategoriesResponseBody> {
            return localVarFp.listDefaultCategoriesByUser(userGuid, page, recordsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint returns a paginated list containing institutions that have been set as the partner’s favorites, sorted by popularity. Please contact MX to set a list of favorites.
         * @summary List favorite institutions
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFavoriteInstitutions(page?: number, recordsPerPage?: number, options?: any): AxiosPromise<InstitutionsResponseBody> {
            return localVarFp.listFavoriteInstitutions(page, recordsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint returns all holdings associated with the specified `user` across all accounts and members.
         * @summary List holdings
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {string} [fromDate] Filter holdings from this date.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {string} [toDate] Filter holdings to this date.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listHoldings(userGuid: string, fromDate?: string, page?: number, recordsPerPage?: number, toDate?: string, options?: any): AxiosPromise<HoldingsResponseBody> {
            return localVarFp.listHoldings(userGuid, fromDate, page, recordsPerPage, toDate, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint returns all holdings associated with the specified `account`.
         * @summary List holdings by account
         * @param {string} accountGuid The unique id for the &#x60;account&#x60;.
         * @param {string} userGuid The unique id for the &#x60;user&#x60;.
         * @param {string} [fromDate] Filter holdings from this date.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {string} [toDate] Filter holdings to this date.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listHoldingsByAccount(accountGuid: string, userGuid: string, fromDate?: string, page?: number, recordsPerPage?: number, toDate?: string, options?: any): AxiosPromise<HoldingsResponseBody> {
            return localVarFp.listHoldingsByAccount(accountGuid, userGuid, fromDate, page, recordsPerPage, toDate, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint returns all holdings associated with the specified `member` across all accounts.
         * @summary List holdings by member
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {string} [fromDate] Filter holdings from this date.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {string} [toDate] Filter holdings to this date.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listHoldingsByMember(memberGuid: string, userGuid: string, fromDate?: string, page?: number, recordsPerPage?: number, toDate?: string, options?: any): AxiosPromise<HoldingsResponseBody> {
            return localVarFp.listHoldingsByMember(memberGuid, userGuid, fromDate, page, recordsPerPage, toDate, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to see which credentials will be needed to create a member for a specific institution.
         * @summary List institution credentials
         * @param {string} institutionCode The institution_code of the institution.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInstitutionCredentials(institutionCode: string, page?: number, recordsPerPage?: number, options?: any): AxiosPromise<CredentialsResponseBody> {
            return localVarFp.listInstitutionCredentials(institutionCode, page, recordsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint returns a list of institutions based on the specified search term or parameter.
         * @summary List institutions
         * @param {string} [name] This will list only institutions in which the appended string appears.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {boolean} [supportsAccountIdentification] Filter only institutions which support account identification.
         * @param {boolean} [supportsAccountStatement] Filter only institutions which support account statements.
         * @param {boolean} [supportsAccountVerification] Filter only institutions which support account verification.
         * @param {boolean} [supportsTransactionHistory] Filter only institutions which support extended transaction history.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInstitutions(name?: string, page?: number, recordsPerPage?: number, supportsAccountIdentification?: boolean, supportsAccountStatement?: boolean, supportsAccountVerification?: boolean, supportsTransactionHistory?: boolean, options?: any): AxiosPromise<InstitutionsResponseBody> {
            return localVarFp.listInstitutions(name, page, recordsPerPage, supportsAccountIdentification, supportsAccountStatement, supportsAccountVerification, supportsTransactionHistory, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to retrieve a list of all the partner-managed accounts associated with the given partner-manage member.
         * @summary List managed accounts
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listManagedAccounts(memberGuid: string, userGuid: string, page?: number, recordsPerPage?: number, options?: any): AxiosPromise<AccountsResponseBody> {
            return localVarFp.listManagedAccounts(memberGuid, userGuid, page, recordsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint returns a list of institutions which can be used to create partner-managed members.
         * @summary List managed institutions
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listManagedInstitutions(page?: number, recordsPerPage?: number, options?: any): AxiosPromise<InstitutionsResponseBody> {
            return localVarFp.listManagedInstitutions(page, recordsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint returns a list of all the partner-managed members associated with the specified `user`.
         * @summary List managed members
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listManagedMembers(userGuid: string, page?: number, recordsPerPage?: number, options?: any): AxiosPromise<MembersResponseBody> {
            return localVarFp.listManagedMembers(userGuid, page, recordsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint returns a list of all the partner-managed transactions associated with the specified `account`, scoped through a `user` and a `member`.
         * @summary List managed transactions
         * @param {string} accountGuid The unique id for an &#x60;account&#x60;.
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listManagedTransactions(accountGuid: string, memberGuid: string, userGuid: string, page?: number, recordsPerPage?: number, options?: any): AxiosPromise<TransactionsResponseBody> {
            return localVarFp.listManagedTransactions(accountGuid, memberGuid, userGuid, page, recordsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint returns a list of all the accounts associated with the specified `member`.
         * @summary List accounts by member
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {boolean} [memberIsManagedByUser] List only accounts whose member is managed by the user.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMemberAccounts(userGuid: string, memberGuid: string, memberIsManagedByUser?: boolean, page?: number, recordsPerPage?: number, options?: any): AxiosPromise<AccountsResponseBody> {
            return localVarFp.listMemberAccounts(userGuid, memberGuid, memberIsManagedByUser, page, recordsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint for information on what multi-factor authentication challenges need to be answered in order to aggregate a member. If the aggregation is not challenged, i.e., the member does not have a connection status of `CHALLENGED`, then code `204 No Content` will be returned. If the aggregation has been challenged, i.e., the member does have a connection status of `CHALLENGED`, then code `200 OK` will be returned - along with the corresponding credentials.
         * @summary List member challenges
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMemberChallenges(memberGuid: string, userGuid: string, page?: number, recordsPerPage?: number, options?: any): AxiosPromise<ChallengesResponseBody> {
            return localVarFp.listMemberChallenges(memberGuid, userGuid, page, recordsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint returns an array which contains information on every non-MFA credential associated with a specific member.
         * @summary List member credentials
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMemberCredentials(memberGuid: string, userGuid: string, page?: number, recordsPerPage?: number, options?: any): AxiosPromise<CredentialsResponseBody> {
            return localVarFp.listMemberCredentials(memberGuid, userGuid, page, recordsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint returns an array which contains information on every member associated with a specific user.
         * @summary List members
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMembers(userGuid: string, page?: number, recordsPerPage?: number, options?: any): AxiosPromise<MembersResponseBody> {
            return localVarFp.listMembers(userGuid, page, recordsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint returns a paginated list of all the merchants in the MX system.
         * @summary List merchants
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMerchants(page?: number, recordsPerPage?: number, options?: any): AxiosPromise<MerchantsResponseBody> {
            return localVarFp.listMerchants(page, recordsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to get an array of available statements.
         * @summary List statements by member
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStatementsByMember(memberGuid: string, userGuid: string, page?: number, recordsPerPage?: number, options?: any): AxiosPromise<StatementsResponseBody> {
            return localVarFp.listStatementsByMember(memberGuid, userGuid, page, recordsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to retrieve a list of all the taggings associated with a specific user.
         * @summary List taggings
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTaggings(userGuid: string, page?: number, recordsPerPage?: number, options?: any): AxiosPromise<TaggingsResponseBody> {
            return localVarFp.listTaggings(userGuid, page, recordsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to list all tags associated with the specified `user`. Each user includes the `Business` tag by default.
         * @summary List tags
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTags(userGuid: string, page?: number, recordsPerPage?: number, options?: any): AxiosPromise<TagsResponseBody> {
            return localVarFp.listTags(userGuid, page, recordsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to get a paginated list of tax documents.
         * @summary List Tax Documents
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTaxDocuments(memberGuid: string, userGuid: string, page?: number, recordsPerPage?: number, options?: any): AxiosPromise<TaxDocumentsResponseBody> {
            return localVarFp.listTaxDocuments(memberGuid, userGuid, page, recordsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to read the attributes of all existing transaction rules belonging to the user.
         * @summary List transaction rules
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTransactionRules(userGuid: string, page?: number, recordsPerPage?: number, options?: any): AxiosPromise<TransactionRulesResponseBody> {
            return localVarFp.listTransactionRules(userGuid, page, recordsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Requests to this endpoint return a list of transactions associated with the specified `user`, accross all members and accounts associated with that `user`.
         * @summary List transactions
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {string} [fromDate] Filter transactions from this date.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {string} [toDate] Filter transactions to this date.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTransactions(userGuid: string, fromDate?: string, page?: number, recordsPerPage?: number, toDate?: string, options?: any): AxiosPromise<TransactionsResponseBody> {
            return localVarFp.listTransactions(userGuid, fromDate, page, recordsPerPage, toDate, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint returns a list of the last 90 days of transactions associated with the specified account.
         * @summary List transactions by account
         * @param {string} accountGuid The unique id for an &#x60;account&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {string} [fromDate] Filter transactions from this date.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {string} [toDate] Filter transactions to this date.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTransactionsByAccount(accountGuid: string, userGuid: string, fromDate?: string, page?: number, recordsPerPage?: number, toDate?: string, options?: any): AxiosPromise<TransactionsResponseBody> {
            return localVarFp.listTransactionsByAccount(accountGuid, userGuid, fromDate, page, recordsPerPage, toDate, options).then((request) => request(axios, basePath));
        },
        /**
         * Requests to this endpoint return a list of transactions associated with the specified `member`, accross all accounts associated with that `member`.
         * @summary List transactions by member
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {string} [fromDate] Filter transactions from this date.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {string} [toDate] Filter transactions to this date.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTransactionsByMember(memberGuid: string, userGuid: string, fromDate?: string, page?: number, recordsPerPage?: number, toDate?: string, options?: any): AxiosPromise<TransactionsResponseBody> {
            return localVarFp.listTransactionsByMember(memberGuid, userGuid, fromDate, page, recordsPerPage, toDate, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to get a list of all transactions associated with a particular tag according to the tag’s unique GUID. In other words, a list of all transactions that have been assigned to a particular tag using the create a tagging endpoint.
         * @summary List transactions by tag
         * @param {string} tagGuid The unique id for a &#x60;tag&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {string} [fromDate] Filter transactions from this date.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {string} [toDate] Filter transactions to this date.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTransactionsByTag(tagGuid: string, userGuid: string, fromDate?: string, page?: number, recordsPerPage?: number, toDate?: string, options?: any): AxiosPromise<TransactionsResponseBody> {
            return localVarFp.listTransactionsByTag(tagGuid, userGuid, fromDate, page, recordsPerPage, toDate, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint returns a list of all the accounts associated with the specified `user`.
         * @summary List accounts
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {boolean} [memberIsManagedByUser] List only accounts whose member is managed by the user.
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserAccounts(userGuid: string, memberIsManagedByUser?: boolean, page?: number, recordsPerPage?: number, options?: any): AxiosPromise<AccountsResponseBody> {
            return localVarFp.listUserAccounts(userGuid, memberIsManagedByUser, page, recordsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to list every user you\'ve created in the MX Platform API.
         * @summary List users
         * @param {number} [page] Specify current page.
         * @param {number} [recordsPerPage] Specify records per page.
         * @param {string} [id] The user &#x60;id&#x60; to search for.
         * @param {string} [email] The user &#x60;email&#x60; to search for.
         * @param {boolean} [isDisabled] Search for users that are diabled.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsers(page?: number, recordsPerPage?: number, id?: string, email?: string, isDisabled?: boolean, options?: any): AxiosPromise<UsersResponseBody> {
            return localVarFp.listUsers(page, recordsPerPage, id, email, isDisabled, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint returns the specified `account` resource.
         * @summary Read account
         * @param {string} accountGuid The unique id for an &#x60;account&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAccount(accountGuid: string, userGuid: string, options?: any): AxiosPromise<AccountResponseBody> {
            return localVarFp.readAccount(accountGuid, userGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint allows you to read the attributes of an `account` resource.
         * @summary Read account by member
         * @param {string} accountGuid The unique id for an &#x60;account&#x60;.
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readAccountByMember(accountGuid: string, memberGuid: string, userGuid: string, options?: any): AxiosPromise<AccountResponseBody> {
            return localVarFp.readAccountByMember(accountGuid, memberGuid, userGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to read the attributes of either a default category or a custom category.
         * @summary Read a custom category
         * @param {string} categoryGuid The unique id for a &#x60;category&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCategory(categoryGuid: string, userGuid: string, options?: any): AxiosPromise<CategoryResponseBody> {
            return localVarFp.readCategory(categoryGuid, userGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to read the attributes of a default category.
         * @summary Read a default category
         * @param {string} categoryGuid The unique id for a &#x60;category&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDefaultCategory(categoryGuid: string, options?: any): AxiosPromise<CategoryResponseBody> {
            return localVarFp.readDefaultCategory(categoryGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to read the attributes of a specific `holding`.
         * @summary Read holding
         * @param {string} holdingGuid The unique id for a &#x60;holding&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readHolding(holdingGuid: string, userGuid: string, options?: any): AxiosPromise<HoldingResponseBody> {
            return localVarFp.readHolding(holdingGuid, userGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint returns information about the institution specified by `institution_code`.
         * @summary Read institution
         * @param {string} institutionCode The institution_code of the institution.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readInstitution(institutionCode: string, options?: any): AxiosPromise<InstitutionResponseBody> {
            return localVarFp.readInstitution(institutionCode, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to read the attributes of a partner-managed account according to its unique guid.
         * @summary Read managed account
         * @param {string} accountGuid The unique id for an &#x60;account&#x60;.
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readManagedAccount(accountGuid: string, memberGuid: string, userGuid: string, options?: any): AxiosPromise<AccountResponseBody> {
            return localVarFp.readManagedAccount(accountGuid, memberGuid, userGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint returns the attributes of the specified partner-managed `member`.
         * @summary Read managed member
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readManagedMember(memberGuid: string, userGuid: string, options?: any): AxiosPromise<MemberResponseBody> {
            return localVarFp.readManagedMember(memberGuid, userGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * Requests to this endpoint will return the attributes of the specified partner-managed `transaction`.
         * @summary Read managed transaction
         * @param {string} accountGuid The unique id for an &#x60;account&#x60;.
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} transactionGuid The unique id for a &#x60;transaction&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readManagedTransaction(accountGuid: string, memberGuid: string, transactionGuid: string, userGuid: string, options?: any): AxiosPromise<TransactionResponseBody> {
            return localVarFp.readManagedTransaction(accountGuid, memberGuid, transactionGuid, userGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to read the attributes of a specific member.
         * @summary Read member
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readMember(memberGuid: string, userGuid: string, options?: any): AxiosPromise<MemberResponseBody> {
            return localVarFp.readMember(memberGuid, userGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint provides the status of the members most recent aggregation event. This is an important step in the aggregation process, and the results returned by this endpoint should determine what you do next in order to successfully aggregate a member. MX has introduced new, more detailed information on the current status of a members connection to a financial institution and the state of its aggregation - the connection_status field. These are intended to replace and expand upon the information provided in the status field, which will soon be deprecated; support for the status field remains for the time being.
         * @summary Read member status
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readMemberStatus(memberGuid: string, userGuid: string, options?: any): AxiosPromise<MemberStatusResponseBody> {
            return localVarFp.readMemberStatus(memberGuid, userGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns information about a particular merchant, such as a logo, name, and website.
         * @summary Read merchant
         * @param {string} merchantGuid The unique id for a &#x60;merchant&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readMerchant(merchantGuid: string, options?: any): AxiosPromise<MerchantResponseBody> {
            return localVarFp.readMerchant(merchantGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint returns the specified merchant_location resource.
         * @summary Read merchant location
         * @param {string} merchantLocationGuid The unique id for a &#x60;merchant_location&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readMerchantLocation(merchantLocationGuid: string, options?: any): AxiosPromise<MerchantLocationResponseBody> {
            return localVarFp.readMerchantLocation(merchantLocationGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to read a JSON representation of the statement.
         * @summary Read statement by member
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} statementGuid The unique id for a &#x60;statement&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readStatementByMember(memberGuid: string, statementGuid: string, userGuid: string, options?: any): AxiosPromise<StatementResponseBody> {
            return localVarFp.readStatementByMember(memberGuid, statementGuid, userGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to read the attributes of a particular tag according to its unique GUID.
         * @summary Read tag
         * @param {string} tagGuid The unique id for a &#x60;tag&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readTag(tagGuid: string, userGuid: string, options?: any): AxiosPromise<TagResponseBody> {
            return localVarFp.readTag(tagGuid, userGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to read the attributes of a `tagging` according to its unique GUID.
         * @summary Read tagging
         * @param {string} taggingGuid The unique id for a &#x60;tagging&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readTagging(taggingGuid: string, userGuid: string, options?: any): AxiosPromise<TaggingResponseBody> {
            return localVarFp.readTagging(taggingGuid, userGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to read the attributes of the specified tax document.
         * @summary Read a Tax Document
         * @param {string} taxDocumentGuid The unique id for a &#x60;tax_document&#x60;.
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readTaxDocument(taxDocumentGuid: string, memberGuid: string, userGuid: string, options?: any): AxiosPromise<TaxDocumentResponseBody> {
            return localVarFp.readTaxDocument(taxDocumentGuid, memberGuid, userGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * Requests to this endpoint will return the attributes of the specified `transaction`.
         * @summary Read transaction
         * @param {string} transactionGuid The unique id for a &#x60;transaction&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readTransaction(transactionGuid: string, userGuid: string, options?: any): AxiosPromise<TransactionResponseBody> {
            return localVarFp.readTransaction(transactionGuid, userGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to read the attributes of an existing transaction rule based on the rule’s unique GUID.
         * @summary Read transaction rule
         * @param {string} transactionRuleGuid The unique id for a &#x60;transaction_rule&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readTransactionRule(transactionRuleGuid: string, userGuid: string, options?: any): AxiosPromise<TransactionRuleResponseBody> {
            return localVarFp.readTransactionRule(transactionRuleGuid, userGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to read the attributes of a specific user.
         * @summary Read user
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUser(userGuid: string, options?: any): AxiosPromise<UserResponseBody> {
            return localVarFp.readUser(userGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * Clients use this endpoint to request an authorization code according to the parameters specified in the scope. Clients then pass this code to processors. Processor access is scoped only to the GUIDs and features specified in this request. Before requesting an authorization code which includes a member in the scope, clients must have verified that member.
         * @summary Request an authorization code.
         * @param {AuthorizationCodeRequestBody} authorizationCodeRequestBody The scope for the authorization code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestAuthorizationCode(authorizationCodeRequestBody: AuthorizationCodeRequestBody, options?: any): AxiosPromise<AuthorizationCodeResponseBody> {
            return localVarFp.requestAuthorizationCode(authorizationCodeRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint will return a URL for an embeddable version of MX Connect.
         * @summary Request connect widget url
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {ConnectWidgetRequestBody} connectWidgetRequestBody Optional config options for WebView (is_mobile_webview, current_institution_code, current_member_guid, update_credentials)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestConnectWidgetURL(userGuid: string, connectWidgetRequestBody: ConnectWidgetRequestBody, options?: any): AxiosPromise<ConnectWidgetResponseBody> {
            return localVarFp.requestConnectWidgetURL(userGuid, connectWidgetRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint will generate an `oauth_window_uri` for the specified `member`.
         * @summary Request oauth window uri
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {string} [clientRedirectUrl] A URL that MX will redirect to at the end of OAuth with additional query parameters. Only available with &#x60;referral_source&#x3D;APP&#x60;.
         * @param {string} [referralSource] Must be either &#x60;BROWSER&#x60; or &#x60;APP&#x60; depending on the implementation. Defaults to &#x60;BROWSER&#x60;.
         * @param {boolean} [skipAggregation] Setting this parameter to &#x60;true&#x60; will prevent the member from automatically aggregating after being redirected from the authorization page.
         * @param {string} [uiMessageWebviewUrlScheme] A scheme for routing the user back to the application state they were previously in. Only available with &#x60;referral_source&#x3D;APP&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestOAuthWindowURI(memberGuid: string, userGuid: string, clientRedirectUrl?: string, referralSource?: string, skipAggregation?: boolean, uiMessageWebviewUrlScheme?: string, options?: any): AxiosPromise<OAuthWindowResponseBody> {
            return localVarFp.requestOAuthWindowURI(memberGuid, userGuid, clientRedirectUrl, referralSource, skipAggregation, uiMessageWebviewUrlScheme, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint allows partners to get a URL by passing the `widget_type` in the request body, as well as configuring it in several different ways. In the case of Connect, that means setting the `widget_type` to `connect_widget`. Partners may also pass an optional `Accept-Language` header as well as a number of configuration options. Note that this is a `POST` request.
         * @summary Request widget url
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {WidgetRequestBody} widgetRequestBody The widget url configuration options.
         * @param {string} [acceptLanguage] The desired language of the widget.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestWidgetURL(userGuid: string, widgetRequestBody: WidgetRequestBody, acceptLanguage?: string, options?: any): AxiosPromise<WidgetResponseBody> {
            return localVarFp.requestWidgetURL(userGuid, widgetRequestBody, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint answers the challenges needed when a member has been challenged by multi-factor authentication.
         * @summary Resume aggregation
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {MemberResumeRequestBody} memberResumeRequestBody Member object with MFA challenge answers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resumeAggregation(memberGuid: string, userGuid: string, memberResumeRequestBody: MemberResumeRequestBody, options?: any): AxiosPromise<MemberResponseBody> {
            return localVarFp.resumeAggregation(memberGuid, userGuid, memberResumeRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint allows you to update certain attributes of an `account` resource.
         * @summary Update account by member
         * @param {string} accountGuid The unique id for an &#x60;account&#x60;.
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {AccountUpdateRequestBody} accountUpdateRequestBody Account object to be created with optional parameters (is_hidden)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountByMember(accountGuid: string, memberGuid: string, userGuid: string, accountUpdateRequestBody: AccountUpdateRequestBody, options?: any): AxiosPromise<AccountResponseBody> {
            return localVarFp.updateAccountByMember(accountGuid, memberGuid, userGuid, accountUpdateRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to update the attributes of a custom category according to its unique GUID.
         * @summary Update category
         * @param {string} categoryGuid The unique id for a &#x60;category&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {CategoryUpdateRequestBody} categoryUpdateRequestBody Category object to be updated (While no single parameter is required, the &#x60;category&#x60; object cannot be empty)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCategory(categoryGuid: string, userGuid: string, categoryUpdateRequestBody: CategoryUpdateRequestBody, options?: any): AxiosPromise<CategoryResponseBody> {
            return localVarFp.updateCategory(categoryGuid, userGuid, categoryUpdateRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to update the attributes of a partner-managed account according to its unique GUID.
         * @summary Update managed account
         * @param {string} accountGuid The unique id for an &#x60;account&#x60;.
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {ManagedAccountUpdateRequestBody} managedAccountUpdateRequestBody Managed account object to be updated (While no single parameter is required, the request body can\&#39;t be empty)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateManagedAccount(accountGuid: string, memberGuid: string, userGuid: string, managedAccountUpdateRequestBody: ManagedAccountUpdateRequestBody, options?: any): AxiosPromise<AccountResponseBody> {
            return localVarFp.updateManagedAccount(accountGuid, memberGuid, userGuid, managedAccountUpdateRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to update the attributes of the specified partner_managed `member`.
         * @summary Update managed member
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {ManagedMemberUpdateRequestBody} managedMemberUpdateRequestBody Managed member object to be updated (While no single parameter is required, the request body can\&#39;t be empty)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateManagedMember(memberGuid: string, userGuid: string, managedMemberUpdateRequestBody: ManagedMemberUpdateRequestBody, options?: any): AxiosPromise<MemberResponseBody> {
            return localVarFp.updateManagedMember(memberGuid, userGuid, managedMemberUpdateRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to update the attributes of the specified partner_managed `transaction`.
         * @summary Update managed transaction
         * @param {string} accountGuid The unique id for an &#x60;account&#x60;.
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} transactionGuid The unique id for a &#x60;transaction&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {ManagedTransactionUpdateRequestBody} managedTransactionUpdateRequestBody Managed transaction object to be updated (While no single parameter is required, the request body can\&#39;t be empty)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateManagedTransaction(accountGuid: string, memberGuid: string, transactionGuid: string, userGuid: string, managedTransactionUpdateRequestBody: ManagedTransactionUpdateRequestBody, options?: any): AxiosPromise<TransactionResponseBody> {
            return localVarFp.updateManagedTransaction(accountGuid, memberGuid, transactionGuid, userGuid, managedTransactionUpdateRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to update a members attributes. Only the credentials, id, and metadata parameters can be updated. To get a list of the required credentials for the member, use the list member credentials endpoint.
         * @summary Update member
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {MemberUpdateRequestBody} memberUpdateRequestBody Member object to be updated (While no single parameter is required, the request body can\&#39;t be empty)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMember(memberGuid: string, userGuid: string, memberUpdateRequestBody: MemberUpdateRequestBody, options?: any): AxiosPromise<MemberResponseBody> {
            return localVarFp.updateMember(memberGuid, userGuid, memberUpdateRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to update the name of a specific tag according to its unique GUID.
         * @summary Update tag
         * @param {string} tagGuid The unique id for a &#x60;tag&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {TagUpdateRequestBody} tagUpdateRequestBody Tag object to be updated with required parameter (tag_guid)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTag(tagGuid: string, userGuid: string, tagUpdateRequestBody: TagUpdateRequestBody, options?: any): AxiosPromise<TagResponseBody> {
            return localVarFp.updateTag(tagGuid, userGuid, tagUpdateRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to update a tagging.
         * @summary Update tagging
         * @param {string} taggingGuid The unique id for a &#x60;tagging&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {TaggingUpdateRequestBody} taggingUpdateRequestBody Tagging object to be updated with required parameter (tag_guid)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTagging(taggingGuid: string, userGuid: string, taggingUpdateRequestBody: TaggingUpdateRequestBody, options?: any): AxiosPromise<TaggingResponseBody> {
            return localVarFp.updateTagging(taggingGuid, userGuid, taggingUpdateRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to update the `description` of a specific transaction according to its unique GUID.
         * @summary Update transaction
         * @param {string} transactionGuid The unique id for a &#x60;transaction&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {TransactionUpdateRequestBody} transactionUpdateRequestBody Transaction object to be updated with a new description
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTransaction(transactionGuid: string, userGuid: string, transactionUpdateRequestBody: TransactionUpdateRequestBody, options?: any): AxiosPromise<TransactionResponseBody> {
            return localVarFp.updateTransaction(transactionGuid, userGuid, transactionUpdateRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to update the attributes of a specific transaction rule based on its unique GUID. The API will respond with the updated transaction_rule object. Any attributes not provided will be left unchanged.
         * @summary Update transaction_rule
         * @param {string} transactionRuleGuid The unique id for a &#x60;transaction_rule&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {TransactionRuleUpdateRequestBody} transactionRuleUpdateRequestBody TransactionRule object to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTransactionRule(transactionRuleGuid: string, userGuid: string, transactionRuleUpdateRequestBody: TransactionRuleUpdateRequestBody, options?: any): AxiosPromise<TransactionRuleResponseBody> {
            return localVarFp.updateTransactionRule(transactionRuleGuid, userGuid, transactionRuleUpdateRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to update the attributes of the specified user.
         * @summary Update user
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {UserUpdateRequestBody} userUpdateRequestBody User object to be updated (None of these parameters are required, but the user object cannot be empty.)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(userGuid: string, userUpdateRequestBody: UserUpdateRequestBody, options?: any): AxiosPromise<UserResponseBody> {
            return localVarFp.updateUser(userGuid, userUpdateRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * The verify endpoint begins a verification process for a member.
         * @summary Verify member
         * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
         * @param {string} userGuid The unique id for a &#x60;user&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyMember(memberGuid: string, userGuid: string, options?: any): AxiosPromise<MemberResponseBody> {
            return localVarFp.verifyMember(memberGuid, userGuid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MxPlatformApi - object-oriented interface
 * @export
 * @class MxPlatformApi
 * @extends {BaseAPI}
 */
export class MxPlatformApi extends BaseAPI {
    /**
     * Calling this endpoint initiates an aggregation event for the member. This brings in the latest account and transaction data from the connected institution. If this data has recently been updated, MX may not initiate an aggregation event.
     * @summary Aggregate member
     * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public aggregateMember(memberGuid: string, userGuid: string, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).aggregateMember(memberGuid, userGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint operates much like the aggregate member endpoint except that it gathers only account balance information; it does not gather any transaction data.
     * @summary Check balances
     * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public checkBalances(memberGuid: string, userGuid: string, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).checkBalances(memberGuid, userGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to create a new custom category for a specific `user`.
     * @summary Create category
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {CategoryCreateRequestBody} categoryCreateRequestBody Custom category object to be created
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public createCategory(userGuid: string, categoryCreateRequestBody: CategoryCreateRequestBody, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).createCategory(userGuid, categoryCreateRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to create a partner-managed account.
     * @summary Create managed account
     * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {ManagedAccountCreateRequestBody} managedAccountCreateRequestBody Managed account to be created.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public createManagedAccount(memberGuid: string, userGuid: string, managedAccountCreateRequestBody: ManagedAccountCreateRequestBody, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).createManagedAccount(memberGuid, userGuid, managedAccountCreateRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to create a new partner-managed `member`.
     * @summary Create managed member
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {ManagedMemberCreateRequestBody} managedMemberCreateRequestBody Managed member to be created.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public createManagedMember(userGuid: string, managedMemberCreateRequestBody: ManagedMemberCreateRequestBody, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).createManagedMember(userGuid, managedMemberCreateRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to create a new partner-managed `transaction`.
     * @summary Create managed transaction
     * @param {string} accountGuid The unique id for an &#x60;account&#x60;.
     * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {ManagedTransactionCreateRequestBody} managedTransactionCreateRequestBody Managed transaction to be created.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public createManagedTransaction(accountGuid: string, memberGuid: string, userGuid: string, managedTransactionCreateRequestBody: ManagedTransactionCreateRequestBody, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).createManagedTransaction(accountGuid, memberGuid, userGuid, managedTransactionCreateRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint allows you to create a new member. Members are created with the required parameters credentials and institution_code, and the optional parameters id and metadata. When creating a member, youll need to include the correct type of credential required by the financial institution and provided by the user. You can find out which credential type is required with the `/institutions/{institution_code}/credentials` endpoint. If successful, the MX Platform API will respond with the newly-created member object. Once you successfully create a member, MX will immediately validate the provided credentials and attempt to aggregate data for accounts and transactions.
     * @summary Create member
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {MemberCreateRequestBody} memberCreateRequestBody Member object to be created with optional parameters (id and metadata) and required parameters (credentials and institution_code)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public createMember(userGuid: string, memberCreateRequestBody: MemberCreateRequestBody, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).createMember(userGuid, memberCreateRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to create a new custom tag.
     * @summary Create tag
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {TagCreateRequestBody} tagCreateRequestBody Tag object to be created with required parameters (tag_guid)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public createTag(userGuid: string, tagCreateRequestBody: TagCreateRequestBody, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).createTag(userGuid, tagCreateRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to create a new association between a tag and a particular transaction, according to their unique GUIDs.
     * @summary Create tagging
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {TaggingCreateRequestBody} taggingCreateRequestBody Tagging object to be created with required parameters (tag_guid and transaction_guid)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public createTagging(userGuid: string, taggingCreateRequestBody: TaggingCreateRequestBody, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).createTagging(userGuid, taggingCreateRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to create a new transaction rule. The newly-created `transaction_rule` object will be returned if successful.
     * @summary Create transaction rule
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {TransactionRuleCreateRequestBody} transactionRuleCreateRequestBody TransactionRule object to be created with optional parameters (description) and required parameters (category_guid and match_description)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public createTransactionRule(userGuid: string, transactionRuleCreateRequestBody: TransactionRuleCreateRequestBody, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).createTransactionRule(userGuid, transactionRuleCreateRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to create a new user. The API will respond with the newly-created user object if successful. Disabling a user means that accounts and transactions associated with it will not be updated in the background by MX. It will also restrict access to that user’s data until they are no longer disabled.
     * @summary Create user
     * @param {UserCreateRequestBody} userCreateRequestBody User object to be created. (None of these parameters are required, but the user object cannot be empty)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public createUser(userCreateRequestBody: UserCreateRequestBody, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).createUser(userCreateRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to delete a specific custom category according to its unique GUID. The API will respond with an empty object and a status of `204 No Content`.
     * @summary Delete category
     * @param {string} categoryGuid The unique id for a &#x60;category&#x60;.
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public deleteCategory(categoryGuid: string, userGuid: string, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).deleteCategory(categoryGuid, userGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to delete a partner-managed account according to its unique GUID. If successful, the API will respond with a status of `204 No Content`.
     * @summary Delete managed account
     * @param {string} accountGuid The unique id for an &#x60;account&#x60;.
     * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public deleteManagedAccount(accountGuid: string, memberGuid: string, userGuid: string, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).deleteManagedAccount(accountGuid, memberGuid, userGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to delete the specified partner-managed `member`. The endpoint will respond with a status of `204 No Content` without a resource.
     * @summary Delete managed member
     * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public deleteManagedMember(memberGuid: string, userGuid: string, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).deleteManagedMember(memberGuid, userGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to delete the specified partner-managed `transaction`. The endpoint will respond with a status of `204 No Content` without a resource.
     * @summary Delete managed transaction
     * @param {string} accountGuid The unique id for an &#x60;account&#x60;.
     * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
     * @param {string} transactionGuid The unique id for a &#x60;transaction&#x60;.
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public deleteManagedTransaction(accountGuid: string, memberGuid: string, transactionGuid: string, userGuid: string, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).deleteManagedTransaction(accountGuid, memberGuid, transactionGuid, userGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Accessing this endpoint will permanently delete a member.
     * @summary Delete member
     * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public deleteMember(memberGuid: string, userGuid: string, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).deleteMember(memberGuid, userGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to permanently delete a specific tag based on its unique GUID. If successful, the API will respond with status of `204 No Content`.
     * @summary Delete tag
     * @param {string} tagGuid The unique id for a &#x60;tag&#x60;.
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public deleteTag(tagGuid: string, userGuid: string, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).deleteTag(tagGuid, userGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to delete a tagging according to its unique GUID. If successful, the API will respond with an empty body and a status of 204 NO Content.
     * @summary Delete tagging
     * @param {string} taggingGuid The unique id for a &#x60;tagging&#x60;.
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public deleteTagging(taggingGuid: string, userGuid: string, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).deleteTagging(taggingGuid, userGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to permanently delete a transaction rule based on its unique GUID.
     * @summary Delete transaction rule
     * @param {string} transactionRuleGuid The unique id for a &#x60;transaction_rule&#x60;.
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public deleteTransactionRule(transactionRuleGuid: string, userGuid: string, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).deleteTransactionRule(transactionRuleGuid, userGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to delete the specified `user`. The response will have a status of `204 No Content` without an object.
     * @summary Delete user
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public deleteUser(userGuid: string, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).deleteUser(userGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * (This endpoint is deprecated. Clients should use `/authorization_code`.) Clients use this endpoint to request an authorization_code according to a user, member, and account specified in the request body. Clients then pass this code to processors. Processor access is scoped only to the user/member/account specified in this request. Before requesting an authorization_code, clients must have verified the specified member.
     * @summary (Deprecated) Request an authorization code.
     * @param {PaymentProcessorAuthorizationCodeRequestBody} paymentProcessorAuthorizationCodeRequestBody The scope for the authorization code.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public deprecatedRequestPaymentProcessorAuthorizationCode(paymentProcessorAuthorizationCodeRequestBody: PaymentProcessorAuthorizationCodeRequestBody, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).deprecatedRequestPaymentProcessorAuthorizationCode(paymentProcessorAuthorizationCodeRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to download a specified statement PDF.
     * @summary Download statement pdf
     * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
     * @param {string} statementGuid The unique id for a &#x60;statement&#x60;.
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public downloadStatementPDF(memberGuid: string, statementGuid: string, userGuid: string, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).downloadStatementPDF(memberGuid, statementGuid, userGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to download a PDF version of the specified tax document. The endpoint URL is the base URL appended with the uri of the tax_document.
     * @summary Download a Tax Document PDF
     * @param {string} taxDocumentGuid The unique id for a &#x60;tax_document&#x60;.
     * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public downloadTaxDocument(taxDocumentGuid: string, memberGuid: string, userGuid: string, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).downloadTaxDocument(taxDocumentGuid, memberGuid, userGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to categorize, cleanse, and classify transactions. These transactions are not persisted or stored on the MX platform.
     * @summary Enhance transactions
     * @param {EnhanceTransactionsRequestBody} enhanceTransactionsRequestBody Transaction object to be enhanced
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public enhanceTransactions(enhanceTransactionsRequestBody: EnhanceTransactionsRequestBody, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).enhanceTransactions(enhanceTransactionsRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Some institutions allow developers to access an extended transaction history with up to 24 months of data associated with a particular member. The process for fetching and then reading this extended transaction history is much like standard aggregation, and it may trigger multi-factor authentication.
     * @summary Extend history
     * @param {string} memberGuid The unique identifier for a &#x60;member&#x60;.
     * @param {string} userGuid The unique identifier for a &#x60;user&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public extendHistory(memberGuid: string, userGuid: string, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).extendHistory(memberGuid, userGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to fetch the statements associated with a particular member.
     * @summary Fetch statements
     * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public fetchStatements(memberGuid: string, userGuid: string, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).fetchStatements(memberGuid, userGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to fetch (aggregate) the tax documents associated with the specified member. This request **does not** return the latest tax documents. It just starts the document aggregation process and returns the initial state of the process. You must interact with the newly aggregated data using the other document endpoints in this reference. This request may also trigger multi-factor authentication which requires end-user input and a specific process for answering authentication challenges.
     * @summary Fetch Tax Documents
     * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public fetchTaxDocuments(memberGuid: string, userGuid: string, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).fetchTaxDocuments(memberGuid, userGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The identify endpoint begins an identification process for an already-existing member.
     * @summary Identify member
     * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public identifyMember(memberGuid: string, userGuid: string, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).identifyMember(memberGuid, userGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint returns a list of account numbers associated with the specified `account`.
     * @summary List account numbers by account
     * @param {string} accountGuid The unique id for an &#x60;account&#x60;.
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {number} [page] Specify current page.
     * @param {number} [recordsPerPage] Specify records per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public listAccountNumbersByAccount(accountGuid: string, userGuid: string, page?: number, recordsPerPage?: number, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).listAccountNumbersByAccount(accountGuid, userGuid, page, recordsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint returns a list of account numbers associated with the specified `member`.
     * @summary List account numbers by member
     * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {number} [page] Specify current page.
     * @param {number} [recordsPerPage] Specify records per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public listAccountNumbersByMember(memberGuid: string, userGuid: string, page?: number, recordsPerPage?: number, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).listAccountNumbersByMember(memberGuid, userGuid, page, recordsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint returns an array with information about every account associated with a particular member.
     * @summary List account owners by member
     * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {number} [page] Specify current page.
     * @param {number} [recordsPerPage] Specify records per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public listAccountOwnersByMember(memberGuid: string, userGuid: string, page?: number, recordsPerPage?: number, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).listAccountOwnersByMember(memberGuid, userGuid, page, recordsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to list all categories associated with a `user`, including both default and custom categories.
     * @summary List categories
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {number} [page] Specify current page.
     * @param {number} [recordsPerPage] Specify records per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public listCategories(userGuid: string, page?: number, recordsPerPage?: number, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).listCategories(userGuid, page, recordsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to retrieve a list of all the default categories and subcategories offered within the MX Platform API. In other words, each item in the returned list will have its `is_default` field set to `true`. There are currently 119 default categories and subcategories. Both the _list default categories_ and _list default categories by user_ endpoints return the same results. The different routes are provided for convenience.
     * @summary List default categories
     * @param {number} [page] Specify current page.
     * @param {number} [recordsPerPage] Specify records per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public listDefaultCategories(page?: number, recordsPerPage?: number, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).listDefaultCategories(page, recordsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to retrieve a list of all the default categories and subcategories, scoped by user, offered within the MX Platform API. In other words, each item in the returned list will have its `is_default` field set to `true`. There are currently 119 default categories and subcategories. Both the _list default categories_ and _list default categories by user_ endpoints return the same results. The different routes are provided for convenience.
     * @summary List default categories by user
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {number} [page] Specify current page.
     * @param {number} [recordsPerPage] Specify records per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public listDefaultCategoriesByUser(userGuid: string, page?: number, recordsPerPage?: number, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).listDefaultCategoriesByUser(userGuid, page, recordsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint returns a paginated list containing institutions that have been set as the partner’s favorites, sorted by popularity. Please contact MX to set a list of favorites.
     * @summary List favorite institutions
     * @param {number} [page] Specify current page.
     * @param {number} [recordsPerPage] Specify records per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public listFavoriteInstitutions(page?: number, recordsPerPage?: number, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).listFavoriteInstitutions(page, recordsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint returns all holdings associated with the specified `user` across all accounts and members.
     * @summary List holdings
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {string} [fromDate] Filter holdings from this date.
     * @param {number} [page] Specify current page.
     * @param {number} [recordsPerPage] Specify records per page.
     * @param {string} [toDate] Filter holdings to this date.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public listHoldings(userGuid: string, fromDate?: string, page?: number, recordsPerPage?: number, toDate?: string, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).listHoldings(userGuid, fromDate, page, recordsPerPage, toDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint returns all holdings associated with the specified `account`.
     * @summary List holdings by account
     * @param {string} accountGuid The unique id for the &#x60;account&#x60;.
     * @param {string} userGuid The unique id for the &#x60;user&#x60;.
     * @param {string} [fromDate] Filter holdings from this date.
     * @param {number} [page] Specify current page.
     * @param {number} [recordsPerPage] Specify records per page.
     * @param {string} [toDate] Filter holdings to this date.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public listHoldingsByAccount(accountGuid: string, userGuid: string, fromDate?: string, page?: number, recordsPerPage?: number, toDate?: string, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).listHoldingsByAccount(accountGuid, userGuid, fromDate, page, recordsPerPage, toDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint returns all holdings associated with the specified `member` across all accounts.
     * @summary List holdings by member
     * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {string} [fromDate] Filter holdings from this date.
     * @param {number} [page] Specify current page.
     * @param {number} [recordsPerPage] Specify records per page.
     * @param {string} [toDate] Filter holdings to this date.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public listHoldingsByMember(memberGuid: string, userGuid: string, fromDate?: string, page?: number, recordsPerPage?: number, toDate?: string, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).listHoldingsByMember(memberGuid, userGuid, fromDate, page, recordsPerPage, toDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to see which credentials will be needed to create a member for a specific institution.
     * @summary List institution credentials
     * @param {string} institutionCode The institution_code of the institution.
     * @param {number} [page] Specify current page.
     * @param {number} [recordsPerPage] Specify records per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public listInstitutionCredentials(institutionCode: string, page?: number, recordsPerPage?: number, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).listInstitutionCredentials(institutionCode, page, recordsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint returns a list of institutions based on the specified search term or parameter.
     * @summary List institutions
     * @param {string} [name] This will list only institutions in which the appended string appears.
     * @param {number} [page] Specify current page.
     * @param {number} [recordsPerPage] Specify records per page.
     * @param {boolean} [supportsAccountIdentification] Filter only institutions which support account identification.
     * @param {boolean} [supportsAccountStatement] Filter only institutions which support account statements.
     * @param {boolean} [supportsAccountVerification] Filter only institutions which support account verification.
     * @param {boolean} [supportsTransactionHistory] Filter only institutions which support extended transaction history.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public listInstitutions(name?: string, page?: number, recordsPerPage?: number, supportsAccountIdentification?: boolean, supportsAccountStatement?: boolean, supportsAccountVerification?: boolean, supportsTransactionHistory?: boolean, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).listInstitutions(name, page, recordsPerPage, supportsAccountIdentification, supportsAccountStatement, supportsAccountVerification, supportsTransactionHistory, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to retrieve a list of all the partner-managed accounts associated with the given partner-manage member.
     * @summary List managed accounts
     * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {number} [page] Specify current page.
     * @param {number} [recordsPerPage] Specify records per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public listManagedAccounts(memberGuid: string, userGuid: string, page?: number, recordsPerPage?: number, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).listManagedAccounts(memberGuid, userGuid, page, recordsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint returns a list of institutions which can be used to create partner-managed members.
     * @summary List managed institutions
     * @param {number} [page] Specify current page.
     * @param {number} [recordsPerPage] Specify records per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public listManagedInstitutions(page?: number, recordsPerPage?: number, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).listManagedInstitutions(page, recordsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint returns a list of all the partner-managed members associated with the specified `user`.
     * @summary List managed members
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {number} [page] Specify current page.
     * @param {number} [recordsPerPage] Specify records per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public listManagedMembers(userGuid: string, page?: number, recordsPerPage?: number, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).listManagedMembers(userGuid, page, recordsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint returns a list of all the partner-managed transactions associated with the specified `account`, scoped through a `user` and a `member`.
     * @summary List managed transactions
     * @param {string} accountGuid The unique id for an &#x60;account&#x60;.
     * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {number} [page] Specify current page.
     * @param {number} [recordsPerPage] Specify records per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public listManagedTransactions(accountGuid: string, memberGuid: string, userGuid: string, page?: number, recordsPerPage?: number, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).listManagedTransactions(accountGuid, memberGuid, userGuid, page, recordsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint returns a list of all the accounts associated with the specified `member`.
     * @summary List accounts by member
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
     * @param {boolean} [memberIsManagedByUser] List only accounts whose member is managed by the user.
     * @param {number} [page] Specify current page.
     * @param {number} [recordsPerPage] Specify records per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public listMemberAccounts(userGuid: string, memberGuid: string, memberIsManagedByUser?: boolean, page?: number, recordsPerPage?: number, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).listMemberAccounts(userGuid, memberGuid, memberIsManagedByUser, page, recordsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint for information on what multi-factor authentication challenges need to be answered in order to aggregate a member. If the aggregation is not challenged, i.e., the member does not have a connection status of `CHALLENGED`, then code `204 No Content` will be returned. If the aggregation has been challenged, i.e., the member does have a connection status of `CHALLENGED`, then code `200 OK` will be returned - along with the corresponding credentials.
     * @summary List member challenges
     * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {number} [page] Specify current page.
     * @param {number} [recordsPerPage] Specify records per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public listMemberChallenges(memberGuid: string, userGuid: string, page?: number, recordsPerPage?: number, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).listMemberChallenges(memberGuid, userGuid, page, recordsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint returns an array which contains information on every non-MFA credential associated with a specific member.
     * @summary List member credentials
     * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {number} [page] Specify current page.
     * @param {number} [recordsPerPage] Specify records per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public listMemberCredentials(memberGuid: string, userGuid: string, page?: number, recordsPerPage?: number, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).listMemberCredentials(memberGuid, userGuid, page, recordsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint returns an array which contains information on every member associated with a specific user.
     * @summary List members
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {number} [page] Specify current page.
     * @param {number} [recordsPerPage] Specify records per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public listMembers(userGuid: string, page?: number, recordsPerPage?: number, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).listMembers(userGuid, page, recordsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint returns a paginated list of all the merchants in the MX system.
     * @summary List merchants
     * @param {number} [page] Specify current page.
     * @param {number} [recordsPerPage] Specify records per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public listMerchants(page?: number, recordsPerPage?: number, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).listMerchants(page, recordsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to get an array of available statements.
     * @summary List statements by member
     * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {number} [page] Specify current page.
     * @param {number} [recordsPerPage] Specify records per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public listStatementsByMember(memberGuid: string, userGuid: string, page?: number, recordsPerPage?: number, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).listStatementsByMember(memberGuid, userGuid, page, recordsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to retrieve a list of all the taggings associated with a specific user.
     * @summary List taggings
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {number} [page] Specify current page.
     * @param {number} [recordsPerPage] Specify records per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public listTaggings(userGuid: string, page?: number, recordsPerPage?: number, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).listTaggings(userGuid, page, recordsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to list all tags associated with the specified `user`. Each user includes the `Business` tag by default.
     * @summary List tags
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {number} [page] Specify current page.
     * @param {number} [recordsPerPage] Specify records per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public listTags(userGuid: string, page?: number, recordsPerPage?: number, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).listTags(userGuid, page, recordsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to get a paginated list of tax documents.
     * @summary List Tax Documents
     * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {number} [page] Specify current page.
     * @param {number} [recordsPerPage] Specify records per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public listTaxDocuments(memberGuid: string, userGuid: string, page?: number, recordsPerPage?: number, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).listTaxDocuments(memberGuid, userGuid, page, recordsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to read the attributes of all existing transaction rules belonging to the user.
     * @summary List transaction rules
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {number} [page] Specify current page.
     * @param {number} [recordsPerPage] Specify records per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public listTransactionRules(userGuid: string, page?: number, recordsPerPage?: number, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).listTransactionRules(userGuid, page, recordsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Requests to this endpoint return a list of transactions associated with the specified `user`, accross all members and accounts associated with that `user`.
     * @summary List transactions
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {string} [fromDate] Filter transactions from this date.
     * @param {number} [page] Specify current page.
     * @param {number} [recordsPerPage] Specify records per page.
     * @param {string} [toDate] Filter transactions to this date.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public listTransactions(userGuid: string, fromDate?: string, page?: number, recordsPerPage?: number, toDate?: string, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).listTransactions(userGuid, fromDate, page, recordsPerPage, toDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint returns a list of the last 90 days of transactions associated with the specified account.
     * @summary List transactions by account
     * @param {string} accountGuid The unique id for an &#x60;account&#x60;.
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {string} [fromDate] Filter transactions from this date.
     * @param {number} [page] Specify current page.
     * @param {number} [recordsPerPage] Specify records per page.
     * @param {string} [toDate] Filter transactions to this date.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public listTransactionsByAccount(accountGuid: string, userGuid: string, fromDate?: string, page?: number, recordsPerPage?: number, toDate?: string, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).listTransactionsByAccount(accountGuid, userGuid, fromDate, page, recordsPerPage, toDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Requests to this endpoint return a list of transactions associated with the specified `member`, accross all accounts associated with that `member`.
     * @summary List transactions by member
     * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {string} [fromDate] Filter transactions from this date.
     * @param {number} [page] Specify current page.
     * @param {number} [recordsPerPage] Specify records per page.
     * @param {string} [toDate] Filter transactions to this date.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public listTransactionsByMember(memberGuid: string, userGuid: string, fromDate?: string, page?: number, recordsPerPage?: number, toDate?: string, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).listTransactionsByMember(memberGuid, userGuid, fromDate, page, recordsPerPage, toDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to get a list of all transactions associated with a particular tag according to the tag’s unique GUID. In other words, a list of all transactions that have been assigned to a particular tag using the create a tagging endpoint.
     * @summary List transactions by tag
     * @param {string} tagGuid The unique id for a &#x60;tag&#x60;.
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {string} [fromDate] Filter transactions from this date.
     * @param {number} [page] Specify current page.
     * @param {number} [recordsPerPage] Specify records per page.
     * @param {string} [toDate] Filter transactions to this date.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public listTransactionsByTag(tagGuid: string, userGuid: string, fromDate?: string, page?: number, recordsPerPage?: number, toDate?: string, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).listTransactionsByTag(tagGuid, userGuid, fromDate, page, recordsPerPage, toDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint returns a list of all the accounts associated with the specified `user`.
     * @summary List accounts
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {boolean} [memberIsManagedByUser] List only accounts whose member is managed by the user.
     * @param {number} [page] Specify current page.
     * @param {number} [recordsPerPage] Specify records per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public listUserAccounts(userGuid: string, memberIsManagedByUser?: boolean, page?: number, recordsPerPage?: number, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).listUserAccounts(userGuid, memberIsManagedByUser, page, recordsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to list every user you\'ve created in the MX Platform API.
     * @summary List users
     * @param {number} [page] Specify current page.
     * @param {number} [recordsPerPage] Specify records per page.
     * @param {string} [id] The user &#x60;id&#x60; to search for.
     * @param {string} [email] The user &#x60;email&#x60; to search for.
     * @param {boolean} [isDisabled] Search for users that are diabled.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public listUsers(page?: number, recordsPerPage?: number, id?: string, email?: string, isDisabled?: boolean, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).listUsers(page, recordsPerPage, id, email, isDisabled, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint returns the specified `account` resource.
     * @summary Read account
     * @param {string} accountGuid The unique id for an &#x60;account&#x60;.
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public readAccount(accountGuid: string, userGuid: string, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).readAccount(accountGuid, userGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint allows you to read the attributes of an `account` resource.
     * @summary Read account by member
     * @param {string} accountGuid The unique id for an &#x60;account&#x60;.
     * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public readAccountByMember(accountGuid: string, memberGuid: string, userGuid: string, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).readAccountByMember(accountGuid, memberGuid, userGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to read the attributes of either a default category or a custom category.
     * @summary Read a custom category
     * @param {string} categoryGuid The unique id for a &#x60;category&#x60;.
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public readCategory(categoryGuid: string, userGuid: string, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).readCategory(categoryGuid, userGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to read the attributes of a default category.
     * @summary Read a default category
     * @param {string} categoryGuid The unique id for a &#x60;category&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public readDefaultCategory(categoryGuid: string, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).readDefaultCategory(categoryGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to read the attributes of a specific `holding`.
     * @summary Read holding
     * @param {string} holdingGuid The unique id for a &#x60;holding&#x60;.
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public readHolding(holdingGuid: string, userGuid: string, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).readHolding(holdingGuid, userGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint returns information about the institution specified by `institution_code`.
     * @summary Read institution
     * @param {string} institutionCode The institution_code of the institution.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public readInstitution(institutionCode: string, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).readInstitution(institutionCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to read the attributes of a partner-managed account according to its unique guid.
     * @summary Read managed account
     * @param {string} accountGuid The unique id for an &#x60;account&#x60;.
     * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public readManagedAccount(accountGuid: string, memberGuid: string, userGuid: string, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).readManagedAccount(accountGuid, memberGuid, userGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint returns the attributes of the specified partner-managed `member`.
     * @summary Read managed member
     * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public readManagedMember(memberGuid: string, userGuid: string, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).readManagedMember(memberGuid, userGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Requests to this endpoint will return the attributes of the specified partner-managed `transaction`.
     * @summary Read managed transaction
     * @param {string} accountGuid The unique id for an &#x60;account&#x60;.
     * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
     * @param {string} transactionGuid The unique id for a &#x60;transaction&#x60;.
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public readManagedTransaction(accountGuid: string, memberGuid: string, transactionGuid: string, userGuid: string, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).readManagedTransaction(accountGuid, memberGuid, transactionGuid, userGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to read the attributes of a specific member.
     * @summary Read member
     * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public readMember(memberGuid: string, userGuid: string, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).readMember(memberGuid, userGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint provides the status of the members most recent aggregation event. This is an important step in the aggregation process, and the results returned by this endpoint should determine what you do next in order to successfully aggregate a member. MX has introduced new, more detailed information on the current status of a members connection to a financial institution and the state of its aggregation - the connection_status field. These are intended to replace and expand upon the information provided in the status field, which will soon be deprecated; support for the status field remains for the time being.
     * @summary Read member status
     * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public readMemberStatus(memberGuid: string, userGuid: string, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).readMemberStatus(memberGuid, userGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns information about a particular merchant, such as a logo, name, and website.
     * @summary Read merchant
     * @param {string} merchantGuid The unique id for a &#x60;merchant&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public readMerchant(merchantGuid: string, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).readMerchant(merchantGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint returns the specified merchant_location resource.
     * @summary Read merchant location
     * @param {string} merchantLocationGuid The unique id for a &#x60;merchant_location&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public readMerchantLocation(merchantLocationGuid: string, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).readMerchantLocation(merchantLocationGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to read a JSON representation of the statement.
     * @summary Read statement by member
     * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
     * @param {string} statementGuid The unique id for a &#x60;statement&#x60;.
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public readStatementByMember(memberGuid: string, statementGuid: string, userGuid: string, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).readStatementByMember(memberGuid, statementGuid, userGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to read the attributes of a particular tag according to its unique GUID.
     * @summary Read tag
     * @param {string} tagGuid The unique id for a &#x60;tag&#x60;.
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public readTag(tagGuid: string, userGuid: string, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).readTag(tagGuid, userGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to read the attributes of a `tagging` according to its unique GUID.
     * @summary Read tagging
     * @param {string} taggingGuid The unique id for a &#x60;tagging&#x60;.
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public readTagging(taggingGuid: string, userGuid: string, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).readTagging(taggingGuid, userGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to read the attributes of the specified tax document.
     * @summary Read a Tax Document
     * @param {string} taxDocumentGuid The unique id for a &#x60;tax_document&#x60;.
     * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public readTaxDocument(taxDocumentGuid: string, memberGuid: string, userGuid: string, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).readTaxDocument(taxDocumentGuid, memberGuid, userGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Requests to this endpoint will return the attributes of the specified `transaction`.
     * @summary Read transaction
     * @param {string} transactionGuid The unique id for a &#x60;transaction&#x60;.
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public readTransaction(transactionGuid: string, userGuid: string, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).readTransaction(transactionGuid, userGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to read the attributes of an existing transaction rule based on the rule’s unique GUID.
     * @summary Read transaction rule
     * @param {string} transactionRuleGuid The unique id for a &#x60;transaction_rule&#x60;.
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public readTransactionRule(transactionRuleGuid: string, userGuid: string, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).readTransactionRule(transactionRuleGuid, userGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to read the attributes of a specific user.
     * @summary Read user
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public readUser(userGuid: string, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).readUser(userGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Clients use this endpoint to request an authorization code according to the parameters specified in the scope. Clients then pass this code to processors. Processor access is scoped only to the GUIDs and features specified in this request. Before requesting an authorization code which includes a member in the scope, clients must have verified that member.
     * @summary Request an authorization code.
     * @param {AuthorizationCodeRequestBody} authorizationCodeRequestBody The scope for the authorization code.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public requestAuthorizationCode(authorizationCodeRequestBody: AuthorizationCodeRequestBody, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).requestAuthorizationCode(authorizationCodeRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint will return a URL for an embeddable version of MX Connect.
     * @summary Request connect widget url
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {ConnectWidgetRequestBody} connectWidgetRequestBody Optional config options for WebView (is_mobile_webview, current_institution_code, current_member_guid, update_credentials)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public requestConnectWidgetURL(userGuid: string, connectWidgetRequestBody: ConnectWidgetRequestBody, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).requestConnectWidgetURL(userGuid, connectWidgetRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint will generate an `oauth_window_uri` for the specified `member`.
     * @summary Request oauth window uri
     * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {string} [clientRedirectUrl] A URL that MX will redirect to at the end of OAuth with additional query parameters. Only available with &#x60;referral_source&#x3D;APP&#x60;.
     * @param {string} [referralSource] Must be either &#x60;BROWSER&#x60; or &#x60;APP&#x60; depending on the implementation. Defaults to &#x60;BROWSER&#x60;.
     * @param {boolean} [skipAggregation] Setting this parameter to &#x60;true&#x60; will prevent the member from automatically aggregating after being redirected from the authorization page.
     * @param {string} [uiMessageWebviewUrlScheme] A scheme for routing the user back to the application state they were previously in. Only available with &#x60;referral_source&#x3D;APP&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public requestOAuthWindowURI(memberGuid: string, userGuid: string, clientRedirectUrl?: string, referralSource?: string, skipAggregation?: boolean, uiMessageWebviewUrlScheme?: string, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).requestOAuthWindowURI(memberGuid, userGuid, clientRedirectUrl, referralSource, skipAggregation, uiMessageWebviewUrlScheme, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint allows partners to get a URL by passing the `widget_type` in the request body, as well as configuring it in several different ways. In the case of Connect, that means setting the `widget_type` to `connect_widget`. Partners may also pass an optional `Accept-Language` header as well as a number of configuration options. Note that this is a `POST` request.
     * @summary Request widget url
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {WidgetRequestBody} widgetRequestBody The widget url configuration options.
     * @param {string} [acceptLanguage] The desired language of the widget.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public requestWidgetURL(userGuid: string, widgetRequestBody: WidgetRequestBody, acceptLanguage?: string, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).requestWidgetURL(userGuid, widgetRequestBody, acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint answers the challenges needed when a member has been challenged by multi-factor authentication.
     * @summary Resume aggregation
     * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {MemberResumeRequestBody} memberResumeRequestBody Member object with MFA challenge answers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public resumeAggregation(memberGuid: string, userGuid: string, memberResumeRequestBody: MemberResumeRequestBody, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).resumeAggregation(memberGuid, userGuid, memberResumeRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint allows you to update certain attributes of an `account` resource.
     * @summary Update account by member
     * @param {string} accountGuid The unique id for an &#x60;account&#x60;.
     * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {AccountUpdateRequestBody} accountUpdateRequestBody Account object to be created with optional parameters (is_hidden)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public updateAccountByMember(accountGuid: string, memberGuid: string, userGuid: string, accountUpdateRequestBody: AccountUpdateRequestBody, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).updateAccountByMember(accountGuid, memberGuid, userGuid, accountUpdateRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to update the attributes of a custom category according to its unique GUID.
     * @summary Update category
     * @param {string} categoryGuid The unique id for a &#x60;category&#x60;.
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {CategoryUpdateRequestBody} categoryUpdateRequestBody Category object to be updated (While no single parameter is required, the &#x60;category&#x60; object cannot be empty)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public updateCategory(categoryGuid: string, userGuid: string, categoryUpdateRequestBody: CategoryUpdateRequestBody, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).updateCategory(categoryGuid, userGuid, categoryUpdateRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to update the attributes of a partner-managed account according to its unique GUID.
     * @summary Update managed account
     * @param {string} accountGuid The unique id for an &#x60;account&#x60;.
     * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {ManagedAccountUpdateRequestBody} managedAccountUpdateRequestBody Managed account object to be updated (While no single parameter is required, the request body can\&#39;t be empty)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public updateManagedAccount(accountGuid: string, memberGuid: string, userGuid: string, managedAccountUpdateRequestBody: ManagedAccountUpdateRequestBody, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).updateManagedAccount(accountGuid, memberGuid, userGuid, managedAccountUpdateRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to update the attributes of the specified partner_managed `member`.
     * @summary Update managed member
     * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {ManagedMemberUpdateRequestBody} managedMemberUpdateRequestBody Managed member object to be updated (While no single parameter is required, the request body can\&#39;t be empty)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public updateManagedMember(memberGuid: string, userGuid: string, managedMemberUpdateRequestBody: ManagedMemberUpdateRequestBody, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).updateManagedMember(memberGuid, userGuid, managedMemberUpdateRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to update the attributes of the specified partner_managed `transaction`.
     * @summary Update managed transaction
     * @param {string} accountGuid The unique id for an &#x60;account&#x60;.
     * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
     * @param {string} transactionGuid The unique id for a &#x60;transaction&#x60;.
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {ManagedTransactionUpdateRequestBody} managedTransactionUpdateRequestBody Managed transaction object to be updated (While no single parameter is required, the request body can\&#39;t be empty)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public updateManagedTransaction(accountGuid: string, memberGuid: string, transactionGuid: string, userGuid: string, managedTransactionUpdateRequestBody: ManagedTransactionUpdateRequestBody, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).updateManagedTransaction(accountGuid, memberGuid, transactionGuid, userGuid, managedTransactionUpdateRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to update a members attributes. Only the credentials, id, and metadata parameters can be updated. To get a list of the required credentials for the member, use the list member credentials endpoint.
     * @summary Update member
     * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {MemberUpdateRequestBody} memberUpdateRequestBody Member object to be updated (While no single parameter is required, the request body can\&#39;t be empty)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public updateMember(memberGuid: string, userGuid: string, memberUpdateRequestBody: MemberUpdateRequestBody, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).updateMember(memberGuid, userGuid, memberUpdateRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to update the name of a specific tag according to its unique GUID.
     * @summary Update tag
     * @param {string} tagGuid The unique id for a &#x60;tag&#x60;.
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {TagUpdateRequestBody} tagUpdateRequestBody Tag object to be updated with required parameter (tag_guid)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public updateTag(tagGuid: string, userGuid: string, tagUpdateRequestBody: TagUpdateRequestBody, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).updateTag(tagGuid, userGuid, tagUpdateRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to update a tagging.
     * @summary Update tagging
     * @param {string} taggingGuid The unique id for a &#x60;tagging&#x60;.
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {TaggingUpdateRequestBody} taggingUpdateRequestBody Tagging object to be updated with required parameter (tag_guid)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public updateTagging(taggingGuid: string, userGuid: string, taggingUpdateRequestBody: TaggingUpdateRequestBody, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).updateTagging(taggingGuid, userGuid, taggingUpdateRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to update the `description` of a specific transaction according to its unique GUID.
     * @summary Update transaction
     * @param {string} transactionGuid The unique id for a &#x60;transaction&#x60;.
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {TransactionUpdateRequestBody} transactionUpdateRequestBody Transaction object to be updated with a new description
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public updateTransaction(transactionGuid: string, userGuid: string, transactionUpdateRequestBody: TransactionUpdateRequestBody, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).updateTransaction(transactionGuid, userGuid, transactionUpdateRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to update the attributes of a specific transaction rule based on its unique GUID. The API will respond with the updated transaction_rule object. Any attributes not provided will be left unchanged.
     * @summary Update transaction_rule
     * @param {string} transactionRuleGuid The unique id for a &#x60;transaction_rule&#x60;.
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {TransactionRuleUpdateRequestBody} transactionRuleUpdateRequestBody TransactionRule object to be updated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public updateTransactionRule(transactionRuleGuid: string, userGuid: string, transactionRuleUpdateRequestBody: TransactionRuleUpdateRequestBody, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).updateTransactionRule(transactionRuleGuid, userGuid, transactionRuleUpdateRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to update the attributes of the specified user.
     * @summary Update user
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {UserUpdateRequestBody} userUpdateRequestBody User object to be updated (None of these parameters are required, but the user object cannot be empty.)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public updateUser(userGuid: string, userUpdateRequestBody: UserUpdateRequestBody, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).updateUser(userGuid, userUpdateRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The verify endpoint begins a verification process for a member.
     * @summary Verify member
     * @param {string} memberGuid The unique id for a &#x60;member&#x60;.
     * @param {string} userGuid The unique id for a &#x60;user&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MxPlatformApi
     */
    public verifyMember(memberGuid: string, userGuid: string, options?: AxiosRequestConfig) {
        return MxPlatformApiFp(this.configuration).verifyMember(memberGuid, userGuid, options).then((request) => request(this.axios, this.basePath));
    }
}


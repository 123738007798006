export const EventCategories = {
  CONNECT: 'Connect',
}

export const EventLabels = {
  DISCLOSURE: 'Disclosure',
  DUPLICATE_CONNECTION: 'Duplicate Connection',
  CONNECT_MEMBER_SUCCESS: 'Connect Member Success',
  CONNECT_MEMBER_ERROR: 'Connect Member Error',
  CONNECT_START: 'Connect Start',
  CREDENTIALS_GO_TO_INSTITUTION: 'Credentials Go To Institution',
  ENTER_CREDENTIALS: 'Enter Credentials',
  ERROR: 'Error',
  INSTITUTION_SEARCH: 'Institution Search',
  LOGIN_ERROR: 'Login Error',
  MANUAL_ACCOUNT: 'Manual Account',
  MFA: 'MFA',
  MICRODEPOSITS: 'Microdeposits',
  REQUEST_AN_INSTITUTION: 'Request an Institution',
  TIMEOUT: 'Timeout',
  DELETE_SURVEY: 'Delete Survey',
  OAUTH: 'OAuth',
  VERIFICATION: 'Verification',
  SINGLE_ACCOUNT_SELECT: 'Single Account Select',
  CONNECT_FEEDBACK: 'Connect Feedback',
}

export const EventActions = {
  ADD_ANOTHER: 'Add Another',
  CANCEL: 'Cancel',
  CHALLENGE: 'Challenge',
  CHALLENGES: 'Challenges',
  CLEAR: 'Clear',
  CLOSE: 'Close',
  CREDENTIAL_RECOVERY: 'Credential Recovery',
  DELETE_MEMBER: 'Delete Member',
  DISCOVERED_SELECT: 'Discovered Select',
  EDIT: 'Edit',
  END: 'End',
  OAUTH_ERROR_RETRY: 'OAuth Error Retry',
  GO_BACK: 'Go Back',
  HIDE_PASSWORD: 'Hide Password',
  IMAGE: 'Image',
  IMAGES: 'Images',
  INITIAL_STEP: 'Initial Step',
  INSTITUTION_SELECTED: 'Institution Selected',
  INVALID_ACCOUNT_NUMBER: 'Invalid Account Number',
  INVALID_ROUTING_NUMBER: 'Invalid Routing Number',
  NEED_HELP: 'Need Help',
  OPTIONS: 'Options',
  PASSWORD_ENTERED: 'Password Entered',
  POPULAR_SELECT: 'Popular Select',
  PRIVACY_POLICY: 'Privacy Policy',
  RESTART: 'Restart',
  RETURN: 'Return',
  SELECTED_ACCOUNT_TYPE: 'Selected Account Type',
  SHOW_PASSWORD: 'Show Password',
  START: 'Start',
  SUBMIT: 'Submit',
  VERIFY_NEW: 'Verify New',
  TRY_REFRESHING: 'Try Refreshing',
  USERNAME_ENTERED: 'Username Entered',
  UPDATE_CREDENTIALS: 'Update Credentials',
  VISIT_BANK: 'Visit Bank',
  SUBMITTED: 'Submitted',
  FEED_BACK: 'Feedback',
}

/**
 * PagviewInfo for Analytics
 * Format: [ Name(STRING), Path(STRING) ]
 * Example: ['Connect Disclosure', '/disclosure']
 */
export const PageviewInfo = {
  CONNECT: ['Connect', '/connect'],
  CONNECT_CONNECTED: ['Connect Successful', '/connected'],
  CONNECT_CONNECTING: ['Connect Connecting', '/connecting'],
  CONNECT_CREATE_CREDENTIALS: [
    'Connect Create Credentials',
    '/credentials/create_credentials_form',
  ],
  CONNECT_DELETE_MEMBER_SUCCESS: ['Connect Delete Member Success', '/delete_member/success'],
  CONNECT_DELETE_MEMBER_SURVEY: ['Connect Delete Member Survey', '/delete_member/survey'],
  CONNECT_DISCLOSURE: ['Connect Disclosure', '/disclosure'],
  CONNECT_DISCLOSURE_PRIVACY_POLICY: ['Connect Disclosure Privacy Policy', '/privacy_policy'],
  CONNECT_EXISTING_MEMBER_WARNING: ['Connect Existing Member Warning', '/existing_member_warning'],
  CONNECT_LOGIN_ERROR: ['Connect Login Error', '/login_error'],
  CONNECT_MANUAL_ACCOUNT: ['Manual Account Connect', '/manual_account_connect'],
  CONNECT_MANUAL_ACCOUNT_FORM: ['Connect Manual Account Form', '/manual_account_form'],
  CONNECT_MANUAL_ACCOUNT_SUCCESS: ['Connect Manual Account Success', '/manual_account_success'],
  CONNECT_MFA: ['Connect MFA', '/mfa'],
  CONNECT_MFA_DEFAULT: ['Connect MFA Default', '/mfa_default'],
  CONNECT_MFA_IMAGE_OPTIONS: ['Connect MFA Image Options', '/mfa_image_options'],
  CONNECT_MFA_OPTIONS: ['Connect MFA Options', '/mfa_options'],
  CONNECT_MFA_SINGLE_ACCOUNT_SELECT: [
    'Connect MFA Single Account Select',
    '/single_account_select',
  ],
  CONNECT_MICRODEPOSITS: ['Connect Microdeposits', '/microdeposits'],
  CONNECT_MICRODEPOSITS_ACCOUNT_INFO: ['Connect Microdeposits Account Info', '/account_info'],
  CONNECT_MICRODEPOSITS_COME_BACK: ['Connect Microdeposists Come Back', '/come_back'],
  CONNECT_MICRODEPOSITS_CONFIRM_DETAILS: [
    'Connect Microdeposits Confirm Details',
    '/confirm_details',
  ],
  CONNECT_MICRODEPOSITS_HOW_IT_WORKS: ['Connect Microdeposits How It Works', '/how_it_works'],
  CONNECT_MICRODEPOSITS_MICRODEPOSIT_ERRORS: [
    'Connect Microdepoits Microdeposit Errors',
    '/microdeposit_errors',
  ],
  CONNECT_MICRODEPOSITS_PERSONAL_INFO_FORM: [
    'Connect Microdeposits Personal Info Form',
    '/personal_info_form',
  ],
  CONNECT_MICRODEPOSITS_ROUTING_NUMBER: ['Connect Microdeposits Routing Number', '/routing_number'],
  CONNECT_MICRODEPOSITS_VERIFIED: ['Connect Microdeposits Verified', '/verified'],
  CONNECT_MICRODEPOSITS_VERIFYING: ['Connect Microdeposits Verifying', '/verifying'],
  CONNECT_MICRODEPOSITS_VERIFY_DEPOSITS: [
    'Connect Microdeposits Verify Deposits',
    '/verify_deposits',
  ],
  CONNECT_OAUTH: ['Connect Oauth Step', '/credentials/oauth_step'],
  CONNECT_OAUTH_ERROR: ['Connect Oauth Error', '/oauth_error'],
  CONNECT_SEARCH: ['Connect Search', '/search'],
  CONNECT_SEARCH_FAILED: ['Connect Search Failed', '/search_failed'],
  CONNECT_SEARCH_NO_RESULTS: ['Connect Search No Results', '/no_results'],
  CONNECT_SEARCH_POPULAR: ['Connect Search Popular', '/popular'],
  CONNECT_SEARCH_TOO_MANY: ['Connect Search Too Many', '/too_many'],
  CONNECT_SEARCHED: ['Connect Searched', '/searched'],
  CONNECT_SURVEY_FEEDBACK: ['Connect Survey Feedback', '/survey/feedback'],
  CONNECT_TIMEOUT: ['Connect Timeout', '/timeout'],
  CONNECT_UPDATE_CREDENTIALS: [
    'Connect Update Credentials',
    '/credentials/update_credentials_form',
  ],
  CONNECT_VERIFY_EXISTING_MEMBER: ['Connect Verify Existing Member', '/verify_existing_member'],
  CONNECTIONS: ['Connections', '/connections'],
}

module.exports = {
  "member": {
    "aggregation_status": 6,
    "connection_status": 0,
    "guid": "MBR-241266ed-803a-4841-8a8a-0f37551e8f56",
    "institution_guid": "INS-***",
    "institution_name": null,
    "institution_url": "https://banksy.kube.sand.internal.mx",
    "instructional_data": {
      "title": null,
      "description": "This is my instructional text. It includes a <a href=\"https://www.mx.com\" id=\"instructional_text\">link</a> that you can click.",
      "steps": []
    }
  }
}
module.exports = {
  "job": {
    "guid": "JOB-500b7919-5f05-40b7-b033-8b5337d176d0",
    "has_processed_account_numbers": false,
    "member_guid": "MBR-ef0ef135-e4fb-495e-a054-0cd50f327f75",
    "user_guid": "USR-810d4e82-750f-4c2a-a194-8c9b2897c629",
    "status": 6,
    "error_message": null,
    "is_authenticated": true,
    "job_type": 0,
    "finished_at": 1680125639,
    "started_at": 1680125637,
    "updated_at": 1680125639
  }
}
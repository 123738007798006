// Please alphabetize <3

export const ENABLE_TWELVE_MONTH_CASH_FLOW = 'ENABLE_TWELVE_MONTH_CASH_FLOW'
export const GOALS_NOTIFICATIONS = 'GOALS_NOTIFICATIONS'
export const GOALS_WIDGET_REDESIGN = 'GOALS_WIDGET_REDESIGN'
export const HAS_MINI_FINSTRONG_POST_MESSAGES = 'HAS_MINI_FINSTRONG_POST_MESSAGES'
export const HELP_SECTION_REDIRECT = 'HELP_SECTION_REDIRECT'
export const HIDE_CONNECT_FUNCTIONALITY = 'HIDE_CONNECT_FUNCTIONALITY'
export const HIDE_FINSTRONG_SCORE = 'HIDE_FINSTRONG_SCORE'
export const INVESTMENTS_REDESIGN = 'INVESTMENTS_REDESIGN'
export const SPENDING_WIDGET_OTHER_TAB = 'SPENDING_WIDGET_OTHER_TAB'
export const SHOW_ABSOLUTE_TRANSACTION_AMOUNT = 'SHOW_ABSOLUTE_TRANSACTION_AMOUNT'
export const SHOW_ACCOUNTS_AGGREGATION_CTA = 'SHOW_ACCOUNTS_AGGREGATION_CTA'
export const SHOW_CARTOGRAPHER_TRANSACTIONS_WIDGET = 'SHOW_CARTOGRAPHER_TRANSACTIONS_WIDGET'
export const SHOW_CASHFLOW_CALENDAR_VIEW_BY_DEFAULT = 'SHOW_CASHFLOW_CALENDAR_VIEW_BY_DEFAULT'
export const SHOW_EXTRA_MARGIN_IN_ACCOUNT_FILTER = 'SHOW_EXTRA_MARGIN_IN_ACCOUNT_FILTER'
export const SHOW_FINSTRONG_CAROUSEL = 'SHOW_FINSTRONG_CAROUSEL'
export const SHOW_FINSTRONG_RECATEGORIZATION = 'SHOW_FINSTRONG_RECATEGORIZATION'
export const SHOW_FINSTRONG_SAVINGS_FIRST = 'SHOW_FINSTRONG_SAVINGS_TOP'
export const SHOW_FINSTRONG_WIDGET = 'SHOW_FINSTRONG_WIDGET'
export const SHOW_NEW_EXISTING_MEMBER_ENHANCEMENT = 'SHOW_NEW_EXISTING_MEMBER_ENHANCEMENT'
export const SHOW_SPENDING_PLAN_WIDGET = 'SHOW_SPENDING_PLAN_WIDGET'
export const SHOW_OLD_SUPPORT_FLOW = 'SHOW_OLD_SUPPORT_FLOW'
export const SHOW_UNSTYLED_GROUPED_ACCOUNTS = 'SHOW_UNSTYLED_GROUPED_ACCOUNTS'
export const TRANSACTION_SUBSCRIPTIONS_DISABLED = 'TRANSACTION_SUBSCRIPTIONS_DISABLED'
export const USE_REGIONS_HELP_WIDGET_COPY = 'USE_REGIONS_HELP_WIDGET_COPY'

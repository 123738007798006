export default {
  LocaleStrings: {
    // widget titles
    accounts: 'Accounts',
    budgets: 'Budgets',
    debts: 'Debts',
    networth: 'Net Worth',
    spending: 'Spending',
    transactions: 'Transactions',
    spending_over_time: 'Trends',
    goals: 'Goals',
    trends: 'Trends',
    notifications_settings: 'Notification Settings',
    investments: 'Investments',
    cashflow: 'Cash Flow',
    finstrong: 'FinStrong',
    STARTING: 'Mise en exécution',
    CHECKING_YOUR_ANSWER: 'Validation de vos réponses',
    VERIFYING_CREDENTIALS: 'Vérification de vos informations de connexion',
    SYNCING_DATA: 'Synchronisation des données',
    ERROR_HAS_OCCURRED: 'Une erreur est survenue',
    FINISHING: 'Bientôt terminé',
    EXISTING_MEMBER: 'Membre existant',
    ALREADY_CONNECTED_AN_ACCOUNT_WITH_: 'Vous avez déjà connecté un compte avec {name}.',
    ALREADY_VERIFIED_AN_ACCOUNT_WITH_: 'Vous avez déjà validé un compte avec {name}.',
    GO_BACK: 'Retour',
    ADD_ANOTHER: 'Ajouter un autre compte',
    ADD_ACCOUNT_WITH_: 'Ajouter un compte avec {name}',
    ADD_AN_ACCOUNT: '+ Ajoutez Un Compte',
    LINK_ACCOUNT: 'Lier un Compte',
    TOO_MANY_RESULTS_TO_DISPLAY: 'Trop de résultats à afficher',
    SEARCH_FOR_A_BANK_CREDIT_CARD_OR_LOAN_PR:
      'Chercher une banque, une carte de crédit, ou un fournisseur de prêt.',
    LOADING: 'CHARGEMENT',
    SHOW: 'Montrer',
    HIDE: 'Cacher',
    CLOSE: 'fermer',
    GO_TO_PROVIDERS_WEBSITE: 'Allez sur le site de cette fournisseur en ligne',
    VERIFY_YOU_CAN_ACCESS_YOUR_ACCOUNT: 'Assurez-vous que vous pouvez accéder à votre compte.',
    TRY_REFRESHING_AGAIN: 'Veuillez actualiser de nouveau',
    TRY_AGAIN_WITH_THE_SAME_CREDENTIALS:
      "Essayez de nouveau avec les mêmes informations d'identification.",
    UPDATE_YOUR_CREDENTIALS_AND_TRY_AGAIN:
      'Mettez à jour vos identifiants de connexion et réessayez.',
    DELETE: 'Effacer',
    CANCEL_CONNECTING_TO_THE_ACCOUNT: 'Annuler la connexion au compte',
    LEAVING_OUR_WEBSITE: 'Vous quittez notre site Web',
    WEB_DISCLAIMER: `Si vous sélectionnez {continue}, vous accédez à un site Web que nous ne possédons ni ne contrôlons. Nous ne sommes pas responsables des produits, services ou informations que vous pouvez y trouver ou y fournir.`,
    TERMS_AND_CONDITIONS: `Vous devez également lire et comprendre comment la politique de confidentialité, le niveau de sécurité de ce site, et les termes et conditions peuvent vous affecter.`,
    CANCEL_TO_STAY: 'Annuler pour rester sur notre site Web',
    CONTINUE_TO_WEBSITE: 'Continuer vers un site externe',
    MANAGE_ACCOUNTS: 'Gérez les comptes',
    CHALLENGE_IMAGE: 'Image d’identification',
    ENTER_YOUR_ANSWER_HERE: 'Entrez votre réponse ici',
    PLEASE_PICK_ONE_OF_THE_FOLLOWING: 'Veuillez choisir une des options suivantes',
    PLEASE_PROVIDE_THE_FOLLOWING_INFORMATION: 'Veuillez fournir les renseignements suivants',
    CONNECT: 'Connecter',
    VERIFY: 'Vérifier',
    CONFIGURATION_MAINTENANCE: 'Maintenance de la configuration',
    THIS_CONNECTION_IS_CURRENTLY_BEING_UPGRADED:
      'Cette connexion est mise à jour. Si cette opération dure plus de 3 jours, veuillez communiquer avec le service d’assistance technique.',
    CONTACT_SUPPORT: "Communiquez avec l'assistance technique",
    MOST_POPULAR: 'Les plus populaires',
    THE_NUMBER_OF_LOGIN_ATTEMPTS_HAS_BEEN_EX: 'Le nombre de tentatives de connexion a été dépassé',
    INCORRECT_LOGIN_ID_OR_PASSWORD: "Le code d'utilisateur ou mot de passe sont incorrects",
    UH_OH_WE_RAN_INTO_A_PROBLEM_AND_OUR_TEAM:
      "Hum...Nous avons rencontré un problème et notre équipe a été informée qu'une correction est nécessaire. Revenez bientôt!",
    NETWORK_ERROR_HAS_OCCURRED: "Une erreur de réseau s'est produite",
    THERE_WAS_AN_UNKNOWN_PROBLEM_WHILE_TRYING:
      "Il y avait un problème inconnu pendant l'établissement de connexion ",
    YOUR_FINANCIAL_INSTITUTION_IS_NOT_RESPONDING:
      "Votre institution financière ne s'est pas répondu. Veuillez réessayer plus tard.",
    SORRY_THERE_IS_A_PROBLEM_WITH_NAME_S_CON:
      'Désolé, il y a un problème avec la connexion de {name}',
    ADD_MANUAL_ACCOUNT: 'Ajoutez un compte manuellement',
    MANUAL_ACCOUNT: 'Compte manuellement',
    MANUAL_DYNAMIC_ACCOUNT: '{accountType} manuelle',
    MANUAL_ACCOUNTS: 'Comptes manuels',
    DELETE_THIS_CONNECTION: 'Supprimer cette connexion',
    NEED_HELP: "Besoin d'aide?",
    CONTACT_US: 'Nous joindre',
    RESOLVE: 'Résoudre',
    VISIT_WEBSITE: 'Consulter le site web',
    CANNOT_FIND_YOUR_BANK: 'Vous ne trouvez pas votre banque',
    REQUEST_SUPPORT: "Demander l'assistance technique",
    INSTITUTION_NAME: "Nom de l'institution financière",
    INSTITUTION_INFO: "L'information sur l'institution financière",
    EMAIL: 'Courriel',
    A_QUESTION: 'Une question',
    QUESTION_DETAIL: 'Le détail de la question',
    YOUR_EMAIL: 'Votre courriel',
    NAME_OF_FINANCIAL_INSTITUTION: "Nom de l'établissement financier",
    COMMENTS_CITY_STATE_WEBSITE_ETC: 'Commentaire (ville, province, site internet, etc.)',
    SUBMIT: 'Soumettre',
    BRIEFLY_DESCRIBE_YOUR_QUESTION: 'Veuillez décrire brièvement votre question',
    FILL_IN_THE_DETAILS_HERE_PLEASE_BE_AS_SPECIFIC:
      "Ajouter les détails ici. Soyez aussi précis que possible. N'incluez pas d'informations confidentielles ou financières telles qu’un mot de passe ou un numéro de compte.",
    THANK_YOU_WE_APPRECIATE_YOUR_QUESTIONS_AND:
      "Merci! Nous apprécions vos questions et commentaires qui nous permettront de devenir meilleurs. Nous répondrons à votre demande dans un délai d'un jour ouvrable.",
    OUR_HOURS_ARE_MONDAY_TO_FRIDAY_AM_PM_MST:
      'Nos heures d’ouverture sont du lundi au vendredi, de 9 h 00 à 17 h 00 HNR. Si vous ne recevez aucune réponse pendant ce délai, consultez votre boîte de pourriels.',
    MEMBER_CONNECT_ERROR:
      "Uh oh! Nous avons rencontré un problème et notre équipe a été informée qu'une correction est nécessaire. Revenez bientôt!",
    REPLY_WILL_BE_SENT_TO_EMAIL: 'Une réponse sera envoyée à {email}',
    CONTINUE: 'Continuer',
    SESSION_EXPIRED: 'Votre session a expiré. Rafraîchissez votre navigateur.',
    SESSION_TIMEOUT_WARNING:
      "Il n'y a eu aucune activité, veuillez cliquer sur Continuer pour éviter les délais",
    SUPPORT: 'Assistance technique',
    // constants/Member.js
    DEFAULT_CLIENT_MESSAGE:
      'Nous sommes temporairement incapables de vous présenter les informations à jour pour ce compte. La situation sera résolue sous peu.',
    NEW_CREDENTIALS_NEEDED: 'Nouvelles références de connexion requises',
    PREVENTED_MESSAGE:
      "Les 3 dernières tentatives de connexion ont échoué. Pour éviter que l'accès au compte {accountName} ne soit bloqué, nous avons suspendu les tentatives de connexion. Veuillez entrer votre nom d'utilisateur et votre mot de passe pour continuer la mise à jour de vos données.",
    DENIED_MESSAGE:
      "Les informations d'identification saisies ne correspondent pas à celles de {accountName}. Veuillez les mettre à jour pour continuer.",
    CHALLENGED_TITLE: 'Informations supplémentaires requises',
    CHALLENGED_MESSAGE:
      'Pour des raisons de sécurité, et afin de vous connecter au compte {accountName}, nous avons besoin d’informations supplémentaires.',
    REJECTED_TITLE: 'Information invalide',
    REJECTED_MESSAGE: 'Information invalide. Veuillez essayer de nouveau.',
    LOCKED_TITLE: 'Le compte est verrouillé',
    LOCKED_MESSAGE:
      'Connectez-vous au site {accountName} et suivez les étapes pour le déverrouiller.',
    IMPEDED_TITLE: 'Action nécessaire',
    IMPEDED_MESSAGE:
      'le site de {accountName} bloque la mise à jour de vos données. de nouvelles conditions d’utilisation ou des messages promotionnels plein écran peuvent causer ces problèmes. connectez-vous au site web pour résoudre le problème.',
    DEGRADED_MESSAGE: 'La connexion est en cours d’actualisation, veuillez réessayer plus tard.',
    CONNECTION_MAINTENANCE: 'Maintenance de la connexion',
    DISCONNECTED_MESSAGE:
      'Il semble que {accountName} éprouve de la difficulté à mettre vos données à jour. Nous travaillons à résoudre ce problème.',
    DISCONTINUED_TITLE: 'La connexion a été interrompue.',
    DISCONTINUED_MESSAGE:
      'La connexion à {accountName} a été interrompue et les données ne sont plus mises à jour.',
    CLOSED_TITLE: 'Compte fermé',
    EXPIRED_TITLE: "Informations d'identification expirées",
    CLOSED_MESSAGE: "Le compte a été marqué comme fermé et n'est plus mis à jour.",
    DELAYED_TITLE: 'Problème de connexion',
    DELAYED_MESSAGE:
      'L’extraction de vos données {accountName} peut prendre quelques minutes. Revenez plus tard pour accéder aux données à jour.',
    FAILED_TITLE: 'La connexion a échoué',
    FAILED_MESSAGE:
      "nous n'avons pas réussi à extraire des données pour vos comptes [nomcompte]. veuillez réessayer plus tard.",
    PAUSED_TITLE: 'Connexion en pause',
    PAUSED_MESSAGE:
      "Les mises à jour pour {accountName} ont été interrompues. Communiquez avec nous si vous croyez qu'elles ont été suspendues par erreur.",
    IMPORTED_MESSAGE:
      "Avant de pouvoir mettre à jour vos données, vous devez entrer votre nom d'utilisateur et votre mot de passe pour {accountName}.",
    EXPIRED_MESSAGE:
      "Le nom d'utilisateur ou le mot de passe de ce compte est invalide. Veuillez mettre à jour vos informations d'identification et essayez de nouveau.",
    IMPAIRED_MESSAGE:
      "Avant de pouvoir mettre à jour vos données, vous devez entrer votre nom d'utilisateur et votre mot de passe pour {accountName}.",
    SELECT_AN_ACCOUNT: 'Choisissez un compte',
    ACCOUNTS_LIST: 'Liste des comptes',
    VERIFYING: 'Vérification…',
    SUCCESS: 'Succès',
    connections: 'Gérer les connexions',
    MANAGE_CONNECTIONS: 'Gérer les connexions',
    CONNECT_ACCOUNT: 'Ajouter un compte',
    CONNECT_ACCOUNTS: 'Connecter des comptes',
    BACK_TO_CONNECTIONS: 'Retourner aux connexions',
    BACK_TO_SEARCH: 'Retourner à la recherche',
    CANCEL: 'Annuler',
    UNABLE_TO_UPDATE_MEMBER_MESSAGE:
      "Nous ne pouvons pas présenter d'informations mises à jour pour ce compte.",
    ERROR: 'Erreur',
    CONNECTING: 'Connexion en cours',
    SYNC_ACCOUNTS: 'Actualiser les comptes',
    SYNCING: 'Actualisation',
    SUCCESSFULLY_CONNECTED: 'Vous avez réussi à connecter {institutionName}!',
    SUCCESSFULLY_VERIFIED: 'Vous avez vérifié avec succès {institutionName}!',
    _ACCOUNTNAME_HIDDEN: '{accountName} (caché)',
    _ACCOUNTNAME_CLOSED: '{accountName} (fermé)',
    HIDE_ACCOUNT: 'Cacher le compte',
    UNHIDE_ACCOUNT: 'Afficher le compte',
    MARK_AS_DUPLICATE: 'Identifié comme un «Double»',
    MARK_AS_CLOSED: 'Identifié comme un «Fermé»',
    OPTIONS_FOR_ACCOUNT_ACCOUNTNAME: 'Options pour compte {accountName}',
    SELECT_AN_OPTION_FOR_ACCOUNTNAME: 'Sélectionner une option pour {accountName}',
    ACTIONS: 'Actions',
    UPDATE_CREDENTIALS: 'Mettez à jour vos identifiants de connexion',
    GO_TO_URL: 'Visiter {url}',
    DELETE_INSTITUTION: "Supprimer l'institution financière",
    DISCONNECT_INSTITUTION: "Déconnecter l'établissement",
    DELETE_MEMBER_SUCCESS: 'Vous avez supprimé {institutionName} avec succès',
    MARK_ACCOUNTNAME_AS_A_DUPLICATE_ACCOUNT:
      'Identifier {accountName} en tant que compte en double?',
    MARKING_AN_ACCOUNT_AS_DUPLICATE_WILL_MERGE:
      'En identifiant un compte de «Double», ses données seront fusionnées avec le compte sélectionné pour n’en former qu’un seul.',
    SELECT_DUPLICATE: 'Sélectionner le «Double»',
    MERGE: 'Fusionner',
    MERGE_DISABLED_SELECT_A_DUPLICATE_ACCOUN:
      'Fusionner désactivé - Sélectionnez un compte en double',
    SYNC: 'Actualiser',
    GO_TO_PAGE: 'Aller à la page',
    MANAGE_ACCOUNT_WITH_NAME: 'Gérer le compte avec {name}',
    HOME_ACCOUNTS: 'Comptes principaux',
    OUTSIDE_ACCOUNTS: 'Comptes externes',
    KNOW_YOUR_FINANCES: 'Connaître vos finances',
    SEE_ALL_YOUR_ACCOUNTS_INCLUDING_THOSE_WITH:
      'Chaque compte, un endroit. Connectez un compte pour commencer.',
    _NAME_LOGO: '{name} logo',
    INVALID_CREDENTIALS: 'L’identifiant ou le mot de passe est invalide',
    EMPTY_FIELDS: `Aucune information d'identification entrée`,
    ACCOUNTS_CONNECTED: `{count} {count, plural,
      one {Compte Connecté}
      other {Comptes Connectés}
    }`,
    COULDNT_FIND: 'Vous ne trouvez pas ce que vous cherchez?',
    TRY_THIS: 'Essayez ceci:',
    SEARCH_EXAMPLE: "Cherchez le site web de l'institution financière. Par exemple, «www.bnc.ca»",
    SIMPLIFY_SEARCH:
      'Simplifiez vos termes de recherches. Par exemple, «Scotia» plutôt que «Scotiabank»',
    WHAT_INSTITUTION: 'Faites-nous savoir quelle institution vous recherchez.',
    ENTER_ACCOUNTS: 'Entrez vos comptes et vos transactions vous-même.',
    SEND_INSTITUTION: 'Envoyez-nous votre institution.',
    ADD_MANUAL: 'Ajouter un compte manuellement',
    VALIDATION_REQUIRED: `{value} est obligatoire.`,
    VALIDATION_MIN: `{value} doit être supérieur ou égal à {expectation}.`,
    VALIDATION_MAX: `{value} doit être inférieur ou égal à {expectation}.`,
    VALIDATION_RANGE: `{value} doit être entre {lower} et {upper}.`,
    VALIDATION_LENGTH: `{value} doit être {length} caractères.`,
    VALIDATION_MINLENGTH: `{value} doit avoir au moins {length} caractères.`,
    VALIDATION_MAXLENGTH: `{value} doit être maximum {length} caractères.`,
    VALIDATION_RANGELENGTH: `{value} doit être entre {lower} et {upper} caractères.`,
    VALIDATION_ONEOF: `{value} doit être l'un des: {list}.`,
    VALIDATION_NUMBER: `{value} doit être un chiffre.`,
    VALIDATION_DIGITS: `{value} doit contenir uniquement des chiffres.`,
    VALIDATION_EMAIL: `{value} doit être une adresse courriel valide.`,
    VALIDATION_URL: `{value} doit être une adresse web valide.`,
    VALIDATION_INLINEPATTERN: `{value} est invalide.`,
    VALIDATION_EQUALTO: `{value} doit être identique à {expectation}.`,
    //Add Manual Accounts
    TRACK_ACCOUNTS_THAT_DONT_:
      "Suivez les actifs comme une maison, une voiture, de l'argent comptant ou un objet de valeur. Vous pouvez également ajouter un compte manuellement lorsqu'il n'y a pas de connexion automatique disponible.",
    ENTER_THE_DETAILS_OF_YOUR_: 'Entrez les détails de votre {type}',
    NEXT: 'Suivant',
    SAVE: 'Enregistrer',
    Business: 'Entreprise',
    'Account Balance': 'Solde du compte',
    'Account Name': 'Nom de compte',
    'Account Type': 'Type de compte',
    'Credit Limit': 'Limite de crédit',
    'Enter Account Balance': 'Entrez le solde du compte',
    'Enter Account Name': 'Entrez le nom du compte',
    'Enter Credit Limit': 'Entrez la limite de crédit',
    'Enter Day Payment is Due': 'Entrez la date dû pour le paiement',
    'Enter Interest Rate': "Entrez le taux d'intérêt",
    'Enter Minimum Payment': 'Entrez le paiement minimum',
    'Enter Original Balance': 'Entrez le solde initial',
    'Interest Rate': "Taux d'intérêt",
    'Link Status': 'Statut de connexion',
    'Next Payment': 'Prochain paiement',
    'Minimum Payment': 'Paiement minimum',
    'Original Balance': 'Solde initial',
    'Payment Due': 'Date d’échéance du paiement',
    'Property Type': 'Type de propriété',
    'Select A Property Type': 'Choisir le type de propriété',
    'Select An Account Type': 'Sélectionnez un type de compte',
    APR: 'AVR',
    APY: 'APY',
    WHAT_TYPE_OF_ACCOUNT: 'Quel type de compte créez-vous?',
    // AccountTypeMessages
    Other: 'Compte Inconnu',
    Checking: 'Compte Chèque',
    Savings: "Compte d'Épargne",
    Loan: 'Compte de Prêt',
    'Credit Card': 'Carte de Crédit',
    Investment: 'Investissement',
    'Line of Credit': 'Marge de Crédit',
    Mortgage: 'Hypothèque',
    Property: 'Propriété',
    Cash: 'Argent Comptant',
    Insurance: 'Assurance',
    Prepaid: 'Carte Prépayé',
    INSTITUTION_SEARCH_DISABLED_MESSAGE:
      "La connexion à l'institution financière est en cours et pourrait prendre quelques minutes.",
    // PropertyTypeMessages
    'Real Estate': 'Immobilier',
    Vehicle: 'Véhicule',
    Art: 'Art',
    Jewelry: 'Bijoux',
    Furniture: 'Mobilier',
    Appliances: 'Électroménagers',
    Computer: 'Ordinateur',
    Electronics: 'Électroniques',
    'Sports Equipment': 'Équipement sportif',
    Miscellaneous: 'Divers',
    DAY_OF_EVERY_MONTH: `{day}ème de chaque mois`,
    WHAT_DAY_OF_MONTH: 'Quel jour du mois soumettez-vous votre paiement?',

    TYPEWORD_CONTINUE: `Tapez le mot {word} pour continuer`,
    DELETE_BODY: "Cela supprimera ce compte et toutes ses transactions du reste de l'application",
    DELETE_TYPEWORD: 'EFFACER',
    DELETE_ACCOUNT: 'Supprimez un compte',
    SEVERAL_MINUTES: 'Cela peut prendre plusieurs minutes',
    THIS_ACTION_CANNOT_BE_UNDONE:
      'Cette action ne peut pas être annulée et peut prendre plusieurs minutes',
    ACCOUNT_NAME_WILL_BE_MERGED: 'Sélectionnez un compte qui sera jointe avec {accountName}',
    ACCOUNT_NAME_WILL_BE_MERGED_TO_DUPLICATE:
      'Cela signifie que {accountName} et {selectedDuplicateAccountName} sera jointe',
    // Request Institution Modal
    WANT_TO_ADD:
      'À votre demande, pour ajouter une institution financière à notre système, veuillez fournir les renseignements suivants.',
    INSTITUTION_WEBSITE: "Site de l'institution",
    INSTITUTION_LOGIN_PAGE: "Site de connexion de l'institution",
    SEND: 'Envoyer',
    SELECT_CONNECTION_FOR_VERIFY:
      'Sélectionnez la connexion que vous voulez désactiver, ou ajoutez une nouvelle connexion',
    VERIFY_NEW_CONNECTION: 'Vérifiez la nouvelle connexion',
    VERIFICATION_NOT_ENABLED:
      'Oops! Verification doit être activée pour pouvoir utiliser cette fonction',
    ALREADY_CONNECTED: 'Ce compte est déjà connecté.',
    ALREADY_VERIFIED: 'Ce compte a déjà été vérifié.',
    CONNECTION_VERIFY_NOT_ENABLED: 'Cette connexion ne peut être contrôlée',
    TRY_VERIFY_LATER: 'Nous ne pouvons vérifier en ce moment la connexion',
    LOAD_CONNECT_ERROR: 'Oups! Une erreur s’est produite. Veuillez réessayer plus tard.',
    LOAD_CONNECT_NOT_FOUND: 'Oops! Nous ne pouvons pas trouver cette connexion',
    ZERO_STATE_ACCOUNTS_MESSAGE:
      'Connectez tous vos comptes pour voir vos finances en un seul endroit.',
    ZERO_STATE_ACCOUNTS_TITLE: 'Simplifiez votre vie.',
    ZERO_STATE_ACCOUNTS_BUTTON: 'Ajouter un compte',
    ZERO_STATE_BUDGETS_MESSAGE: 'Connectez tous vos comptes pour créer des budgets mensuels.',
    ZERO_STATE_BUDGETS_TITLE: 'Prenez le contrôle de votre argent.',
    ZERO_STATE_BUDGETS_BUTTON_FROM_SCRATCH: 'Commencer à partir de zéro',
    ZERO_STATE_BUDGETS_BUTTON_AUTOMATIC: 'Génération automatique de budgets',
    ZERO_STATE_CASHFLOW_MESSAGE:
      'Connectez tous vos comptes pour prévoir les dépenses à venir et les fonds disponibles.',
    ZERO_STATE_CASHFLOW_TITLE: "Planifier pour l'avenir.",
    ZERO_STATE_DEBT_TITLE: 'Éliminez votre dette.',
    ZERO_STATE_DEBT_MESSAGE:
      'Connectez tous vos comptes pour payer vos dettes le plus rapidement possible.',
    ZERO_STATE_DEBT_BUTTON: 'Ajouter un compte',
    ZERO_STATE_GOALS_MESSAGE: "Fixer des objectifs financiers et planifier pour l'avenir.",
    ZERO_STATE_GOALS_TITLE: 'Prenez le contrôle de votre argent.',
    ZERO_STATE_GOALS_BUTTON: 'Commencez',
    ZERO_STATE_INVESTMENTS_MESSAGE: 'Ajoutez un compte pour voir tous vos avoirs au même endroit.',
    ZERO_STATE_INVESTMENTS_TITLE: 'Suivez tous vos investissements.',
    ZERO_STATE_NETWORTH_MESSAGE:
      'Connectez tous vos comptes pour suivre votre valeur nette au fil du temps.',
    ZERO_STATE_NETWORTH_TITLE: 'Voir la grande image.',
    ZERO_STATE_SPENDING_MESSAGE:
      'Connectez tous vos comptes pour voir où va votre argent chaque mois.',
    ZERO_STATE_SPENDING_TITLE: 'Comprenez vos dépenses.',
    ZERO_STATE_TRANSACTIONS_MESSAGE:
      'Connectez tous vos comptes pour afficher les transactions au même endroit.',
    ZERO_STATE_TRANSACTIONS_TITLE: 'Simplifiez votre vie.',
    ZERO_STATE_TRANSACTIONS_BUTTON: 'Ajouter un compte',
    ZERO_STATE_TRENDS_MESSAGE:
      'Connectez tous vos comptes pour voir les tendances des dépenses au fil du temps.',
    ZERO_STATE_TRENDS_TITLE: 'Comprenez vos dépenses.',
    // Zero State Add Accounts
    ADD: 'Ajouter',
    CHECKING: 'un Compte Chèque',
    SAVINGS: "un Compte d'Épargne",
    PREPAID: 'une Carte Prépayé',
    CASH: 'un Compte Argent Comptant',
    INVESTMENT: 'un Compte Investissement',
    PROPERTY: 'Propriété',
    CREDIT_CARD: 'une Carte de Crédit',
    MORTGAGE: 'un Hypothèque',
    LOAN: 'un Prêt',
    LINE_OF_CREDIT: 'une Marge de Crédit',
    INSURANCE: "d'Assurance",
    CLOSE_MENU: 'Ferme Le Menu',
    PROBLEM_WITH_CONNECTION_FOLLOW_UP: `Un problème avec cette connexion nécessite l'aide de notre équipe de support. S'il vous plaît confirmer votre courriel, et nous reviendrons vers vous dès que possible.`,
    REQUEST_SUPPORT_BUTTON: `Demander l'assistance`,
    WE_RE_ON_IT: `Nous l'examinons!`,
    ENTER_EMAIL_ADDRESS: `Entrer l'adresse courriel`,
    PROBLEM_VALIDATING_PLEASE_WAIT: `Un problème est survenu lors de la validation de vos informations d'identification avec {institutionName}. Veuillez réessayer plus tard.`,
    CONNECTION_FAILED: 'La Connexion a Échoué',
    CONTACT_FORM_USAGE_MESSAGE: `Veuillez utiliser ce formulaire pour des questions, des commentaires ou des préoccupations concernant ce produit de gestion de fonds. N'incluez aucune information privée ou financière, telle qu'un numéro de compte ou un mot de passe. Si vous avez des questions sur vos finances — y compris des transactions, des fonds, des paiements de factures, des virements, des prêts, etc. — veuillez contacter directement le service clientèle.`,
    ATTENTION: 'Attention',
    YOU_ARE_ABOUT_TO_DELETE_THIS_INSTITUTION:
      'En sélectionnant "Supprimer", ce compte et toutes ses transactions seront supprimés de cet outil. Ça ne peut pas être annulé.',
    WHY_DO_YOU_WANT_TO_DELETE: `Pourquoi voulez-vous supprimer {memberName}?`,
    NO_LONGER_USE_ACCOUNT: 'Je n’utilise plus ce compte ou ce n’est pas le mien',
    DONT_WANT_SHARE_DATA: 'Je ne veux pas partager mes données',
    ACCOUNT_INFORMATION_OLD: 'Les informations de compte sont anciennes ou inexactes',
    UNABLE_CONNECT_ACCOUNT: `Je n'arrive pas à connecter ce compte ici`,
    DONT_WANT_TO_USE_APP: 'Je ne veux pas utiliser cette application',
    DONT_WANT_ACCOUNT_CONNECTED: 'Je ne veux pas que ce compte soit connecté ici',
    OTHER_REASON: 'Autre',
    CONNECTED_ACCOUNTS: `Comptes Connectés`,
    SHOW_PASSWORD: 'Montrer le mot de passe',
    HIDE_PASSWORD: 'Masquer le mot de passe',
    MARK_ACCOUNTNAME_AS_CLOSED: `Marquer {accountName} comme fermé?`,
    THIS_WILL_SET_THE_BALANCE_OF_THE_ACCOUNT_TO_0: `Cela fixera le solde du compte à 0,00 $ et conservera l'historique des transactions passées. Cela peut être annulé en marquant le compte comme ouvert.`,
    THIS_WILL_SET_THE_BALANCE_OF_THE_ACCOUNT_TO_0_CANNOT_BE_UNDONE: `Cela fixera le solde du compte à 0,00 $ US et ne pourra pas être annulé. Ne vous inquiétez pas, vous pourrez toujours accéder à l'historique des transactions passées.`,
    MARK_ACCOUNTNAME_AS_OPEN: `Marquer {accountName} comme ouvert?`,
    THIS_WILL_RESUME_UPDATING_THE_BALANCE_AND_TRANSACTION_HISTORY: `Cela reprendra la mise à jour du solde et de l'historique des transactions, si de nouvelles mises à jour sont disponibles. Cela peut prendre 24 heures pour prendre effet.`,
    MARK_AS_OPEN: `Identifié comme un «Ouvert»`,
  },
}

module.exports = {
  "oauth_state": {
    "guid": "OAS-241266ed-803a-4841-8a8a-0f37551e8f56",
    "auth_status": 1,
    "created_at": "2023-03-31T21:08:39+00:00",
    "error_reason": null,
    "first_retrieved_at": null,
    "inbound_member_guid": null,
    "outbound_member_guid": "MBR-241266ed-803a-4841-8a8a-0f37551e8f56",
    "updated_at": "2023-03-31T21:08:39+00:00",
    "user_guid": "USR-241266ed-803a-4841-8a8a-0f37551e8f56"
  }
}
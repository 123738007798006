// import React from 'react'

// import { useTokens } from '@kyper/tokenprovider'
// import { ChevronRight } from '@kyper/icon/ChevronRight'
// import PropTypes from 'prop-types'

// import { Button } from '@kyper/button'
// import PoweredByMXText from './PoweredByMXText'

// const PoweredByMX = ({ onClick }) => {
//   const tokens = useTokens()
//   const styles = getStyles(tokens)

//   return (
//     <Button onClick={onClick} style={styles.button} variant="transparent-tertiary">
//       <PoweredByMXText />
//       <ChevronRight style={styles.chevron} />
//     </Button>
//   )
// }

// PoweredByMX.propTypes = {
//   onClick: PropTypes.func,
// }

// const getStyles = () => {
//   return {
//     button: {
//       display: 'flex',
//       flexDirection: 'row',
//       width: '100%',
//     },
//     chevron: { marginLeft: 4 },
//   }
// }
// export default PoweredByMX
export default () => null

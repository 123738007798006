module.exports = {
  "credentials": [
      {
          "field_name": "LOGIN",
          "guid": "CRD-241266ed-803a-4841-8a8a-0f37551e8f56",
          "label": "Username",
          "display_order": 1,
          "field_type": "LOGIN",
          "type": "LOGIN"
      },
      {
          "field_name": "PASSWORD",
          "guid": "CRD-241266ed-803a-4841-8a8a-0f37551e8f56",
          "label": "Password",
          "display_order": 2,
          "field_type": "PASSWORD",
          "type": "PASSWORD"
      }
  ],
  "pagination": {
      "current_page": 1,
      "per_page": 10,
      "total_entries": 2,
      "total_pages": 1
  }
}
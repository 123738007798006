export const EventCategories = {
  AB_TEST: 'AB Test',
}

export const EventLabels = {
  IE_DEPRECATION: 'IE Deprecation',
}

export const EventActions = {
  CLOSE: 'Close',
  DOWNLOAD: 'Download',
}

module.exports = {
  "accounts": [
      {
          "investmentAccount": {
              "accountId": "1755209824",
              "accountType": "I",
              "balanceType": "ASSET",
              "currency": {
                  "currencyCode": "USD",
                  "currencyRate": 0,
                  "originalCurrencyCode": "USD"
              },
              "nickname": "INDIVIDUAL"
          }
      },
      {
          "investmentAccount": {
              "accountId": "1755093543",
              "accountType": "I",
              "balanceType": "ASSET",
              "currency": {
                  "currencyCode": "USD",
                  "currencyRate": 0,
                  "originalCurrencyCode": "USD"
              },
              "nickname": "Cash Management-Individual"
          }
      }
  ]
}
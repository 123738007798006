export const CredentialTypes = {
  TEXT: 0,
  PASSWORD: 1,
  OPTIONS: 2,
  LOGIN: 3,
  CHECKBOX: 4,
  RADIO: 5,
  URL: 6,
  HIDDEN: 7,
  IMAGE_URL: 8,
  CONTENT_URL: 9,
  CUSTOM: 10,
  CLUDGE: 11,
  TOKEN: 12,
  IMAGE_DATA: 13,
  IMAGE_OPTIONS: 14,
  USERKEY: 15,
  OAUTH2_URL: 16,
  OAUTH_ACCESS_TOKEN: 17,
  OAUTH_REFRESH_TOKEN: 18,
}

export const CONNECT_FORGOT_CREDENTIALS_EXPERIMENT = 'CONNECT_FORGOT_CREDENTIALS_EXPERIMENT'
export const CONNECT_FORGOT_CREDENTIALS = 'CONNECT_FORGOT_CREDENTIALS'
export const CONNECT_GO_TO_INSTITUTION = 'CONNECT_GO_TO_INSTITUTION'

export const connectABExperiments = {
  // Already used a,b,c,d,e,f
  [CONNECT_FORGOT_CREDENTIALS_EXPERIMENT]: {
    [CONNECT_FORGOT_CREDENTIALS]: '/c',
    [CONNECT_GO_TO_INSTITUTION]: '/d',
  },
}

/* CONNECT_HIDE_LIGHT_DISCLOSURE */
export const CONNECT_HIDE_LIGHT_DISCLOSURE_EXPERIMENT = 'CONNECT_HIDE_LIGHT_DISCLOSURE'
export const CONNECT_HIDE_LIGHT_DISCLOSURE = 'CONNECT_HIDE_LIGHT_DISCLOSURE'

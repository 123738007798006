module.exports = {
  "institutions": [
    {
      "guid": "241266ed-803a-4841-8a8a-0f37551e8f56",
      "code": "gringotts",
      "instructional_text": "Please do all these things <a href=\"https://google.com\" id=\"instructional_text\">My Test Link</a>",
      "medium_logo_url": "https://content.moneydesktop.com/storage/MD_Assets/Ipad%20Logos/100x100/INS-asdf",
      "name": "Gringotts",
      "small_logo_url": "https://content.moneydesktop.com/storage/MD_Assets/Ipad%20Logos/50x50/INS-asdf",
      "supports_account_identification": true,
      "supports_account_statement": true,
      "supports_tax_document": false,
      "supports_account_verification": true,
      "supports_oauth": false,
      "supports_transaction_history": true,
      "url": "https://gringotts.qa.internal.mx/"
    }
  ]
}
export const EventCategories = {
  TRANSACTIONS: 'Transactions',
  TRANSACTION_DETAILS: 'Transaction Details',
}

export const EventLabels = {
  MANUAL_TRANSACTION: 'Manual Transaction',
  MERCHANT_LOGO: 'Merchant Logo',
  SEARCH: 'Search',
  RANDOM: 'Random',
  SORT: 'Sort',
  TRANSACTION_ROW: 'Transaction Row',
  TRANSACTION_CATEGORY: 'Transaction Category',
  CHANGE_CATEGORY_RULE: 'Change Category Rule',
  INCLUDE_TRANSACTION: 'Include Transaction',
  EXCLUDE_TRANSACTION: 'Exclude Transaction',
  FLAG_TRANSACTION: 'Flag Transaction',
  SPLIT_TRANSACTION: 'Split Transaction',
  TRANSACTION_DETAILS: 'Transaction Details',
  CHANGE_TRANSACTION_CATEGORY: 'Change Transaction Category',
  TRANSACTION_DATE: 'Transaction Date',
  TRANSACTION_MEMO: 'Transaction Memo',
  TRANSACTION_PAYEE: 'Transaction Payee',
  TRANSACTION_TAG: 'Transaction Tag',
}

export const EventActions = {
  CANCEL: 'Cancel',
  END: 'End',
  START: 'Start',
  CREATE_ACCOUNT: 'Create Account',
  OPEN: 'Open',
  CLOSE: 'Close',
  CLEAR: 'Clear',
  DOWNLOAD_CSV: 'Download CSV',
  SORT: 'Sort',
  TRANSACTION_DETAILS: 'Transaction Details',
  ALL_TRANSACTIONS: 'All Transactions',
  ONE_TIME: 'One Time',
  ON: 'On',
  OFF: 'Off',
  BACK: 'Back',
  SCRIM: 'Scrim',
  EDIT: 'Edit',
  SELECT: 'Select',
  CREATE: 'Create',
  DELETE: 'Delete',
}

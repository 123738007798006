module.exports = {
  "client": {
    "guid": "241266ed-803a-4841-8a8a-0f37551e8f56",
    "default_institution_guid": "241266ed-803a-4841-8a8a-0f37551e8f56",
    "name": "241266ed-803a-4841-8a8a-0f37551e8f56",
    "urls": null,
    "has_atrium_api": false,
    "has_limited_institutions": false
  },
  "client_color_scheme": {
    "primary_100": null,
    "primary_200": null,
    "primary_300": null,
    "primary_400": null,
    "primary_500": null,
    "primary_color": "",
    "secondary_color": "",
    "widget_brand_color": null
  },
  "client_communication_profile": {
    "alerts_display_name": null,
    "budget_exceeded_notifications_enabled": false,
    "budget_off_target_notifications_enabled": false,
    "can_edit_contact_info": true,
    "debt_payment_reminder_notifications_enabled": false,
    "device_created_email_enabled": false,
    "device_deleted_email_enabled": false,
    "duplicate_payment_notifications_enabled": false,
    "email_notifications_enabled": false,
    "goal_progress_notifications_enabled": false,
    "large_deposit_notifications_enabled": false,
    "large_expense_notifications_enabled": false,
    "low_balance_notifications_enabled": false,
    "offer_notifications_enabled": false,
    "password_changed_email_enabled": false,
    "push_notifications_enabled": false,
    "sms_notifications_enabled": false,
    "sms_shortcode": null,
    "sms_shortcode_privacy_policy_url": null,
    "sms_shortcode_terms_of_service_url": null,
    "sms_verification_enabled": false,
    "sms_welcome_enabled": false,
    "transaction_fee_notifications_enabled": false,
    "transaction_is_international_notifications_enabled": false,
    "user_summary_notifications_enabled": false,
    "verification_email_enabled": false,
    "welcome_email_enabled": false
  },
  "client_profile": {
    "account_verification_is_enabled": true,
    "allow_accounts_widget": false,
    "allow_budgets_widget": false,
    "allow_cash_flow_widget": false,
    "allow_debts_widget": false,
    "allow_goals_widget": false,
    "allow_investments_widget": false,
    "allow_linked_account_add": true,
    "allow_mobile_master_widget": false,
    "allow_net_worth_widget": false,
    "allow_notifications_settings_widget": false,
    "allow_spending_widget": false,
    "allow_transactions_widget": false,
    "allow_trends_widget": false,
    "client_guid": "241266ed-803a-4841-8a8a-0f37551e8f56",
    "custom_copy_namespace": null,
    "institution_display_name": "",
    "is_microdeposits_enabled": true,
    "locale": "en-US",
    "mobile_app_android_url": "",
    "mobile_app_itunes_url": "",
    "privacy_policy_url": null,
    "show_bubble_budgets": true,
    "show_external_link_popup": true,
    "show_insurance_as_default_top_level_account_type": false,
    "show_notifications_settings_widget": true,
    "show_prepaid_as_default_top_level_account_type": false,
    "tax_statement_is_enabled": false,
    "uses_custom_popular_institution_list": false,
    "uses_mobile_sign_on_tokens": true,
    "uses_oauth": true,
    "default_institution_guid": "241266ed-803a-4841-8a8a-0f37551e8f56"
  },
  "user": {
    "guid": "241266ed-803a-4841-8a8a-0f37551e8f56",
    "birthday": 1320945600,
    "client_guid": "241266ed-803a-4841-8a8a-0f37551e8f56",
    "credit_score": 750,
    "email": "241266ed-803a-4841-8a8a-0f37551e8f56",
    "email_is_verified": true,
    "first_name": "241266ed-803a-4841-8a8a-0f37551e8f56",
    "has_accepted_terms": true,
    "has_password": true,
    "has_updated_terms_and_conditions": false,
    "health_score": 98,
    "last_name": "241266ed-803a-4841-8a8a-0f37551e8f56",
    "metadata": null,
    "phone": null,
    "phone_is_verified": false,
    "postal_code": null,
    "sex": null,
    "uses_single_sign_on": false,
    "created_at": 1663453526
  },
  "user_profile": {
    "guid": "241266ed-803a-4841-8a8a-0f37551e8f56",
    "has_completed_finstrong_onboarding": true,
    "has_completed_guide_me_wizard": false,
    "has_completed_spending_plan_onboarding": false,
    "has_completed_widgets_wizard": false,
    "has_closed_accounts_widget_help_state": false,
    "has_closed_budgets_widget_help_state": false,
    "has_closed_debts_widget_help_state": false,
    "has_closed_goals_widget_help_state": false,
    "has_closed_net_worth_widget_help_state": false,
    "has_closed_spending_widget_help_state": false,
    "has_closed_transactions_widget_help_state": false,
    "has_closed_trends_widget_help_state": false,
    "uses_detailed_notifications": false,
    "user_guid": "241266ed-803a-4841-8a8a-0f37551e8f56"
  },
  "user_communication_profile": {
    "client_guid": "241266ed-803a-4841-8a8a-0f37551e8f56",
    "guid": "241266ed-803a-4841-8a8a-0f37551e8f56",
    "opted_out_of_all_notifications": false,
    "opted_out_of_email_notifications": false,
    "opted_out_of_push_notifications": false,
    "opted_out_of_sms_notifications": true,
    "status_code": 200,
    "summary_email_enabled": null,
    "user_guid": "241266ed-803a-4841-8a8a-0f37551e8f56",
    "created_at": 1660673527,
    "updated_at": 1660673527
  },
  "address": {
    "bullseye": "http://localhost:3001"
  }
}
export const OfferType = {
  ACCOUNT_WIDGET_BANNER: 3,
  FINSMART_CREDIT_CARD: 8,
  FINSMART_CREDIT_CARD_SMALL_CARD: 9,
  FINSMART_CREDIT_CARD_SPENDING_WIDGET: 10,
  FINSMART_BALANCE_TRANSFER: 12,
  FINSMART_BALANCE_TRANSFER_SMALL_CARD: 13,
  FINSMART_BALANCE_TRANSFER_SPENDING_WIDGET: 14,
  TOAST_MASTER: 15,
  TOAST_MINI: 16,
  TOAST_MOBILE: 17,
}

export const EventActions = {
  BLUR: 'blur',
  CLICK: 'click',
}

export const EventCategories = {
  ACCOUNT_DETAILS: 'Account Details',
  ACCOUNTS: 'Accounts',
  ADD_MANUAL_ACCOUNT: 'Add Manual Account',
  BUDGET_DETAILS: 'Budget Details',
  BUDGET_ZERO: 'Budget Zero State',
  BUDGETS: 'Budgets',
  BUDGETS_ADD_SUB_BUDGET: 'Budgets Add Sub-Budget',
  BUDGETS_DELETE_BUDGET: 'Budgets Delete Budget',
  BUDGETS_EDIT_BUDGET: 'Budgets Edit Budget',
  BUDGETS_RECALCULATE: 'Recalculate Budgets',
  CASH_FLOW: 'Cash Flow',
  CONNECT: 'Connect',
  CONNECT_CREDENTIALS: 'Connect Credentials',
  CONNECT_MFA: 'Connect MFA',
  CONNECT_TIMEOUT: 'Connect Timeout',
  CONNECTION_DETAILS: 'Connection Details',
  DELETE_ACCOUNT: 'Delete Account',
  DELETE_CONNECTION: 'Delete Connection',
  EDIT_INCOME_BUDGET: 'Edit Income Budget',
  MANAGE_BUDGETS: 'Manage Budgets',
  NET_WORTH: 'Net Worth',
  SPENDING: 'Spending',
  TRANSACTION_DETAILS: 'Transaction Details',
  TRANSACTION_RECATEGORIZE: 'Transaction Recategorize',
  TRANSACTIONS: 'Transactions',
  TRANSACTIONS_LIST: 'Transactions List',
  TRENDS: 'Trends',
}

export const GroupedEventNames = {
  [EventCategories.ACCOUNT_DETAILS]: {
    DELETE_ACCOUNT_START: 'Delete Account - Start',
  },
  [EventCategories.ADD_MANUAL_ACCOUNT]: {
    ADD_MANUAL_ACCOUNT_CANCEL: 'Add Manual Account - Cancel',
    ADD_MANUAL_ACCOUNT_END: 'Add Manual Account - End',
  },
  [EventCategories.BUDGET_ZERO]: {
    AUTO_GENERATE: 'Auto Generate',
    START_FROM_SCRATCH: 'Start from Scratch',
  },
  [EventCategories.BUDGET_DETAILS]: {
    ADD_SUB_BUDGET_START: 'Add Sub-Budget - Start',
    BUDGET_DETAILS_CLOSE: 'Budget Details - Close',
  },
  [EventCategories.BUDGETS]: {
    BUDGET_SUMMARY_OPEN: 'Budget Summary - Open',
    BUDGET_SUMMARY_CLOSE: 'Budget Summary - Close',
    EDIT_INCOME_BUDGET_START: 'Edit Income Budget - Start',
  },
  [EventCategories.BUDGETS_ADD_SUB_BUDGET]: {
    ADD_SUB_BUDGET_CLOSE: 'Sub-Budget - Close',
    ADD_CUSTOM_CATEGORY_START: 'Add Custom Category - Start',
    ADD_CUSTOM_CATEGORY_END: 'Add Custom Category - End',
    DELETE_CUSTOM_CATEGORY_START: 'Delete Custom Category - Start',
    DELETE_CUSTOM_CATEGORY_END: 'Delete Custom Category - End',
  },
  [EventCategories.BUDGETS_DELETE_BUDGET]: {
    DELETE_BUDGET_CANCEL: 'Delete Budget - Cancel',
    DELETE_BUDGET_END: ' Delete Budget - End',
  },
  [EventCategories.BUDGETS_EDIT_BUDGET]: {
    EDIT_BUDGET_CANCEL: 'Edit Budget - Cancel',
    EDIT_BUDGET_END: 'Edit Budget - End',
    EDIT_BUDGET_START: 'Edit Budget - Start',
    DELETE_BUDGET_START: 'Delete Budget - Start',
  },
  [EventCategories.BUDGETS_RECALCULATE]: {
    RECALCULATE_BUDGETS_START: 'Recalculate Budgets - Start',
    RECALCULATE_BUDGETS_SAVE: 'Recalculate Budgets - Save Recalculation',
    RECALCULATE_BUDGETS_UNDO: 'Recalculate Budgets - Undo Recalculation',
  },
  [EventCategories.CASH_FLOW]: {
    VIEW_CHART: 'View chart',
    VIEW_CALENDAR: 'View calendar',
    IGNORE_SEQUENCE: 'Ignore sequence',
    CREATE_SEQUENCE_FROM_TRANSACTION_START: 'Create sequence from transaction - start',
    CREATE_SEQUENCE_FROM_TRANSACTION_END: 'Create sequence from transaction - end',
    CREATE_SEQUENCE_FROM_SCRATCH_START: 'Create sequence from scratch - start',
    CREATE_SEQUENCE_FROM_SCRATCH_END: 'Create sequence from scratch - end',
    EDIT_SEQUENCE_START: 'Edit sequence - start',
    EDIT_SEQUENCE_END: 'Edit sequence - end',
    DELETE_EVENT_START: 'Delete event - start',
    DELETE_EVENT_END: 'Delete event - end',
  },
  [EventCategories.CONNECT]: {
    ADD_ACCOUNT_START: 'Add Account - Start',
    ADD_MANUAL_ACCOUNT_START: 'Add Manual Account - Start',
  },
  [EventCategories.CONNECT_CREDENTIALS]: {
    SUBMIT_CREDENTIALS: 'Submit Credentials',
  },
  [EventCategories.CONNECT_MFA]: {
    ANSWER_MFA: 'Answer MFA',
  },
  [EventCategories.CONNECTION_DETAILS]: {
    BACK_TO_CONNECTIONS: 'Back To Connections',
    BACK_TO_SEARCH: 'Back To Search',
    CONTACT_US: 'Contact Us',
    DELETE_ACCOUNT_START: 'Delete Account - Start',
    DELETE_INSTITUTION_START: 'Delete Institution - Start',
    GO_TO_WEBSITE: 'Go to Website',
    VISIT_WEBSITE_RESOLVE: 'Visit Website Resolve',
    HIDE_ACCOUNT: 'Hide Account',
    MARK_AS_CLOSED_END: 'Mark as Closed - End',
    MARK_AS_CLOSED_START: 'Mark as Closed - Start',
    MARK_AS_DUPLICATE_END: 'Mark as Duplicate - End',
    MARK_AS_DUPLICATE_START: 'Mark as Duplicate - Start',
    RESOLVE_CONNECTION_ERROR: 'Resolve Connection Error',
    SYNC: 'Sync',
    UPDATE_CREDENTIALS_START: 'Update Credentials - Start',
    UNHIDE_ACCOUNT: 'Unhide Account',
  },
  [EventCategories.DELETE_ACCOUNT]: {
    DELETE_ACCOUNT_CANCEL: 'Delete Account - Cancel',
    DELETE_ACCOUNT_END: 'Delete Account - End',
  },
  [EventCategories.DELETE_CONNECTION]: {
    DELETE_INSTITUTION_CANCEL: 'Delete Institution - Cancel',
    DELETE_INSTITUTION_END: 'Delete Institution - End',
  },
  [EventCategories.EDIT_INCOME_BUDGET]: {
    EDIT_INCOME_BUDGET_CANCEL: 'Edit Income Budget - Cancel',
    EDIT_INCOME_BUDGET_END: 'Edit Income Budget - End',
  },
  [EventCategories.MANAGE_BUDGETS]: {
    MANAGE_BUDGETS_END: 'Manage Budgets - End',
    MANAGE_BUDGETS_START: 'Manage Budgets - Start',
  },
  [EventCategories.NET_WORTH]: {
    VIEW_GAINS_AND_LOSSES: 'View Gains and Losses',
  },
  [EventCategories.TRANSACTION_RECATEGORIZE]: {
    CREATE_RULE: 'Transaction Recategorize - Create Rule',
    SINGLE_RECATEGORIZE: 'Transaction Recategorize - Single Recategorize',
  },
  [EventCategories.TRANSACTIONS]: {
    ADD_MANUAL_TRANSACTION_START: 'Add Manual Transaction - Start',
  },
  [EventCategories.TRANSACTIONS_LIST]: {
    TRANSACTIONS_LIST_CLOSE: 'Transactions List - Close',
  },
}

export const EventNames = {
  ACCOUNT_TYPE_COLLAPSE: 'Account Type - Collapse',
  ACCOUNT_TYPE_EXPAND: 'Account Type - Expand',
  ADD_ACCOUNT_END: 'Add Account - End',
  ADD_AGGREGATION_OPEN_CONNECT: 'Open Connect',
  ADD_AGGREGATION_OPEN_CONNECT_SELECT: 'Open Connect - Select',
  ADD_MANUAL_ACCOUNT_START: 'Add Manual Account - Start',
  ADD_SUB_BUDGET_END: 'Add Sub-Budget - End',
  ADD_SUB_BUDGET_START: 'Add Sub-Budget - Start',
  APPLY_ACCOUNT_FILTER: 'APPLY_ACCOUNT_FILTER',
  CHANGE_DATE: 'Change Date',
  CLOSE_ACCOUNT_FILTER: 'Close account filter',
  DELETE_INSTITUTION_END: 'Delete Institution - End',
  DELETE_INSTITUTION_START: 'Delete Institution - Start',
  DISMISS: 'Dismiss',
  DOWNLOAD: 'Download',
  EXCLUDE_ACCOUNT: 'Exclude Account',
  GO_BACK: 'Go Back',
  GO_TO_INSTITUTION_SITE: 'Go to Institution Site',
  HIDE_ACCOUNT: 'Hide Account',
  HIDE_ACCOUNT_END: 'Hide Account - End',
  HIDE_ACCOUNT_START: 'Hide Account - Start',
  HIGHLIGHT_CATEGORY: 'Highlight Category',
  HIGHLIGHT_SUBCATEGORY: 'Highlight Subcategory',
  MARK_AS_CLOSED_END: 'Mark as Closed - End',
  MARK_AS_CLOSED_START: 'Mark as Closed - Start',
  MARK_AS_DUPLICATE_END: 'Mark as Duplicate - End',
  MARK_AS_DUPLICATE_START: 'Mark as Duplicate - Start',
  OPEN_ACCOUNT_FILTER: 'Open account filter',
  SEARCH_COLLAPSE: 'Search - Collapse',
  SEARCH_EXPAND: 'Search - Expand',
  SELECT_ACCOUNT: 'Select an Account',
  SYNC: 'Sync',
  UNHIDE_ACCOUNT: 'Unhide Account',
  UPDATE_CREDENTIALS: 'Update Credentials',
  VIEW_ASSETS_AND_LIABILITIES: 'View Assets and Liabilities',
  VIEW_BUBBLES: 'View Bubbles',
  VIEW_CATEGORY_TRANSACTIONS: 'View Category Transactions',
  VIEW_DATA_SET: 'View Data Set',
  VIEW_INCOME: 'View Income',
  VIEW_LIST: 'View List',
  VIEW_SPENDING: 'View Spending',
  VIEW_SUBCATEGORIES: 'View Subcategories',
  VIEW_SUBCATEGORY_TRANSACTIONS: 'View Subcategory Transactions',
}

export const EventValues = {
  BUTTON: 'Button',
  BOTTOM_BUTTON: 'Bottom Button',
  BUBBLE_DETAILS: 'Bubble Details',
  DATA_LINE: 'Data Line',
  DATA_POINT: 'Data Point',
  FINSMART: 'FinSmart',
  LIST_DETAILS: 'List Details',
  MANAGE_BUDGETS: 'Manage Budgets',
  MENU: 'Menu',
  MONTH_RANGE: 'Month Range',
  NEXT_MONTH: 'Next Month',
  ONE_STATE: 'One State',
  PREVIOUS_MONTH: 'Previous Month',
}

const PageviewNames = {
  ACCOUNT_ACTIVITY: 'Account Activity',
  ACCOUNT_DETAILS: 'Account Details',
  ACCOUNT_TREND: 'Account Trend',
  ACCOUNTS: 'Accounts',
  ADD_MANUAL_ACCOUNT: 'Add Manual Account',
  BUDGET_DETAILS: 'Budget Details',
  BUDGET_ZERO: 'Budget Zero State',
  BUDGETS: 'Budgets',
  BUDGETS_ADD_SUB_BUDGET: 'Budgets Add Sub-Budget',
  BUDGETS_DELETE_BUDGET: 'Budgets Delete Budget',
  BUDGETS_EDIT_BUDGET: 'Budgets Edit Budget',
  DEBTS: 'Debts',
  CASH_FLOW: 'Cash Flow',
  DELETE_CONNECTION: 'Delete Connection',
  EDIT_INCOME_BUDGET: 'Edit Income Budget',
  FINSTRONG: 'Finstrong',
  GOALS: 'Goals',
  HELP: 'Help',
  INVESTMENTS: 'Investments',
  MANAGE_ACCOUNTS: 'Manage Accounts',
  MANAGE_ALERTS: 'Manage Alerts',
  MANAGE_BUDGETS: 'Manage Budgets',
  MANAGE_INSTITUTIONS: 'Manage Institutions',
  MASTER: 'Master',
  MINI_SPENDING: 'Mini Spending',
  MINI_NET_WORTH: 'Mini NetWorth',
  MINI_FINSTRONG: 'Mini Finstrong',
  NET_WORTH: 'Net Worth',
  SETTINGS: 'Settings',
  SPENDING: 'Spending',
  SPENDING_PLAN: 'Spending Plan',
  TRANSACTION_DETAILS: 'Transaction Details',
  TRANSACTION_RECATEGORIZE: 'Transaction Recategorize',
  TRANSACTIONS: 'Transactions',
  TRANSACTIONS_LIST: 'Transactions List',
  TRENDS: 'Trends',
}

const PageviewPaths = {
  ACCOUNT_ACTIVITY: '/account_activity',
  ACCOUNT_DETAILS: '/account_details',
  ACCOUNT_TREND: '/account_trend',
  ACCOUNTS: '/accounts',
  ADD_MANUAL_ACCOUNT: '/add_manual_account',
  BUDGET_DETAILS: '/budget_details',
  BUDGET_ZERO: '/budget_zero',
  BUDGETS: '/budgets',
  BUDGETS_ADD_SUB_BUDGET: '/budgets_add_sub_budget',
  BUDGETS_EDIT_BUDGET: '/budgets_edit_budget',
  BUDGETS_DELETE_BUDGET: '/budgets_delete_budget',
  CASH_FLOW: '/cashflow',
  DEBTS: '/debts',
  DELETE_CONNECTION: '/delete_connection',
  EDIT_INCOME_BUDGET: '/edit_income_budget',
  FINSTRONG: '/finstrong',
  GOALS: '/goals',
  HELP: '/help',
  INVESTMENTS: '/investments',
  MANAGE_ACCOUNTS: '/manage_accounts',
  MANAGE_ALERTS: '/manage_alerts',
  MANAGE_BUDGETS: '/manage_budgets',
  MANAGE_INSTITUTIONS: '/manage_institutions',
  MASTER: '/master',
  MIMI_SPENDING: '/mini_spending',
  MINI_NET_WORTH: '/mini_networth',
  NET_WORTH: '/net_worth',
  SETTINGS: '/settings',
  SPENDING: '/spending',
  SPENDING_PLAN: '/spending_plan',
  TRANSACTION_DETAILS: '/transaction_details',
  TRANSACTION_RECATEGORIZE: '/transaction_recategorize',
  TRANSACTIONS: '/transactions',
  TRANSACTIONS_LIST: '/transactions_list',
  TRENDS: '/trends',
}

const createPageviewInfo = () => {
  let allWidgets = {}

  Object.keys(PageviewNames).forEach(key => {
    allWidgets = Object.assign({}, allWidgets, { [key]: [PageviewNames[key], PageviewPaths[key]] })
  })

  return allWidgets
}

export const PageviewInfo = createPageviewInfo()

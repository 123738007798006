module.exports = {
  "accounts": [{
      "id": "6055993964",
      "number": "202020",
      "realAccountNumberLast4": "2020",
      "accountNumberDisplay": "2020",
      "name": "IRA",
      "balance": 7030,
      "type": "ira",
      "aggregationStatusCode": 0,
      "status": "active",
      "customerId": "6030781868",
      "institutionId": "102176",
      "balanceDate": 1688989426,
      "aggregationSuccessDate": 1688989426,
      "aggregationAttemptDate": 1688989426,
      "createdDate": 1688650529,
      "lastUpdatedDate": 1688650531,
      "currency": "USD",
      "lastTransactionDate": 1688729563,
      "institutionLoginId": 6026370998,
      "detail": {
          "availableCashBalance": 0
      },
      "accountNickname": "IRA",
      "marketSegment": "personal"
  }, {
      "id": "6055993965",
      "number": "212121",
      "realAccountNumberLast4": "2121",
      "accountNumberDisplay": "2121",
      "name": "401K",
      "balance": 9005,
      "type": "401k",
      "aggregationStatusCode": 0,
      "status": "active",
      "customerId": "6030781868",
      "institutionId": "102176",
      "balanceDate": 1688989426,
      "aggregationSuccessDate": 1688989426,
      "aggregationAttemptDate": 1688989426,
      "createdDate": 1688650529,
      "lastUpdatedDate": 1688650531,
      "currency": "USD",
      "lastTransactionDate": 1688729563,
      "institutionLoginId": 6026370998,
      "detail": {
          "availableCashBalance": 0
      },
      "accountNickname": "401K",
      "marketSegment": "personal"
  }, {
      "id": "6055993966",
      "number": "222222",
      "realAccountNumberLast4": "2222",
      "accountNumberDisplay": "2222",
      "name": "Savings",
      "balance": 22327.3,
      "type": "savings",
      "aggregationStatusCode": 0,
      "status": "active",
      "customerId": "6030781868",
      "institutionId": "102176",
      "balanceDate": 1688989426,
      "aggregationSuccessDate": 1688989426,
      "aggregationAttemptDate": 1688989426,
      "createdDate": 1688650529,
      "lastUpdatedDate": 1688650531,
      "currency": "USD",
      "lastTransactionDate": 1688729566,
      "institutionLoginId": 6026370998,
      "detail": {
          "availableBalanceAmount": 0
      },
      "accountNickname": "Savings",
      "oldestTransactionDate": 1673098529,
      "marketSegment": "personal"
  }, {
      "id": "6055993967",
      "number": "333333",
      "realAccountNumberLast4": "3333",
      "accountNumberDisplay": "3333",
      "name": "Credit Card",
      "balance": -1952.71,
      "type": "creditCard",
      "aggregationStatusCode": 0,
      "status": "active",
      "customerId": "6030781868",
      "institutionId": "102176",
      "balanceDate": 1688989426,
      "aggregationSuccessDate": 1688989426,
      "aggregationAttemptDate": 1688989426,
      "createdDate": 1688650529,
      "lastUpdatedDate": 1688650531,
      "currency": "USD",
      "lastTransactionDate": 1688729565,
      "institutionLoginId": 6026370998,
      "detail": {
          "creditAvailableAmount": 0,
          "cashAdvanceBalance": 0,
          "currentBalance": -1952.71,
          "paymentMinAmount": 0,
          "statementCloseBalance": 0
      },
      "accountNickname": "Credit Card",
      "oldestTransactionDate": 1673098529,
      "marketSegment": "personal"
    }
  ]
}
